
import {
    Box,
    Button,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
} from '@mui/material';
import React from 'react';
import { useHistory } from 'react-router-dom';

const NavigationBar = ({ handleSectionChange, selectedSection }) => {
    const history = useHistory();

    const navigateToSection = (section) => {
        handleSectionChange(section);
    };

    const goBack = () => {
        history.goBack();
    };

    return (
        <Box sx={{ width: 250, bgcolor: '#f0f0f0', height: '100%', p: 2, overflowY: 'auto' }}>
            <Button
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                onClick={goBack}
            >
                ← Back to Project
            </Button>
            <Box sx={{ height: '90%', bgcolor: '#ffffff' }}>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            General
                        </ListSubheader>
                    }
                >
                    <ListItem
                        button
                        selected={selectedSection === 'workflows'}
                        onClick={() => navigateToSection('workflows')}
                    >
                        <ListItemText primary="Workflows" />
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedSection === 'info'}
                        onClick={() => navigateToSection('info')}
                    >
                        <ListItemText primary="Info" />
                    </ListItem>
                </List>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Calculations
                        </ListSubheader>
                    }
                >
                    <ListItem
                        button
                        selected={selectedSection === 'methods'}
                        onClick={() => navigateToSection('methods')}
                    >
                        <ListItemText primary="Methods" />
                    </ListItem>
                    <ListItem
                        button
                        selected={selectedSection === 'systems'}
                        onClick={() => navigateToSection('systems')}
                    >
                        <ListItemText primary="Systems" />
                    </ListItem>
                </List>
                <List
                    sx={{ width: '100%', bgcolor: 'background.paper' }}
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            Other
                        </ListSubheader>
                    }
                >
                    <ListItem
                        button
                        selected={selectedSection === 'units'}
                        onClick={() => navigateToSection('units')}
                    >
                        <ListItemText primary="Units" />
                    </ListItem>
                    {/* Uncomment to add 'Hot Keys' section */}
                    {/* <ListItem
                        button
                        selected={selectedSection === 'hotkeys'}
                        onClick={() => navigateToSection('hotkeys')}
                    >
                        <ListItemText primary="Hot Keys" />
                    </ListItem> */}
                </List>
            </Box>
        </Box>
    );
};

export default NavigationBar;
