import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProjects } from '../../redux/slice/projectSlice';
import { deleteProject } from '../../services/projectService';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  Skeleton,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Tooltip,
} from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConfirmAction from '../Dialog/ConfirmAction';
import projectThumbnail from '../../resources/images/plan.jpg';

const ProjectCard = styled(Card)(({ theme }) => ({
  background: alpha(theme.palette.background.paper, 0.8),
  borderRadius: theme.shape.borderRadius * 2,
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  border: `1px solid ${alpha(theme.palette.divider, 0.1)}`,
  '&:hover': {
    background: alpha(theme.palette.background.paper, 1),
    transform: 'translateY(-4px)',
    boxShadow: `0 4px 20px 0px ${alpha(theme.palette.primary.main, 0.2)}`,
    '& .project-image': {
      transform: 'scale(1.05)',
    },
  },
}));

const ProjectImage = styled(CardMedia)(({ theme }) => ({
  height: 140,
  transition: 'transform 0.3s ease',
}));

const ProjectName = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.primary,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
}));

const UpdatedDate = styled(Typography)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.secondary,
  fontSize: '0.75rem',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.secondary,
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
    color: theme.palette.primary.main,
  },
}));

function CardView({ project, loading }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const { projects } = useSelector(state => state.project);

  const [openDialog, setOpenDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => setAnchorEl(null);

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    const res = await deleteProject(token, project._id);
    if (res.success) {
      dispatch(setProjects(projects.filter(p => p._id !== project._id)));
    }
  };

  const handleDeleteConfirm = () => {
    handleDelete();
    setOpenDialog(false);
  };

  const openProject = () => history.push(`/project/${project._id}`);

  const projectCreation = project?.projectData?.projectCreation;
  const projectName = projectCreation && projectCreation.length > 0
    ? projectCreation[0]?.projectInformation?.projectName
    : 'No Project Name';

  const updatedAt = new Date(project?.updatedAt);
  const updatedDate = `${updatedAt.toDateString().slice(4)}, ${updatedAt.toTimeString().split('G')[0]}`;

  if (loading) {
    return (
      <ProjectCard>
        <Skeleton variant="rectangular" height={140} />
        <CardContent>
          <Skeleton variant="text" width="80%" />
          <Skeleton variant="text" width="60%" />
        </CardContent>
      </ProjectCard>
    );
  }

  return (
    <ProjectCard>
      <CardActionArea onClick={openProject}>
        <ProjectImage
          className="project-image"
          image={project?.projectData?.projectImage ?? projectThumbnail}
          title={projectName}
        />
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
            <Box sx={{ minWidth: 0, flexGrow: 1 }}>
              <ProjectName variant="subtitle1">{projectName}</ProjectName>
              <UpdatedDate>
                <AccessTimeIcon fontSize="inherit" sx={{ mr: 0.5 }} />
                {updatedDate}
              </UpdatedDate>
            </Box>
            <Tooltip title="More options">
              <ActionButton
                size="small"
                onClick={handleMenuOpen}
                aria-label="project actions"
              >
                <MoreHorizIcon />
              </ActionButton>
            </Tooltip>
          </Box>
        </CardContent>
      </CardActionArea>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        onClick={(e) => e.stopPropagation()}
        PaperProps={{
          sx: {
            backgroundColor: theme => theme.palette.background.paper,
            boxShadow: theme => `0 4px 20px 0px ${alpha(theme.palette.common.black, 0.2)}`,
          }
        }}
      >
        <MenuItem onClick={() => {
          handleMenuClose();
          setOpenDialog(true);
        }}>
          <DeleteOutlineIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
      <ConfirmAction
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        dialogTitle="Delete Project"
        dialogContent={`Are you sure you want to delete "${projectName}"?`}
        onAgree={handleDeleteConfirm}
        onDisagree={() => setOpenDialog(false)}
      />
    </ProjectCard>
  );
}

export default CardView;