import { createTheme, responsiveFontSizes } from '@mui/material/styles';

let appTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 768,
      lg: 1024,
      xl: 1440,
    },
  },
  palette: {
    primary: {
      main: '#4E5FBF',
    },
    secondary: {
      main: '#D2DBE8',
    },
    links: {
      main: '#5864A6',
    },
    text: {
      main: '#2D2D2B',
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#8091F2',
          color: 'white',
          paddingTop: '1vh',
          '&:hover': {
            backgroundColor: '#fffff',
            color: 'black',
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: 'white',
          color: '#4E5FBF',
          paddingTop: '1vh',
        },
      },
    },
  },
});

appTheme = responsiveFontSizes(appTheme);
export default appTheme;
