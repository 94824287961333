import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import { Box, Button, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import elbowPanelStyles from '../PanelStyles/ElbowPanelStyles';

const EqElbowPanel = ({ selectedEquipment, setSelectedEquipment, setEquipments, equipments }) => {
  const [rotation, setRotation] = useState(selectedEquipment.rotation);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [isRightToLeft, setIsRightToLeft] = useState(false);
  const [showAdditionalProperties, setShowAdditionalProperties] = useState(false);
  const [shape, setShape] = useState('');
  const [dimensions, setDimensions] = useState({
    depth: '',
    width: '',
    height: '',
  });

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRotation(selectedEquipment.rotation);
  }, [selectedEquipment.rotation]);

  const handleDelete = () => {
    if (selectedEquipment?.id) {
      const filteredItems = equipments.filter(item => item.id !== selectedEquipment.id);
      setEquipments(filteredItems);
    }
    setSelectedEquipment(null);
    setIsPanelVisible(false); // Hide the panel after delete
  };

  const handleRotationChange = value => {
    const optionIndex = equipments.findIndex(opt => opt.id === selectedEquipment?.id);
    if (optionIndex !== -1) {
      let newRotation = rotation + value;
      newRotation = Math.max(0, Math.min(360, newRotation));
      if (newRotation === 360) {
        newRotation = 0;
      }
      const updatedSelectedOptions = [...equipments];
      updatedSelectedOptions[optionIndex] = {
        ...updatedSelectedOptions[optionIndex],
        rotation: newRotation,
      };
      setRotation(newRotation);
      setEquipments(updatedSelectedOptions);
    }
  };

  const toggleRightToLeft = () => {
    setIsRightToLeft(!isRightToLeft);
  };

  const toggleAdditionalProperties = () => {
    setShowAdditionalProperties(!showAdditionalProperties);
  };

  const handleDimensionChange = (key, value) => {
    setDimensions({
      ...dimensions,
      [key]: value,
    });
  };

  const handleClose = () => {
    setIsPanelVisible(false); // Hide the panel when cancel button is clicked
  };

  if (!isPanelVisible) {
    return null; // Render nothing if the panel is not visible
  }

  return (
    <Box sx={elbowPanelStyles.container}>
      <Box sx={elbowPanelStyles.centered}>
        <Typography variant="h6">Elbow</Typography>
      </Box>
      <Box sx={elbowPanelStyles.controlGroup}>
        <Typography sx={{ textAlign: 'left', fontWeight: 'bold', mb: 1 }}>Rotation</Typography>
        <Box sx={elbowPanelStyles.controls}>
          <Button
            variant="outlined"
            onClick={() => handleRotationChange(-45)}
            sx={elbowPanelStyles.rotateButton}
          >
            <RotateLeftIcon style={{ fontSize: '1.5rem' }} />
          </Button>
          <TextField
            value={rotation}
            inputProps={{ style: { textAlign: 'center', padding: '5px' } }}
            sx={elbowPanelStyles.inputNumber}
          />
          <Button
            variant="outlined"
            onClick={() => handleRotationChange(45)}
            sx={elbowPanelStyles.rotateButton}
          >
            <RotateRightIcon style={{ fontSize: '1.5rem' }} />
          </Button>
        </Box>
      </Box>
      <Box sx={elbowPanelStyles.buttonGroup}>
        <Button variant="outlined" onClick={handleDelete} color='error' sx={{ bgcolor: "red", color: "white" }}>
          Delete
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default EqElbowPanel;
