import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import styles from './index.module.scss';
import { useSelector } from 'react-redux';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({
  fileReaderInfo,
  fileReaderInfoArray,
  updateFileReaderInfo,
  fabric,
  canvas,
  selectedPaperSize,
  drawing,
  mode,
  projectData,
  setLoadedPDFs,
}) => {
  const [pdfImageAdded, setPdfImageAdded] = useState(false);
  const { pdfWidth, pdfScale, pdfHeight } = useSelector(state => state.drawing);
  const pdfPositions = projectData?.pdfPositions;

  function onRenderSuccess() {
    const importPDFCanvas = document.querySelector('.import-pdf-page canvas');
    const pdfAsImageSrc = importPDFCanvas.toDataURL();
    updateFileReaderInfo({ currentPage: pdfAsImageSrc });
  }

  function onRenderSuccessArray(index, pdfFile) {
    const importPDFCanvas = document.querySelector(`.import-pdf-page-${index} canvas`);
    const pdfAsImageSrc = importPDFCanvas.toDataURL();
    const pdfData = pdfPositions.find(obj => obj.fileName === pdfFile.file.name);
    setLoadedPDFs(prevPDFs => [
      ...prevPDFs,
      {
        src: pdfAsImageSrc,
        position: {
          // left: pdfData.left,
          // top: pdfData.top,
          originX: pdfData.originX,
          originY: pdfData.originY,
          selectable: pdfData.selectable,
          evented: pdfData.evented,
          hasControls: pdfData.hasControls,
          hasBorders: pdfData.hasBorders,
          pdfId: pdfData.pdfId,
          selectedLevel: pdfData.selectedLevel,
          fileName: pdfData.fileName,
        },
      },
    ]);
  }

  function onDocumentLoadSuccess({ numPages }) {
    updateFileReaderInfo({ totalPages: numPages });
  }

  const renderCanvas = () => {
    try {
      if (canvas && !pdfImageAdded) {
        const center = canvas.getCenter();
        fabric.Image.fromURL(fileReaderInfo.currentPage, img => {
          img.scaleToHeight(canvas.height);
          img.set({
            left: center.left,
            top: center.top,
            originX: 'center',
            originY: 'center',
            selectable: !drawing && mode !== 'drag',
            evented: true,
            hasControls: false,
            hasBorders: false,
          });

          img.on('mousedown', function (options) {
            if (options.target) {
              canvas.setActiveObject(options.target);
              options.target.bringToFront();
            }
          });

          canvas.add(img);
          setPdfImageAdded(true);
          canvas.renderAll();
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleDragMode = () => {
    if (canvas) {
      const panOptions = {
        cursor: 'grab',
        moveCursor: 'grabbing',
      };

      canvas.on('mouse:down', function (options) {
        if (mode === 'drag' && options.e.altKey) {
          this.isDragging = true;
          this.selection = false;
          this.lastPosX = options.e.clientX;
          this.lastPosY = options.e.clientY;
          this.setCursor(panOptions.cursor);
        }
      });

      canvas.on('mouse:move', function (options) {
        if (this.isDragging) {
          const e = options.e;
          const vpt = this.viewportTransform;
          vpt[4] += e.clientX - this.lastPosX;
          vpt[5] += e.clientY - this.lastPosY;
          this.requestRenderAll();
          this.lastPosX = e.clientX;
          this.lastPosY = e.clientY;
        }
      });

      canvas.on('mouse:up', function () {
        this.isDragging = false;
        this.selection = true;
        this.setCursor('default');
      });
    }
  };

  useEffect(() => {
    // renderCanvas();
    handleDragMode();
  }, [fileReaderInfo.currentPage, fabric, canvas, pdfImageAdded, drawing, mode]);

  return (
    <div className={styles.pdfReader}>
      <div className={styles.fileContainer}>
        {fileReaderInfoArray.length > 0 ? (
          fileReaderInfoArray.map((pdfFile, index) => (
            <Document
              key={index}
              className={styles.document}
              file={pdfFile.file}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                className={`import-pdf-page-${index}`}
                onRenderSuccess={() => onRenderSuccessArray(index, pdfFile)}
                pageNumber={pdfFile.currentPageNumber}
              />
            </Document>
          ))
        ) : (
          <Document
            className={styles.document}
            file={fileReaderInfo.file}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadProgress={({ loaded, total }) =>
              console.log('Loading a document: ' + (loaded / total) * 100 + '%')
            }
          >
            <Page
              className="import-pdf-page"
              onRenderSuccess={onRenderSuccess}
              pageNumber={fileReaderInfo.currentPageNumber}
            />
          </Document>
        )}
      </div>
    </div>
  );
};

export default PDFReader;
