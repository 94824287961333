import { fabric } from 'fabric';
import { scalingRatio, standardPaperSizes } from '../cordinates/distancePoints';

let drawInstance = null;
let mouseDown = false;
let lineStrokeWidth = 1;
let transitionWidth = 1;

const options = {
  currentMode: '',
  currentColor: '#000000',
  currentWidth: 5,
  fill: false,
  group: {},
};

const modes = {
  LINE: 'LINE',
};

function stopDrawing() {
  mouseDown = false;
}

export const stopLineDraw = canvas => {
  if (canvas) {
    canvas.off('mouse:down');
    canvas.off('mouse:move');
    canvas.off('mouse:up');
  }
};

function removeCanvasListener(canvas) {
  canvas.off('mouse:down');
  canvas.off('mouse:move');
  canvas.off('mouse:up');
  canvas.off('mouse:wheel');
}

function handleLineClick(event) {
  console.log('Line Clicked:', event.target);
}

export function startAddLine(canvas, width) {
  return ({ e }) => {
    mouseDown = true;

    let pointer = canvas.getPointer(e);
    console.log('Start Drawing Line:', { x: pointer.x, y: pointer.y });
    drawInstance = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
      strokeWidth: width,
      stroke: 'red',
      selectable: false,
    });
    drawInstance.on('mousedown', handleLineClick);

    canvas.add(drawInstance);
    canvas.requestRenderAll();
  };
}

export const generateUniqueId = () => {
  return Math.random().toString(36).substr(2, 9);
};

export function getDistance(x1, y1, x2, y2) {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
}

export function setLineProperties(line, unit, lineProps) {
  let flowRate = lineProps[0].flowRate;
  let width = lineProps[0].ductWidth;
  let height = lineProps[0].ductHeight;
  let diameter = 100;
  const orientation = checkLineOrientation(line);
  const direction = getLineDirection(line);
  line.orientation = orientation;
  line.direction = direction;
  if (orientation === 'horizontal') {
    line.ductLength = line.width;
  } else if (orientation === 'vertical') {
    line.ductLength = line.height;
  }
  line.ductLength = convertDimensions(line.ductLength, unit === 'Imperial' ? 'feet' : 'meters');
  if (!flowRate || !width || !height) {
    flowRate = 1
    width = 100
    height = 100
    if (unit === 'Imperial') {
      flowRate = convertM3sToCfm(flowRate);
      width = mmToInches(width);
      height = mmToInches(height);
      diameter = mmToInches(diameter);
    }
  }
  line.ductType = 'Rectangular';
  line.diameter = diameter;
  line.systemType = 'Supply Air';
  line.ductWidth = width;
  line.ductHeight = height;
  line.flow = 'out';
  line.pressure = 1;
  line.flowRate = flowRate;
  return line;
}

function convertM3sToCfm(m3s) {
  const conversionFactor = 2118.87997;
  return m3s * conversionFactor;
}

export function mmToInches(mm) {
  return mm / 25.4;
}

// Conversion factors
const feetPerMeter = 3.28084;

// Function to convert drawing dimensions based on scaling ratio and paper size
function convertDimensions(lineLengthInPixels, unit = 'meters') {
  // Calculate the real-world length in meters using the scaling value
  const lengthInMeters = lineLengthInPixels * 0.1;

  if (unit === 'feet') {
    return lengthInMeters * feetPerMeter;
  }
  return lengthInMeters; // Default to meters
}

export function paToInWG(pa) {
  return pa / 249.08891;
}

export function checkLineOrientation(line) {
  const TOLERANCE = 1e-10;
  if (Math.abs(line.x1 - line.x2) < TOLERANCE) {
    return 'vertical';
  } else if (Math.abs(line.y1 - line.y2) < TOLERANCE) {
    return 'horizontal';
  } else {
    return 'other';
  }
}

export function getLineDirection(line) {
  var x1 = line.x1,
    y1 = line.y1;
  var x2 = line.x2,
    y2 = line.y2;

  var dx = x2 - x1;
  var dy = y2 - y1;

  if (dx > 0 && dy > 0) {
    return 'Bottom Right';
  } else if (dx > 0 && dy < 0) {
    return 'Top Right';
  } else if (dx < 0 && dy > 0) {
    return 'Bottom Left';
  } else if (dx < 0 && dy < 0) {
    return 'Top Left';
  } else if (dx > 0 && dy === 0) {
    return 'Right';
  } else if (dx < 0 && dy === 0) {
    return 'Left';
  } else if (dx === 0 && dy > 0) {
    return 'Down';
  } else if (dx === 0 && dy < 0) {
    return 'Up';
  } else {
    return 'No direction (Point)';
  }
}

export function closestPointOnLine(x1, y1, x2, y2, px, py) {
  const dx = x2 - x1;
  const dy = y2 - y1;
  const lengthSquared = dx * dx + dy * dy;
  const t = ((px - x1) * dx + (py - y1) * dy) / lengthSquared;
  const clampedT = Math.max(0, Math.min(1, t));
  return {
    x: x1 + clampedT * dx,
    y: y1 + clampedT * dy,
  };
}

// Return equipment under pointer
export function isOverEquipment(pointer, equipments) {
  let equipment = null;
  equipments.forEach(eq => {
    const imageCenterX = eq.position.left;
    const imageCenterY = eq.position.top;
    const imageHalfWidth = eq.width / 2;
    const imageHalfHeight = eq.height / 2;

    if (
      pointer.x >= imageCenterX - imageHalfWidth &&
      pointer.x <= imageCenterX + imageHalfWidth &&
      pointer.y >= imageCenterY - imageHalfHeight &&
      pointer.y <= imageCenterY + imageHalfHeight
    ) {
      equipment = eq;
    }
  });
  return equipment;
}

export function checkForOverlaps(lines, newLine) {
  if (lines.length < 1) {
    return null;
  }
  let newLineStart = { x: newLine.x1, y: newLine.y1 };
  let newLineEnd = { x: newLine.x2, y: newLine.y2 };

  for (let i = 0; i < lines.length; i++) {
    let existingLineStart = { x: lines[i].x1, y: lines[i].y1 };
    let existingLineEnd = { x: lines[i].x2, y: lines[i].y2 };

    if (doIntersect(newLineStart, newLineEnd, existingLineStart, existingLineEnd)) {
      let intersectionPoint = getIntersectionPoint(
        newLineStart,
        newLineEnd,
        existingLineStart,
        existingLineEnd,
      );
      if (intersectionPoint) {
        return [i, intersectionPoint];
      }
    }
  }
  return null;
}

export function doIntersect(p1, q1, p2, q2) {
  let o1 = orientation(p1, q1, p2);
  let o2 = orientation(p1, q1, q2);
  let o3 = orientation(p2, q2, p1);
  let o4 = orientation(p2, q2, q1);

  if (o1 !== o2 && o3 !== o4) return true;

  // Special cases:
  // p1, q1, p2 are collinear and p2 lies on segment p1q1
  if (o1 === 0 && onSegment(p1, p2, q1)) return true;
  // p1, q1, q2 are collinear and q2 lies on segment p1q1
  if (o2 === 0 && onSegment(p1, q2, q1)) return true;
  // p2, q2, p1 are collinear and p1 lies on segment p2q2
  if (o3 === 0 && onSegment(p2, p1, q2)) return true;
  // p2, q2, q1 are collinear and q1 lies on segment p2q2
  if (o4 === 0 && onSegment(p2, q1, q2)) return true;

  return false; // Doesn't fall in any of the above cases
}

export function onSegment(p, q, r) {
  if (
    q.x <= Math.max(p.x, r.x) &&
    q.x >= Math.min(p.x, r.x) &&
    q.y <= Math.max(p.y, r.y) &&
    q.y >= Math.min(p.y, r.y)
  )
    return true;
  return false;
}

export function getIntersectionPoint(p1, q1, p2, q2) {
  let A1 = q1.y - p1.y;
  let B1 = p1.x - q1.x;
  let C1 = A1 * p1.x + B1 * p1.y;

  let A2 = q2.y - p2.y;
  let B2 = p2.x - q2.x;
  let C2 = A2 * p2.x + B2 * p2.y;

  let determinant = A1 * B2 - A2 * B1;

  if (determinant === 0) {
    // Lines are parallel, no intersection
    return null;
  } else {
    let x = (B2 * C1 - B1 * C2) / determinant;
    let y = (A1 * C2 - A2 * C1) / determinant;
    return { x: x, y: y };
  }
}

export function orientation(p, q, r) {
  let val = (q.y - p.y) * (r.x - q.x) - (q.x - p.x) * (r.y - q.y);
  if (val === 0) return 0; // collinear
  return val > 0 ? 1 : 2; // clock or counterclock wise
}

export function getAdjustedStrokeWidth(pdfScale, paperType, baseStroke) {
  const ratio = scalingRatio.find(r => r.value === pdfScale).value;
  const paper = standardPaperSizes.find(size => size.name === paperType);
  const basePaper = standardPaperSizes.find(size => size.name === 'A4');
  const paperFactor = Math.sqrt((paper.width * paper.height) / (basePaper.width * basePaper.height));
  const adjustedWidth = baseStroke * ratio * paperFactor;
  return adjustedWidth;
}

// Function to split the line at a given point
export function splitLineAtPoint(line, selectedlevel, clickPoint, pdfScale, paperType, baseStroke) {
  const { x1, y1, x2, y2 } = line;

  // Calculate vector components
  const dx = x2 - x1;
  const dy = y2 - y1;

  // Calculate squared length of the line segment
  const segmentLengthSquared = dx * dx + dy * dy;

  // Handle edge case: line is a point
  if (segmentLengthSquared === 0) {
    return;
  }

  // Calculate vector from line start to click point
  const vectorFromStart = {
    x: clickPoint.x - x1,
    y: clickPoint.y - y1,
  };

  // Calculate dot product to determine projection factor
  const projectionFactor = (vectorFromStart.x * dx + vectorFromStart.y * dy) / segmentLengthSquared;

  // Clamp projection factor to [0, 1] to ensure it's on the line segment
  const clampedProjectionFactor = Math.max(0, Math.min(1, projectionFactor));

  // Calculate closest point on the line segment
  const closestPoint = {
    x: x1 + clampedProjectionFactor * dx,
    y: y1 + clampedProjectionFactor * dy,
  };

  // Create new lines at the closest point
  const line1 = new fabric.Line([x1, y1, closestPoint.x, closestPoint.y], {
    strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, baseStroke),
    stroke: 'red',
    level: selectedlevel?.name || 'L1',
    id: line.id,
    originX: 'center',
    originY: 'center',
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
  });

  const line2 = new fabric.Line([closestPoint.x, closestPoint.y, x2, y2], {
    strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, baseStroke),
    stroke: 'red',
    level: selectedlevel?.name || 'L1',
    id: generateUniqueId(),
    originX: 'center',
    originY: 'center',
    lockMovementX: true,
    lockMovementY: true,
    lockScalingX: true,
    lockScalingY: true,
    hasControls: false,
  });
  return [line1, line2, closestPoint];
}

// export function startDrawingLine(canvas) {
//   return ({ e }) => {
//     if (mouseDown) {
//       const pointer = canvas.getPointer(e);
//       drawInstance.set({
//         x2: pointer.x,
//         y2: pointer.y,
//       });
//       drawInstance.setCoords();

//       canvas.requestRenderAll();
//       console.log('End Drawing Line:', { x: pointer.x, y: pointer.y });
//     }
//   };
// }

// export const createLine=(canvas)=>{
//   if (modes.currentMode !== modes.LINE) {
//     options.currentMode = modes.LINE;

//     removeCanvasListener(canvas);
//     canvas.on('mouse:down', startAddLine(canvas));
//     canvas.on('mouse:move', startDrawingLine(canvas));
//     canvas.on('mouse:up', stopDrawing);
//     // canvas.on('mouse:wheel', handleMouseWheel(canvas));

//     canvas.selection = false;
//     canvas.hoverCursor = 'auto';
//     canvas.isDrawingMode = false;
//     canvas.getObjects().map((item) => item.set({ selectable: false }));
//     canvas.discardActiveObject().requestRenderAll();
//   }
// }

export const handleResize = callback => {
  const resize_ob = new ResizeObserver(callback);
  return resize_ob;
};

export const resizeCanvas = (canvas, mainboard) => {
  return () => {
    const ratio = canvas.getWidth() / canvas.getHeight();
    const mainboardWidth = mainboard.clientWidth;

    const scale = mainboardWidth / canvas.getWidth();
    const zoom = canvas.getZoom() * scale;
    canvas.setDimensions({ width: mainboardWidth, height: mainboardWidth / ratio });
    canvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0]);
  };
};

export function setZoom(canvas, zoom) {
  let initialZoom = canvas.getZoom();
  let newZoom = initialZoom + zoom;
  // if (newZoom > 5) newZoom = 5;
  if (newZoom < 0.3) newZoom = 0.3;
  const center = canvas.getCenter();
  canvas.zoomToPoint({ x: center.left, y: center.top }, newZoom);
}

export const initCanvas = (width, height) => {
  const canvas = new fabric.Canvas('canvas', {
    height,
    width,
    selection: false,
    selectionColor: 'transparent',
  });
  fabric.Object.prototype.transparentCorners = false;
  fabric.Object.prototype.cornerStyle = 'circle';
  fabric.Object.prototype.borderColor = '#4447A9';
  fabric.Object.prototype.cornerColor = '#4447A9';
  fabric.Object.prototype.cornerSize = 6;
  fabric.Object.prototype.padding = 10;
  fabric.Object.prototype.borderDashArray = [5, 5];

  canvas.on('object:added', e => {
    e.target.on('mousedown', removeObject(canvas));
  });
  canvas.on('path:created', e => {
    e.path.on('mousedown', removeObject(canvas));
  });

  return canvas;
};

export function centerCanvasView(canvas) {
  const canvasWidth = canvas.width;
  const canvasHeight = canvas.height;

  // Centering the canvas view by resetting the viewport transform
  canvas.setViewportTransform([
    1,
    0,
    0,
    1,
    canvasWidth / 2 - canvas.getWidth() / 2,
    canvasHeight / 2 - canvas.getHeight() / 2 - 300,
  ]);

  canvas.renderAll();
}

export function updateCanvasZoom(pdfScale, paperType) {
  const minScaleFactor = 0.1; // Corresponds to scale '1:10'
  const maxScaleFactor = 10; // Corresponds to scale '1:1000'
  const scaleAtMidPoint = 1; // Corresponds to scale '1:100'

  const zoomAtMinScale = 30;
  const zoomAtMidScale = 3.5;
  const zoomAtMaxScale = 0.4;

  const scaleFactor = scalingRatio.find(r => r.value === pdfScale).value;

  // Coefficients of the quadratic equation (derived from the points)
  const a = 2.93939394;
  const b = -32.67777778;
  const c = 33.23838384;

  // Calculate zoom using the quadratic formula
  const zoom = a * Math.pow(scaleFactor, 2) + b * scaleFactor + c;

  return zoom;
}

function removeObject(canvas) {
  return e => { };
}

export const calculateDistance = canvas => {
  if (canvas) {
    const center = {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };

    const scale = 10;

    const buttonA = new fabric.Text('A', {
      left: center.x + 2 * scale,
      top: center.y - 2 * scale,
      fontSize: 20,
      selectable: true,
      hasControls: false,
    });

    const buttonB = new fabric.Text('B', {
      left: center.x - 2 * scale,
      top: center.y - 2 * scale,
      fontSize: 20,
      selectable: true,
      hasControls: false,
    });

    canvas.add(buttonA, buttonB);

    const updateDistance = () => {
      const distanceCanvasUnits = Math.sqrt(
        Math.pow(buttonB.left - buttonA.left, 2) + Math.pow(buttonB.top - buttonA.top, 2),
      );

      const xA = (buttonA.left - center.x) / scale;
      const yA = (center.y - buttonA.top) / scale;

      const xB = (buttonB.left - center.x) / scale;
      const yB = (center.y - buttonB.top) / scale;

      const distanceInCordinates = Math.sqrt(Math.pow(xB - xA, 2) + Math.pow(yB - yA, 2));

      console.log('Distance between buttons in canvas units:', distanceCanvasUnits);
      console.log('Distance between buttons in cordonates units:', distanceInCordinates);

      console.log('Distance between buttons in canvas units:', distanceCanvasUnits);
    };

    buttonA.on('moving', function () {
      updateDistance();
    });

    buttonB.on('moving', function () {
      updateDistance();
    });

    canvas.renderAll();
  }
};

export const calculateDistanceBetweenPoints = canvas => {
  if (canvas) {
    const center = {
      x: canvas.width / 2,
      y: canvas.height / 2,
    };

    const scale = 10;

    const buttonA = new fabric.Text('A', {
      left: center.x + 2 * scale,
      top: center.y - 2 * scale,
      fontSize: 20,
      selectable: true,
      hasControls: false,
    });

    const buttonB = new fabric.Text('B', {
      left: center.x - 2 * scale,
      top: center.y - 2 * scale,
      fontSize: 20,
      selectable: true,
      hasControls: false,
    });

    canvas.add(buttonA, buttonB);

    const updateDistance = () => {
      const distanceCanvasUnits = Math.sqrt(
        Math.pow(buttonB.left - buttonA.left, 2) + Math.pow(buttonB.top - buttonA.top, 2),
      );

      console.log('Distance between buttons in canvas units:', distanceCanvasUnits);
      console.log(
        'Button A Coordinates (x, y):',
        (buttonA.left - center.x) / scale,
        (center.y - buttonA.top) / scale,
      );
      console.log(
        'Button B Coordinates (x, y):',
        (buttonB.left - center.x) / scale,
        (center.y - buttonB.top) / scale,
      );
    };

    buttonA.on('moving', function () {
      updateDistance();
    });

    buttonB.on('moving', function () {
      updateDistance();
    });

    canvas.renderAll();
  }
};

export const getPDFCorners = pdfObject => {
  const boundingBox = pdfObject.getBoundingRect();
  const topLeft = { x: boundingBox.left, y: boundingBox.top };
  const topRight = { x: boundingBox.left + boundingBox.width, y: boundingBox.top };
  const bottomLeft = { x: boundingBox.left, y: boundingBox.top + boundingBox.height };
  const bottomRight = { x: boundingBox.left + boundingBox.width, y: boundingBox.top + boundingBox.height };

  return { topLeft, topRight, bottomLeft, bottomRight };
};

export const generateRandomId = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomId = '';

  for (let i = 0; i < 5; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters.charAt(randomIndex);
  }

  return randomId;
};
