export const scalingRatio = [
  { value: 0.1, scale: '1:10' },
  { value: 0.15, scale: '1:15' },
  { value: 0.2, scale: '1:20' },
  { value: 0.25, scale: '1:25' },
  { value: 0.3, scale: '1:30' },
  { value: 0.4, scale: '1:40' },
  { value: 0.5, scale: '1:50' },
  { value: 0.75, scale: '1:75' },
  { value: 1, scale: '1:100' },
  { value: 1.25, scale: '1:125' },
  { value: 1.5, scale: '1:150' },
  { value: 2, scale: '1:200' },
  { value: 2.5, scale: '1:250' },
  { value: 3, scale: '1:300' },
  { value: 6, scale: '1:600' },
  { value: 7.5, scale: '1:750' },
  { value: 10, scale: '1:1000' },
  { value: 0.12, scale: '1" - 1\'' }, //verified
  { value: 0.24, scale: '1/2" - 1\'' }, //verified
  { value: 0.48, scale: '1/4" - 1\'' }, //verified
  { value: 0.96, scale: '1/8" - 1\'' }, //verified
  { value: 1.92, scale: '1/16" - 1\'' }, //verified
  { value: 3.84, scale: '1/32" - 1\'' }, //verified
  { value: 7.68, scale: '1/64" - 1\'' }, //verified
  { value: 15.36, scale: '1/128" - 1\'' }, //verified
  { value: 0.16, scale: '3/4" - 1\'' }, //verified
  { value: 0.32, scale: '3/8" - 1\'' }, //verified
  { value: 0.64, scale: '3/16" - 1\'' }, //verified
  { value: 1.28, scale: '3/32" - 1\'' },
];

// export const standardPaperSizes = [
//   { name: 'A0', width: 2384, height: 3370 },
//   { name: 'A1', width: 1684, height: 2384 },
//   { name: 'A2', width: 1191, height: 1684 },
//   { name: 'A3', width: 842, height: 1191 },
//   { name: 'A4', width: 595, height: 842 },
//   { name: 'A5', width: 420, height: 595 },
//   { name: 'A6', width: 298, height: 420 },
//   { name: 'A7', width: 210, height: 298 },
//   { name: 'A8', width: 147, height: 210 },
//   { name: 'A9', width: 105, height: 147 },
//   { name: 'A10', width: 74, height: 105 },
// ];

export const standardPaperSizes = [
  { name: 'A0', width: 841, height: 1189 },
  { name: 'A1', width: 594, height: 841 },
  { name: 'A2', width: 420, height: 594 },
  { name: 'A3', width: 297, height: 420 },
  { name: 'A4', width: 210, height: 297 },
  { name: 'A5', width: 148, height: 210 },
  { name: 'A6', width: 105, height: 148 },
  { name: 'A7', width: 74, height: 105 },
  { name: 'A8', width: 52, height: 74 },
  { name: 'A9', width: 37, height: 52 },
  { name: 'A10', width: 26, height: 37 },
];
