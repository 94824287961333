import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';
// import HomePage from '../pages/homePage';
// import Project from '../pages/Project';
import MainBoard from '../components/MainBoard/MainBoard';
import Dashboard from './Dashboard';
import Home from './Home';
import LoginPage from './login';
import SignupPage from './signup';
import WaitList from './Waitlist';
// import ForgetPassword from '../pages/ForgetPassword';
// import ResetPassword from '../pages/ResetPassword';
// import AccountManagement from '../pages/AccountManagement';
// import NoViewerLayout from '../layouts/NoViewerLayout';
// import SuccessfullyReset from '../pages/SuccessfullyReset';
import { Typography } from '@mui/material';
import { InfinitySpin } from 'react-loader-spinner';
import './spinner.css';
// import { useSelector } from 'react-redux';
// import LandingPage from '../pages/LandingPage';
import ActivityLog from '../components/MainBoard/Activity';
import Settings from '../components/MainBoard/ProjectSettings/Settings';
import { tokenRefresh, verifyToken } from '../services/authService';
import LandingPage from '../components/Landingpage/LandingPage';

const styles = {
  spinnerDiv: {
    top: '60%',
    width: '100%',
    height: 'auto',
    background: 'transparent',
  },
};

function AppRoutes() {
  //   const loading = useSelector(state => state.app.loading);
  const loading = false;
  useEffect(() => {
    const fetchData = async () => {
      const email = localStorage.getItem('email');
      const token = localStorage.getItem('token');
      const refreshToken = localStorage.getItem('refreshToken');
      if (!refreshToken) return;
      try {
        const response = await tokenRefresh({ email, refreshToken, token });
        if (response.status === 401) {
          localStorage.removeItem('email');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.replace('/login');
        } else {
          localStorage.setItem('token', response.res.idToken);
        }
      } catch (error) {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.replace('/login');
      }
    };
    // Refresh id token in 12 hours
    fetchData();
    const intervalId = setInterval(fetchData, 12 * 60 * 60 * 1000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <Router>
      {loading.load && (
        <div className="sh-spinner">
          <InfinitySpin type="TailSpin" color="white" />
          <div style={styles.spinnerDiv}>
            <Typography variant="h5" style={{ color: '#FFFFFF' }}>
              {loading.message}
            </Typography>
          </div>
        </div>
      )}
      <Switch>
        <SimpleRoute exact={true} path="/" Component={Home} />
        <SimpleRoute exact={true} path="/login" Component={LoginPage} />
        <SimpleRoute exact={true} path="/signup" Component={SignupPage} />
        <SimpleRoute exact={true} path="/waitlist" Component={WaitList} />
        <ProtectedRoute exact={true} path="/project/:projectId" Component={MainBoard} />
        <ProtectedRoute exact={true} path="/dashboard" Component={Dashboard} />
        <ProtectedRoute exact={true} path="/settings/:projectId" Component={Settings} />
        <ProtectedRoute exact={true} path="/reports/:projectId" Component={ActivityLog} />
      </Switch>
    </Router>
  );
}

function SimpleRoute({ Component, path, exact }) {
  return (
    <Route
      exact={exact}
      path={path}
      render={routeProps => (
        <>
          <Component {...routeProps} />
        </>
      )}
    />
  );
}

function ProtectedRoute({ Component, path, exact }) {
  // const userDetails = useSelector(state => state.user.userDetails);
  // if (Object.keys(userDetails).length !== 0 && userDetails.name !== undefined) {
  //   isLoggedIn = true;
  // }
  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      if (!token) {
        window.location.replace('/login');
      }
      try {
        const response = await verifyToken({ token: token });
        if (response.status === 401) {
          localStorage.removeItem('email');
          localStorage.removeItem('token');
          localStorage.removeItem('refreshToken');
          window.location.replace('/login');
        }
      } catch (error) {
        localStorage.removeItem('email');
        localStorage.removeItem('token');
        localStorage.removeItem('refreshToken');
        window.location.replace('/login');
      }
    };
    fetchData();
  }, []);

  return <Route exact={exact} path={path} render={routeProps => <>{<Component {...routeProps} />}</>} />;
}

export default AppRoutes;
