import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import React from 'react';
import signup from '../resources/images/signup.jpg';
import { confirmUser, resendCode, signUpUser } from '../services/authService';

const styles = () => ({});

class SignupPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      message: '',
      showPassword: false,
      showConfirmPassword: false,
      validEmailError: false,
      matchPasswordError: false,
      isRegistered: false,
      strongPassword: true,
      verificationCodeDialog: false,
      verificationCode: '',
      resentCode: false,
      resentCodeMessage: '',
      invalidCode: false,
      invalidCodeMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePasswordOnchange = this.handlePasswordOnchange.bind(this);
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };
  handleClickShowConfirmPassword = () => {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  };

  handleMouseDownConfirmPassword = event => {
    event.preventDefault();
  };

  componentDidMount() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.password !== this.state.password) {
      this.checkStrongPassword();
    }
  }

  async handleSubmit(event) {
    event.preventDefault();
    const { validEmailError, matchPasswordError } = this.state;

    if (this.state.password !== this.state.confirmPassword) {
      this.setState({ matchPasswordError: true });
      return;
    }

    if (!validEmailError && !matchPasswordError) {
      this.setState({ isRegistered: true });
      const response = await signUpUser({
        name: this.state.name,
        email: this.state.email,
        password: this.state.password,
      });
      if (response.status === 400) {
        this.setState({ validEmailError: true });
        this.setState({ message: response.message });
      } else {
        this.setState({ isRegistered: false });
        this.setState({ verificationCodeDialog: true });
      }
    }
  }

  handleVerificationCode(event) {
    this.setState({ verificationCode: event.target.value });
  }
  async submitVerificationCode(event, email, verificationCode) {
    event.preventDefault();
    const response = await confirmUser({
      email: email,
      verificationCode: verificationCode,
    });
    if (response.status === 400) {
      this.setState({ invalidCode: true });
      this.setState({ invalidCodeMessage: response.message });
    } else {
      this.setState({ verificationCodeDialog: false });
      this.props.history.push('/login');
    }
  }
  async resendCode(event, email) {
    event.preventDefault();
    const response = await resendCode({
      email: email,
    });
    this.setState({ invalidCode: false });
    if (response.status === 200) {
      this.setState({ resentCode: true });
      this.setState({ resentCodeMessage: response.message });
    } else {
      this.setState({ resentCode: true });
      this.setState({ resentCodeMessage: 'Something went wrong' });
    }
  }

  partiallyHideEmail(email) {
    const [username, domain] = email.split('@');
    const maskedUsername = username.replace(/./g, '*');
    const visibleUsername = username[0] + maskedUsername.slice(1);
    return visibleUsername + '@' + domain;
  }

  goTosignin = () => {
    this.props.history.push('/login');
  };
  validateEmail(event) {
    this.setState({ email: event.target.value });
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
      this.setState({ validEmailError: true });
      this.setState({ message: 'Invalid Email Address' });
    } else {
      this.setState({ validEmailError: false });
      this.setState({ message: '' });
    }
  }
  matchPassword(event) {
    this.setState({ confirmPassword: event.target.value });
    if (this.state.password !== event.target.value) {
      this.setState({ matchPasswordError: true });
    } else {
      this.setState({ matchPasswordError: false });
    }
  }
  handlePasswordOnchange(event) {
    this.setState({ password: event.target.value });
    this.checkStrongPassword();
  }
  checkStrongPassword() {
    const strongPasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{5,12}$)');

    this.setState({
      strongPassword: strongPasswordRegex.test(this.state.password),
    });
  }

  render() {
    const isSignInDisabled =
      !this.state.name ||
      !this.state.email ||
      !this.state.password ||
      !this.state.confirmPassword ||
      this.state.matchPasswordError ||
      this.state.validEmailError ||
      !this.state.strongPassword;

    const { theme } = this.props;

    return (
      <Grid container style={{ height: '92vh' }}>
        <Grid
          item
          xs={12}
          md={6}
          style={{
            backgroundImage: `url(${signup})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            clipPath: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%)',
          }}
        ></Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box style={{ width: '50%' }}>
            <Typography variant="h4" gutterBottom>
              Sign up
            </Typography>
            <form onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                name="name"
                label="Name"
                autoFocus
                value={this.state.name}
                sx={{ backgroundColor: 'transparent' }}
                onChange={event => this.setState({ name: event.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                id="email"
                label="Email"
                value={this.state.email}
                sx={{ backgroundColor: 'transparent' }}
                onChange={event => this.validateEmail(event)}
                error={this.state.validEmailError}
                helperText={this.state.validEmailError ? this.state.message : ''}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Password"
                name="password"
                type={this.state.showPassword ? 'text' : 'password'}
                id="password"
                value={this.state.password}
                sx={{ backgroundColor: 'transparent' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        data-testid="change-password-visibilty"
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={event => {
                  this.handlePasswordOnchange(event);
                }}
                error={!this.state.strongPassword}
                helperText={
                  !this.state.strongPassword
                    ? 'Must be 6 characters or more, needs at least one digit, one special charater and one uppercase letter'
                    : ''
                }
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="confirm-password"
                label="Confirm password"
                type={this.state.showConfirmPassword ? 'text' : 'password'}
                id="confirm-password"
                value={this.state.confirmPassword}
                sx={{ backgroundColor: 'transparent' }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={this.handleClickShowConfirmPassword}
                        onMouseDown={this.handleMouseDownConfirmPassword}
                        data-testid="change-confirm-password-visibilty"
                      >
                        {this.state.showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={event => this.matchPassword(event)}
                error={this.state.matchPasswordError}
                helperText={this.state.matchPasswordError ? 'The password you entered do not match.' : ''}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isSignInDisabled}
                sx={{ backgroundColor: theme.palette.primary.main }}
              >
                Create Account
              </Button>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    margin: '15px 0px',
                    fontSize: '14px',
                  }}
                >
                  Already have an account?
                </Typography>
                <Typography
                  variant="caption"
                  onClick={this.goTosignin}
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.links.main,
                    transition: 'color 0.3s ease',
                    margin: '15px 0px',
                    fontSize: '14px',
                  }}
                >
                  &nbsp;Login
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
        {/* <ConfirmUserDialog verificationCodeDialog={this.state.verificationCodeDialog} email={this.state.email} /> */}
        <Dialog maxWidth="sm" fullWidth={true} open={this.state.verificationCodeDialog}>
          <DialogTitle>Verify your account</DialogTitle>
          <DialogContent dividers>
            <Typography>
              Please enter the verification code sent to your email{' '}
              {this.partiallyHideEmail(this.state.email)}
            </Typography>
            {this.state.resentCode && (
              <Typography sx={{ color: 'black' }}>{this.state.resentCodeMessage}</Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="verification-code"
              label="Verification Code"
              value={this.state.verificationCode}
              onChange={event => this.handleVerificationCode(event)}
              error={this.state.invalidCode}
              helperText={this.state.invalidCode ? this.state.invalidCodeMessage : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{ mx: 2, my: 1 }}
              onClick={event => this.resendCode(event, this.state.email)}
            >
              Resend code
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 2, my: 1 }}
              onClick={event =>
                this.submitVerificationCode(event, this.state.email, this.state.verificationCode)
              }
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

export default withStyles(styles, { withTheme: true })(SignupPage);
