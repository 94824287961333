import { Card, CardContent, Divider, Grid, TextField, Typography } from '@mui/material';
import React from 'react';
import CountrySelector from '../CountrySelector';

const ProjectForm = ({ formState, handleProjectTitle, handleCountryChange, handleInputChange }) => (
    <Card sx={{ mb: 3, width: "100%" }}>
        <CardContent>
            <Typography variant="h6" gutterBottom sx={{ color: '#4E5FBF', display: 'flex', alignItems: 'center' }}>
                Project Info
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        name="projectTitle"
                        label="Project Title"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={formState.projectTitle}
                        onChange={handleProjectTitle}
                        size='small'
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <CountrySelector
                        value={formState.projectInformation.country}
                        onChange={handleCountryChange}
                    />
                </Grid>
            </Grid>
            <Typography variant="h6" gutterBottom sx={{ color: '#4E5FBF', display: 'flex', alignItems: 'center' }}>
                Project Details
            </Typography>
            <Divider sx={{ mb: 2 }} />
            <Grid container spacing={2}>
                {[
                    { name: "number", label: "Number" },
                    { name: "client", label: "Client" },
                    { name: "description", label: "Description" },
                ].map((field, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                        <TextField
                            name={`projectInformation.projectDetails.${field.name}`}
                            label={field.label}
                            fullWidth
                            margin="normal"
                            InputLabelProps={{ shrink: true }}
                            value={formState.projectInformation.projectDetails[field.name]}
                            onChange={handleInputChange}
                            size='small'
                        />
                    </Grid>
                ))}
            </Grid>
        </CardContent>
    </Card>
);

export default ProjectForm;
