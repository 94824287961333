import { fabric } from 'fabric';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pixelsToMeterConversion } from '../../constants/constants';
import {
  checkForOverlaps,
  closestPointOnLine,
  generateUniqueId,
  isOverEquipment,
  mmToInches,
  getAdjustedStrokeWidth,
  setLineProperties,
} from '../../functions/utilFunctions';
import { setDrawingMode, setDuctDistance, setPointDistance } from '../../redux/slice/drawingSlice';
import HeaderUi from './HeaderUI/HeaderUi';

const Header = ({
  projectData,
  toggleLineButton,
  showUploadPdfButton,
  fileReaderInfo,
  toggleUploadPdfButton,
  onFileChange,
  calculateScale,
  canvas,
  paperType,
  // scalingRatio,
  showLineButton,
  showScaleNextToCalculate,
  onPaperSizeChange,
  selectedDistanceRatio,
  showScaleButton,
  selectedPaperSize,
  onDistanceRatioChange,
  lockPdf,
  download,
  drawnLines,
  setDrawnLines,
  setElbows,
  save,
  setSelectedLine,
  equipments,
  tees,
  elbows,
  transitions,
  setTransitions,
  setEquipments,
  setTees,
  setSelectedTee,
  selectedEquipment,
  setSelectedEquipment,
  setSelectedElbow,
}) => {
  const [isPdfLocked, setIsPdfLocked] = useState(true);
  const dispatch = useDispatch();
  const { selectedlevel, pointDistance, pdfScale } = useSelector(state => state.drawing);
  const unitSystem = projectData?.projectCreation?.[0]?.projectInformation?.unitSystem ?? 'Imperial';
  const lineCalculation = projectData?.projectCreation?.[0]?.systemConfiguration?.components
  const linesRef = useRef(drawnLines);
  const teesRef = useRef(tees);
  const elbowsRef = useRef(elbows);
  const transitionsRef = useRef(transitions);

  useEffect(() => {
    linesRef.current = drawnLines;
  }, [drawnLines]);

  useEffect(() => {
    teesRef.current = tees;
  }, [tees]);

  useEffect(() => {
    elbowsRef.current = elbows;
  }, [elbows]);

  useEffect(() => {
    transitionsRef.current = transitions;
  }, [transitions]);

  let undoStack = [];
  let redoStack = [];

  const lockPdfIcon = () => {
    setIsPdfLocked(!isPdfLocked);
  };

  const handleLockButton = () => {
    lockPdfIcon();
    lockPdf();
  };

  const levelName = selectedlevel?.name || 'L1';
  const totalDiatance =
    pointDistance.length > 0 && pointDistance.reduce((acc, val) => acc + parseFloat(val), 0).toFixed(2);

  // useEffect(() => {
  //   dispatch(setDuctDistance(totalDiatance));
  // }, [totalDiatance]);

  const lineStrokeWidth = 1;
  const teeWidth = 3;
  const teeOverLap = 0.6;
  let initialHoverEquipment = null;

  function createPolyline(canvas) {
    let mouseDown = false;
    let tempHorizontalGuideline = null;
    let tempVerticalGuideline = null;
    let oldLines = [];
    let hoverLine = null;
    let currentLine = null;
    let isDrawing = false;
    let startPoint = null;
    let previewLine = null;
    let isFirstClick = true;
    let previousLine = null;
    let previousLineUpdate = null;
    let elbow;
    const elbowOffset = 1;

    function stopDrawing() {
      dispatch(setDrawingMode(false));
      // Remove drawing-related event listeners
      canvas.off('mouse:down', startDrawingHandler);
      canvas.off('mouse:move', updatePreviewLineHandler);
      canvas.off('mouse:up', endDrawingHandler);
      mouseDown = false;
      // Remove preview line if exists
      if (previewLine) {
        canvas.remove(previewLine);
        previewLine = null;
      }
      document.removeEventListener('keydown', onKeyDown);
      document.getElementById('stopButton').removeEventListener('click', stopDrawing);
    }

    function onKeyDown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        stopDrawing();
        document.removeEventListener('keydown', onKeyDown);
        document.getElementById('stopButton').removeEventListener('click', stopDrawing);
      }
      // Remove guidelines if exist
      if (tempHorizontalGuideline) {
        canvas.remove(tempHorizontalGuideline);
        tempHorizontalGuideline = null;
      }
      if (tempVerticalGuideline) {
        canvas.remove(tempVerticalGuideline);
        tempVerticalGuideline = null;
      }
    }
    document.getElementById('stopDrawMode')?.addEventListener('click', stopDrawing);
    document.addEventListener('keydown', onKeyDown);

    // Function to start or continue drawing
    const startDrawing = options => {
      const pointer = canvas.getPointer(options.e);

      if (!isDrawing) {
        isDrawing = true;
        // If duct drawn over equipment start duct from center of equipment
        initialHoverEquipment = isOverEquipment(pointer, equipments);
        if (initialHoverEquipment) {
          startPoint = {
            x: initialHoverEquipment.position.left,
            y: initialHoverEquipment.position.top,
          };
        } else {
          startPoint = pointer;
        }
        isFirstClick = true;
      } else {
        const snappedPoint = getSnappedPoint(pointer);
        currentLine = new fabric.Line([startPoint.x, startPoint.y, snappedPoint.x, snappedPoint.y], {
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
          stroke: 'red',
          level: levelName,
          id: generateUniqueId(),
          originX: 'center',
          originY: 'center',
          lockMovementX: true,
          lockMovementY: true,
          strokeLineJoin: 'round',
          lockScalingX: true,
          lockScalingY: true,
          hasControls: false,
        });

        currentLine.on('mousedown', event => {
          setSelectedLine(currentLine);
        });

        // Add rectangle at the startPoint, but not on the first click
        if (!isFirstClick && previousLine) {
          elbow = addElbowAtPoint(startPoint, previousLine, currentLine);
        }

        canvas.add(currentLine);
        canvas.bringToFront(currentLine);
        canvas.requestRenderAll();

        // Update the equipment with the duct ID after creating the currentLine
        if (initialHoverEquipment) {
          setEquipments(prevEquipments => {
            const updatedEquipments = [...prevEquipments];
            const optionIndex = updatedEquipments.findIndex(eq => eq.id === initialHoverEquipment.id);

            if (optionIndex !== -1) {
              const equipment = { ...updatedEquipments[optionIndex] };
              equipment.ductId = currentLine.id;
              updatedEquipments[optionIndex] = equipment;
            }

            return updatedEquipments;
          });
          // Reset initialHoverEquipment after updating
          initialHoverEquipment = null;
        }

        startPoint = snappedPoint;
        isFirstClick = false;
        previousLine = currentLine;
        isDrawing = true;
      }
    };

    const adjustLinesForElbow = (previousLine, currentLine) => {
      const directionPrevious = previousLine.direction;
      const directionCurrent = currentLine.direction;
      const adjustedElbowOffset = getAdjustedStrokeWidth(pdfScale, paperType, elbowOffset);
      // Adjusting previous line coordinates
      if (directionPrevious === 'Right') {
        previousLine.x2 -= adjustedElbowOffset;
      } else if (directionPrevious === 'Left') {
        previousLine.x2 += adjustedElbowOffset;
      } else if (directionPrevious === 'Up') {
        previousLine.y2 += adjustedElbowOffset;
      } else if (directionPrevious === 'Down') {
        previousLine.y2 -= adjustedElbowOffset;
      }

      // Adjusting current line coordinates
      if (directionCurrent === 'Right') {
        currentLine.x1 += adjustedElbowOffset;
      } else if (directionCurrent === 'Left') {
        currentLine.x1 -= adjustedElbowOffset;
      } else if (directionCurrent === 'Up') {
        currentLine.y1 -= adjustedElbowOffset;
      } else if (directionCurrent === 'Down') {
        currentLine.y1 += adjustedElbowOffset;
      }
      return [previousLine, currentLine];
    };

    const getElbowAngle = (previousLine, currentLine) => {
      const directionPrevious = previousLine.direction;
      const directionCurrent = currentLine.direction;

      // Define the angle mapping based on direction changes
      const angleMapping = {
        'Right-Up': 90,
        'Right-Down': 0,
        'Right-Left': 0,

        'Left-Up': 180,
        'Left-Down': 270,
        'Left-Right': 0,

        'Up-Right': 270,
        'Up-Left': 0,
        'Up-Down': 0,

        'Down-Right': 180,
        'Down-Left': 90,
        'Down-Up': 0,
      };

      // Create a key based on the direction change
      const directionChange = `${directionPrevious}-${directionCurrent}`;

      // Return the corresponding angle or 0 if no change (straight line)
      return angleMapping[directionChange] || 0;
    };

    // Function to add rectangle at a given point
    const addElbowAtPoint = (point, line1, line2) => {
      let newElbowLength = getAdjustedStrokeWidth(pdfScale, paperType, teeWidth);
      let elbow = new fabric.Rect({
        hasControls: false,
        id: generateUniqueId(),
        left: point.x,
        top: point.y,
        fill: '#4a90e2',
        width: newElbowLength,
        height: newElbowLength,
        originX: 'center',
        originY: 'center',
        level: levelName,
        elbowType: 'Rectangular',
        lineIds: [line1.id, line2.id],
        connection: {
          line1: {
            id: line1.id,
            flow: 'in',
          },
          line2: {
            id: line2.id,
            flow: 'out',
          },
        },
        bendAngle: 90,
        bendRadius: unitSystem === 'Imperial' ? mmToInches(50) : 50,
        rotationAngle: 0,
        shadow: new fabric.Shadow({
          color: 'rgba(0,0,0,0.3)',
          blur: 5,
          offsetX: 2,
          offsetY: 2,
        }),
      });

      // Event listener for selection
      elbow.on('mousedown', event => {
        setSelectedElbow(elbow);
      });

      // Add both shapes to the canvas
      canvas.add(elbow);
      canvas.bringToFront(elbow);

      return elbow;
    };

    // Function to update the preview line and guidelines
    const updatePreviewLine = options => {
      if (!isDrawing) return;
      const pointer = canvas.getPointer(options.e);
      const snappedPoint = getSnappedPoint(pointer);
      if (previewLine) {
        canvas.remove(previewLine);
      }

      previewLine = new fabric.Line([startPoint.x, startPoint.y, snappedPoint.x, snappedPoint.y], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        level: levelName,
        id: generateUniqueId(),
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        strokeLineJoin: 'round',
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });
      canvas.add(previewLine);
      updateGuidelines(snappedPoint);
      canvas.renderAll();
    };

    // Function to update guidelines
    const updateGuidelines = snappedPoint => {
      if (tempHorizontalGuideline) {
        canvas.remove(tempHorizontalGuideline);
      }
      if (tempVerticalGuideline) {
        canvas.remove(tempVerticalGuideline);
      }

      tempHorizontalGuideline = new fabric.Line([0, snappedPoint.y, canvas.getWidth(), snappedPoint.y], {
        stroke: 'blue',
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        strokeDashArray: [5, 5],
        opacity: 0.5,
        selectable: false,
        evented: false,
        originX: 'center',
        originY: 'center',
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });

      tempVerticalGuideline = new fabric.Line([snappedPoint.x, 0, snappedPoint.x, canvas.getHeight()], {
        stroke: 'blue',
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        strokeDashArray: [5, 5],
        opacity: 0.5,
        selectable: false,
        evented: false,
        originX: 'center',
        originY: 'center',
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });

      canvas.add(tempHorizontalGuideline);
      canvas.add(tempVerticalGuideline);
    };

    // Function to get the snapped point for 90 and 180 degrees
    const getSnappedPoint = pointer => {
      const dx = pointer.x - startPoint.x;
      const dy = pointer.y - startPoint.y;
      if (Math.abs(dx) > Math.abs(dy)) {
        return { x: pointer.x, y: startPoint.y };
      } else {
        return { x: startPoint.x, y: pointer.y };
      }
    };

    // Handlers for drawing-related events
    function startDrawingHandler(options) {
      startDrawing(options);
      if (previewLine) {
        canvas.remove(previewLine);
        previewLine = null;
      }
      if (tempHorizontalGuideline) {
        canvas.remove(tempHorizontalGuideline);
        tempHorizontalGuideline = null;
      }
      if (tempVerticalGuideline) {
        canvas.remove(tempVerticalGuideline);
        tempVerticalGuideline = null;
      }
    }

    function updatePreviewLineHandler(options) {
      updatePreviewLine(options);
    }

    function endDrawingHandler(options) {
      currentLine = setLineProperties(currentLine, unitSystem, lineCalculation.mains);
      // setDrawnLines(prevLines => {
      //   oldLines = [...prevLines, currentLine];
      //   return [...prevLines, currentLine];
      // });
      if (!isFirstClick && previousLineUpdate) {
        // Adjust the end of the previous line and the start of the new line based on directions(avoid elbow overlap)
        [previousLineUpdate, currentLine] = adjustLinesForElbow(previousLineUpdate, currentLine);
        const elbowAngle = getElbowAngle(previousLineUpdate, currentLine);
        elbow.rotationAngle = elbowAngle;
        const updatedLines = linesRef.current.map(line => {
          if (line.id === previousLineUpdate?.id) {
            line.x1 = previousLineUpdate.x1;
            line.y1 = previousLineUpdate.y1;
            line.x2 = previousLineUpdate.x2;
            line.y2 = previousLineUpdate.y2;
            return line;
          }
          return line;
        });
        setDrawnLines([...updatedLines, currentLine]);
        setElbows(prevElbows => [...prevElbows, elbow]);
      } else {
        setDrawnLines(prevLines => [...prevLines, currentLine]);
      }
      previousLineUpdate = currentLine;
    }

    canvas.on('mouse:down', startDrawingHandler);
    canvas.on('mouse:move', updatePreviewLineHandler);
    canvas.on('mouse:up', endDrawingHandler);
  }

  function createLine(canvas) {
    let mouseDown = false;
    let currentLine = null;
    let tempHorizontalGuideline = null;
    let tempVerticalGuideline = null;
    let oldLines = [];
    let currentArrowheads = [];
    let hoverLine = null;
    let hoverEquipment = null;

    function stopDrawing() {
      dispatch(setDrawingMode(false));
      // Remove drawing-related event listeners
      canvas.off('mouse:down', mouseDownHandler);
      canvas.off('mouse:move', mouseMoveHandler);
      canvas.off('mouse:up', mouseUpHandler);
      mouseDown = false;
      document.removeEventListener('keydown', onKeyDown);
      document.getElementById('stopButton').removeEventListener('click', stopDrawing);
    }

    function onKeyDown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        stopDrawing();
        document.removeEventListener('keydown', onKeyDown);
        document.getElementById('stopButton').removeEventListener('click', stopDrawing);
      }
    }
    document.getElementById('stopDrawMode')?.addEventListener('click', stopDrawing);
    document.addEventListener('keydown', onKeyDown);

    function drawArrowhead(prevX, prevY, x, y) {
      const arrowheadSize = 5;
      const angle = Math.atan2(y - prevY, x - prevX);

      const offsetX = (Math.cos(angle) * arrowheadSize) / 2 + lineStrokeWidth / 2;
      const offsetY = (Math.sin(angle) * arrowheadSize) / 2 + lineStrokeWidth / 2;

      const arrowhead = new fabric.Triangle({
        left: (x + prevX) / 2 + offsetX,
        top: (y + prevY) / 2 + offsetY,
        originX: 'center',
        originY: 'center',
        angle: angle * (180 / Math.PI) + 90,
        width: arrowheadSize,
        height: arrowheadSize,
        //fill: "red".hex,
        level: levelName,
        fill: 'black',
        opacity: 0.5,
        selectable: false,
      });

      currentArrowheads.push(arrowhead);
      canvas.add(arrowhead);
      return currentArrowheads;
    }

    function mouseDownHandler(event) {
      mouseDown = true;
      hoverEquipment = null;
      let pointer = canvas.getPointer(event.e);

      // If duct drawn over equipment start duct from center of equipment
      hoverEquipment = isOverEquipment(pointer, equipments);
      if (hoverEquipment) {
        pointer = {
          x: hoverEquipment.position.left,
          y: hoverEquipment.position.top,
        };
      }

      // If duct drawn over another duct, start current duct from point on hovered duct
      if (hoverLine) {
        const closestPoint = closestPointOnLine(
          hoverLine.x1,
          hoverLine.y1,
          hoverLine.x2,
          hoverLine.y2,
          pointer.x,
          pointer.y,
        );
        pointer = closestPoint;
      }

      const line = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        level: levelName,
        id: generateUniqueId(),
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });

      line.on('mousedown', event => {
        setSelectedLine(line);
      });
      currentLine = line;
      currentArrowheads = [];

      canvas.add(line);
      canvas.bringToFront(line);
      canvas.requestRenderAll();

      // Update the equipment with the duct ID after creating the currentLine
      if (hoverEquipment) {
        setEquipments(prevEquipments => {
          const updatedEquipments = [...prevEquipments];
          const optionIndex = updatedEquipments.findIndex(eq => eq.id === hoverEquipment.id);

          if (optionIndex !== -1) {
            const equipment = { ...updatedEquipments[optionIndex] };
            equipment.ductId = line.id;
            updatedEquipments[optionIndex] = equipment;
          }
          return updatedEquipments;
        });
      }

      undoStack.push({ line, arrowheads: [] });
      redoStack = [];
    }

    function mouseMoveHandler(event) {
      const pointer = canvas.getPointer(event.e);

      if (mouseDown) {
        minDistance = 1;
        const gridStep = 1;
        const isCtrlPressed = event.e.ctrlKey;
        let snappedX, snappedY;

        if (isCtrlPressed) {
          snappedX = pointer.x;
          snappedY = pointer.y;
        } else {
          const dx = Math.abs(pointer.x - currentLine.x1);
          const dy = Math.abs(pointer.y - currentLine.y1);

          if (dx > dy) {
            snappedX = pointer.x;
            snappedY = currentLine.y1;
          } else {
            snappedX = currentLine.x1;
            snappedY = pointer.y;
          }
        }

        const lines = canvas.getObjects('line');
        let closestX = snappedX;
        let closestY = snappedY;

        let snapToVertical = false;
        let snapToHorizontal = false;

        lines.forEach(line => {
          if (line === currentLine) return;
          const lineX1 = line.x1;
          const lineY1 = line.y1;
          const lineX2 = line.x2;
          const lineY2 = line.y2;

          if (lineX1 === lineX2) {
            // Perfectly vertical line
            const distanceToX = Math.abs(snappedX - lineX1);
            if (distanceToX < minDistance) {
              closestX = lineX1;
              snapToVertical = true;
            }
          }

          if (lineY1 === lineY2) {
            // Perfectly horizontal line
            const distanceToY = Math.abs(snappedY - lineY1);
            if (distanceToY < minDistance) {
              closestY = lineY1;
              snapToHorizontal = true;
            }
          }
        });
        if (snapToVertical) {
          currentLine.set({ x2: closestX, y2: currentLine.y1 });
        } else if (snapToHorizontal) {
          currentLine.set({ x2: currentLine.x1, y2: closestY });
        } else {
          currentLine.set({ x2: snappedX, y2: snappedY });
        }

        // Create guidelines on snapping
        if (snapToHorizontal) {
          if (!tempHorizontalGuideline) {
            tempHorizontalGuideline = new fabric.Line([0, closestY, canvas.width, closestY], {
              strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
              stroke: 'blue',
              strokeDashArray: [5, 5],
              selectable: false,
              evented: false,
              originX: 'center',
              originY: 'center',
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            });
            canvas.add(tempHorizontalGuideline);
          } else {
            tempHorizontalGuideline.set({ x1: 0, y1: closestY, x2: canvas.width, y2: closestY });
          }
        } else {
          if (tempHorizontalGuideline) {
            canvas.remove(tempHorizontalGuideline);
            tempHorizontalGuideline = null;
          }
        }

        if (snapToVertical) {
          if (!tempVerticalGuideline) {
            tempVerticalGuideline = new fabric.Line([closestX, 0, closestX, canvas.height], {
              strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
              stroke: 'blue',
              strokeDashArray: [5, 5],
              selectable: false,
              evented: false,
              originX: 'center',
              originY: 'center',
              lockScalingX: true,
              lockScalingY: true,
              hasControls: false,
            });
            canvas.add(tempVerticalGuideline);
          } else {
            tempVerticalGuideline.set({ x1: closestX, y1: 0, x2: closestX, y2: canvas.height });
          }
        } else {
          if (tempVerticalGuideline) {
            canvas.remove(tempVerticalGuideline);
            tempVerticalGuideline = null;
          }
        }
        canvas.requestRenderAll();
      } else {
        hoverLine = null;
        const lines = canvas.getObjects('line');
        lines.forEach(line => {
          const closestPoint = closestPointOnLine(line.x1, line.y1, line.x2, line.y2, pointer.x, pointer.y);
          const distance = Math.hypot(pointer.x - closestPoint.x, pointer.y - closestPoint.y);
          if (distance < 1) {
            hoverLine = line;
            return false;
          }
        });
      }
    }

    function mouseUpHandler(event) {
      mouseDown = false;
      let prevX = currentLine.x1;
      let prevY = currentLine.y1;
      let heads = [];

      currentLine = setLineProperties(currentLine, unitSystem, lineCalculation.branches);

      // const numberOfArrows = 5;
      // for (let i = 1; i <= numberOfArrows; i++) {
      //   const ratio = i / (numberOfArrows + 1);
      //   const x = currentLine.x1 + (currentLine.x2 - currentLine.x1) * ratio;
      //   const y = currentLine.y1 + (currentLine.y2 - currentLine.y1) * ratio;
      //   heads = drawArrowhead(prevX, prevY, x, y);
      //   prevX = x;
      //   prevY = y;
      // }
      // currentLine.arrows = heads;
      const startPoint = [currentLine.x1, currentLine.y1];
      const endPoint = [currentLine.x2, currentLine.y2];
      const distanceInPixels = Math.sqrt(
        Math.pow(endPoint[0] - startPoint[0], 2) + Math.pow(endPoint[1] - startPoint[1], 2),
      );
      const distanceInMeters = (distanceInPixels * pixelsToMeterConversion).toFixed(2);
      dispatch(setPointDistance(distanceInMeters));
      dispatch(setDuctDistance(distanceInMeters));
      setDrawnLines(prevLines => {
        oldLines = [...prevLines, currentLine];
        return [...prevLines, currentLine];
      });
      undoStack[undoStack.length - 1].arrowheads = currentArrowheads;
      let [oldLineOverlapIndex, overlapPoint] = checkForOverlaps(oldLines, currentLine) || [null, null];
      const oldIntersectionLine = oldLines[oldLineOverlapIndex];

      // Create Tee if overlapping and split old line
      if (oldIntersectionLine) {
        // Determine the angle of the intersection
        let angle;
        if (Math.abs(currentLine.x2 - currentLine.x1) > Math.abs(currentLine.y2 - currentLine.y1)) {
          // Horizontal line
          if (currentLine.x2 > overlapPoint.x || currentLine.x1 > overlapPoint.x) {
            angle = 270;
          } else {
            angle = 90;
          }
        } else {
          // Vertical line
          if (currentLine.y1 > overlapPoint.y || currentLine.y2 > overlapPoint.y) {
            angle = 180;
          } else {
            angle = 0;
          }
        }
        console.log('angle:', angle);

        // Determine if the line is drawn from outside to inside to adjust appropriate end of line
        const isFromOutsideToInside =
          (angle === 180 && currentLine.y2.toFixed(2) === overlapPoint.y.toFixed(2)) ||
          (angle === 0 && currentLine.y2.toFixed(2) === overlapPoint.y.toFixed(2)) ||
          (angle === 90 && currentLine.x2.toFixed(2) === overlapPoint.x.toFixed(2)) ||
          (angle === 270 && currentLine.x2.toFixed(2) === overlapPoint.x.toFixed(2));

        console.log('isFromOutsideToInside:', isFromOutsideToInside);
        const adjustedTeeOverlap = getAdjustedStrokeWidth(pdfScale, paperType, teeOverLap);

        // Adjust currentLine coordinates based on angle and drawing direction
        if (angle === 180 || angle === 0) {
          // Horizontal line
          // Angle = 0 => top incident, 180 => bottom incident
          if (isFromOutsideToInside) {
            currentLine.y2 += angle === 180 ? adjustedTeeOverlap : -adjustedTeeOverlap;
          } else {
            currentLine.y1 += angle === 180 ? adjustedTeeOverlap : -adjustedTeeOverlap;
          }
        } else if (angle === 90 || angle === 270) {
          // Vertical line
          // Angle = 90 => left incident, 270 => right incident
          if (isFromOutsideToInside) {
            currentLine.x2 += angle === 270 ? adjustedTeeOverlap : -adjustedTeeOverlap;
          } else {
            currentLine.x1 += angle === 270 ? adjustedTeeOverlap : -adjustedTeeOverlap;
          }
        }

        const index = oldLines.findIndex(element => element.id === currentLine.id);
        if (index !== -1) {
          oldLines[index] = currentLine;
        }

        let [newLine1, newLine2] = splitLine(oldIntersectionLine, overlapPoint, angle);
        newLine1 = setLineProperties(newLine1, unitSystem, lineCalculation.branches);
        newLine2 = setLineProperties(newLine2, unitSystem, lineCalculation.branches);

        oldLines = oldLines.filter(element => element.id !== oldIntersectionLine.id);
        oldLines.push(newLine1, newLine2);

        // Create a modern, 3D T-shaped path
        const teeSize = getAdjustedStrokeWidth(pdfScale, paperType, teeWidth);
        const stemWidth = teeSize / 4;
        const path = [
          'M',
          teeSize / 2,
          -stemWidth / 2,
          'L',
          teeSize / 2,
          stemWidth / 2,
          'L',
          teeSize / 2 + stemWidth,
          stemWidth / 2,
          'L',
          teeSize / 2 + stemWidth,
          teeSize / 2,
          'L',
          -teeSize / 2 - stemWidth,
          teeSize / 2,
          'L',
          -teeSize / 2 - stemWidth,
          stemWidth / 2,
          'L',
          -teeSize / 2,
          stemWidth / 2,
          'L',
          -teeSize / 2,
          -stemWidth / 2,
          'Z',
        ];

        let tee = new fabric.Rect({
          id: generateUniqueId(),
          left: overlapPoint.x,
          top: overlapPoint.y,
          fill: 'green',
          width: getAdjustedStrokeWidth(pdfScale, paperType, teeWidth),
          height: getAdjustedStrokeWidth(pdfScale, paperType, teeWidth),
          angle: angle,
          originX: 'center',
          originY: 'center',
          level: currentLine.level,
          lineIds: [currentLine.id, newLine1.id, newLine2.id],
          connection: {
            line1: {
              id: newLine1.id,
              flow: 'in',
            },
            line2: {
              id: newLine2.id,
              flow: 'out',
            },
            branch: {
              id: currentLine.id,
            },
          },
          teeType: 'Rectangular',
          fittingType: 'Tee',
          shadow: new fabric.Shadow({
            color: 'rgba(0,0,0,0.3)',
            blur: 5,
            offsetX: 2,
            offsetY: 2,
          }),
        });

        tee.on('mousedown', event => {
          setSelectedTee(tee);
        });

        canvas.add(tee);
        canvas.bringToFront(tee);

        const updatedTees = handleTees(oldIntersectionLine, newLine2);
        const combinedTees = [...updatedTees, tee];
        setTees(combinedTees);
        handleElbows(oldIntersectionLine, newLine2);
        handleTransitions(oldIntersectionLine, newLine2);
      }

      // Remove the temporary guidelines
      if (tempHorizontalGuideline) {
        canvas.remove(tempHorizontalGuideline);
        tempHorizontalGuideline = null;
      }
      if (tempVerticalGuideline) {
        canvas.remove(tempVerticalGuideline);
        tempVerticalGuideline = null;
      }
      setDrawnLines(oldLines);

      canvas.requestRenderAll();
    }

    let minDistance = 1;
    canvas.on('mouse:down', mouseDownHandler);
    canvas.on('mouse:move', mouseMoveHandler);
    canvas.on('mouse:up', mouseUpHandler);
  }

  // Replace previous incoming connection with new splitted line for tees
  const handleTees = (splittingLine, lineSplit2) => {
    if (teesRef.current.length === 0) {
      return [];
    }
    const updatedTees = teesRef.current.map(tee => {
      const updatedTee = fabric.util.object.clone(tee);
      if (updatedTee.connection.line1.id === splittingLine.id) {
        updatedTee.connection.line1.id = lineSplit2.id;
        return updatedTee;
      }
      return updatedTee;
    });
    return updatedTees;
  };

  // Replace previous incoming connection with new splitted line for elbows
  const handleElbows = (splittingLine, lineSplit2) => {
    if (elbowsRef.current.length === 0) {
      return;
    }
    const updatedElbows = elbowsRef.current.map(elbow => {
      const updatedElbow = fabric.util.object.clone(elbow);
      if (updatedElbow.connection.line1.id === splittingLine.id) {
        updatedElbow.connection.line1.id = lineSplit2.id;
        return updatedElbow;
      }
      return updatedElbow;
    });
    setElbows(updatedElbows);
  };

  // Replace previous incoming connection with new splitted line for transitions
  const handleTransitions = (splittingLine, lineSplit2) => {
    if (transitionsRef.current.length === 0) {
      return;
    }
    const updatedTransitions = transitionsRef.current.map(transition => {
      const updatedTransition = fabric.util.object.clone(transition);
      if (updatedTransition.connection.line1.id === splittingLine.id) {
        updatedTransition.connection.line1.id = lineSplit2.id;
        return updatedTransition;
      }
      return updatedTransition;
    });
    setTransitions(updatedTransitions);
  };

  // Function to split the line at Tee
  function splitLine(line, splitPoint, angle) {
    var x1 = line.x1,
      y1 = line.y1,
      x2 = line.x2,
      y2 = line.y2;

    const id2 = generateUniqueId();

    var line1, line2;

    if (angle === 180 || angle === 0) {
      // Adjust teeOverLap for X coordinates
      var teeOverLapX = -getAdjustedStrokeWidth(pdfScale, paperType, teeOverLap);
      line1 = new fabric.Line([x1, y1, splitPoint.x + teeOverLapX, splitPoint.y], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        // selectable: false,
        level: levelName,
        id: line.id,
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });

      line2 = new fabric.Line([splitPoint.x - teeOverLapX, splitPoint.y, x2, y2], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        // selectable: false,
        level: levelName,
        id: id2,
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });
    } else if (angle === 90 || angle === 270) {
      // Adjust teeOverLap for Y coordinates
      var teeOverLapY = -getAdjustedStrokeWidth(pdfScale, paperType, teeOverLap);

      line1 = new fabric.Line([x1, y1, splitPoint.x, splitPoint.y + teeOverLapY], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        // selectable: false,
        level: levelName,
        id: line.id,
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });

      line2 = new fabric.Line([splitPoint.x, splitPoint.y - teeOverLapY, x2, y2], {
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, lineStrokeWidth),
        stroke: 'red',
        // selectable: false,
        level: levelName,
        id: id2,
        originX: 'center',
        originY: 'center',
        lockMovementX: true,
        lockMovementY: true,
        lockScalingX: true,
        lockScalingY: true,
        hasControls: false,
      });
    }

    line1.on('mousedown', event => {
      setSelectedLine(line1);
    });
    line2.on('mousedown', event => {
      setSelectedLine(line2);
    });

    canvas.remove(line);
    canvas.add(line1);
    canvas.add(line2);
    return [line1, line2];
  }

  function undo() {
    if (undoStack.length > 0) {
      const removedObjects = undoStack.pop();
      redoStack.push(removedObjects);
      canvas.remove(removedObjects.line);
      removedObjects.arrowheads.forEach(arrowhead => canvas.remove(arrowhead));
      canvas.requestRenderAll();
    }
  }

  function redo() {
    if (redoStack.length > 0) {
      const restoredObjects = redoStack.pop();
      undoStack.push(restoredObjects);
      canvas.add(restoredObjects.line);
      restoredObjects.arrowheads.forEach(arrowhead => canvas.add(arrowhead));
      canvas.requestRenderAll();
    }
  }

  return (
    <div>
      <HeaderUi
        projectData={projectData}
        toggleUploadPdfButton={toggleUploadPdfButton}
        showUploadPdfButton={showUploadPdfButton}
        onFileChange={onFileChange}
        download={download}
        calculateScale={calculateScale}
        onDistanceRatioChange={onDistanceRatioChange}
        selectedDistanceRatio={selectedDistanceRatio}
        toggleLineCreation={toggleLineButton}
        // scalingRatio={scalingRatio}
        createLine={createLine}
        createPolyline={createPolyline}
        toggleScaleButton={toggleLineButton}
        showLineButton={showLineButton}
        showScaleNextToCalculate={showScaleNextToCalculate}
        onPaperSizeChange={onPaperSizeChange}
        canvas={canvas}
        paperType={paperType}
        handleLockButton={handleLockButton}
        isPdfLocked={isPdfLocked}
        toggleLineButton={toggleLineButton}
        fileReaderInfo={fileReaderInfo}
        showScaleButton={showScaleButton}
        selectedPaperSize={selectedPaperSize}
        save={save}
        drawnLines={drawnLines}
        setDrawnLines={setDrawnLines}
        tees={tees}
        elbows={elbows}
        equipments={equipments}
        setTees={setTees}
        setElbows={setElbows}
        setTransitions={setTransitions}
        setEquipments={setEquipments}
        setSelectedLine={setSelectedLine}
        selectedEquipment={selectedEquipment}
        setSelectedEquipment={setSelectedEquipment}
      />
    </div>
  );
};

export default React.memo(Header);
