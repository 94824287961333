import { Box, Button, FormControl, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { fabric } from 'fabric';
import React, { useEffect, useState } from 'react';
import teePanelstyles from '../PanelStyles/TeePanelStyles';

const ElbowPanel = ({ closeElbowPanel, selectedElbow, setElbows, elbows }) => {
  const [bendAngle, setBendAngle] = useState(selectedElbow?.bendAngle);
  const [bendRadius, setBendRadius] = useState(selectedElbow?.bendRadius);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBendAngle(selectedElbow?.bendAngle);
    setBendRadius(selectedElbow?.bendRadius);
  }, [selectedElbow]);

  const handleDelete = () => {
    if (selectedElbow?.id) {
      const filteredItems = elbows?.filter(item => item.id !== selectedElbow.id);
      setElbows(filteredItems);
      closeElbowPanel();
    }
  };

  const handleBendAngle = e => {
    const updatedElbows = elbows.map(elbow => {
      const updatedElbow = fabric.util.object.clone(elbow);
      if (elbow.id === selectedElbow?.id) {
        updatedElbow.bendAngle = e.target.value;
        setBendAngle(e.target.value);
        return updatedElbow;
      }
      return updatedElbow;
    });
    setElbows(updatedElbows);
  };

  const handleBendRadius = e => {
    const updatedElbows = elbows.map(elbow => {
      const updatedElbow = fabric.util.object.clone(elbow);
      if (elbow.id === selectedElbow?.id) {
        let radius = parseFloat(e.target.value);
        updatedElbow.bendRadius = radius;
        setBendRadius(radius);
        return updatedElbow;
      }
      return updatedElbow;
    });
    setElbows(updatedElbows);
  };

  return (
    <Box sx={teePanelstyles.container}>
      <Typography variant="h6">Elbow</Typography>
      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="bendAngle">Angle of bend</InputLabel>
          <Select
            labelId="bendAngle"
            id="bendAngle"
            label="Angle of bend"
            defaultValue={90}
            value={bendAngle}
            onChange={handleBendAngle}
          >
            <MenuItem value={0}>0</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={45}>45</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={75}>75</MenuItem>
            <MenuItem value={90}>90</MenuItem>
            <MenuItem value={110}>110</MenuItem>
            <MenuItem value={130}>130</MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={180}>180</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={teePanelstyles.controlGroup}>
        <TextField
          type="number"
          variant="outlined"
          label="Radius of bend"
          sx={{ width: '100%', flex: 1, textAlign: 'right' }}
          value={bendRadius}
          onChange={handleBendRadius}
        />
      </Box>

      <Box sx={teePanelstyles.buttonGroup}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDelete}
          sx={{ bgcolor: 'red', color: 'white' }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={closeElbowPanel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default ElbowPanel;
