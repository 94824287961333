// elbowPanelStyles.js
const elbowPanelStyles = {
  container: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 300,
    maxHeight: '60vh',
    bgcolor: 'background.paper',
    boxShadow: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 1,
    zIndex: 1000,
    overflowY: 'auto',
  },
  centered: {
    textAlign: 'center',
    mb: 2,
  },
  controlGroup: {
    width: '100%',
    mb: 2,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rotateButton: {
    p: 1,
  },
  inputNumber: {
    width: 80,
    '& .MuiOutlinedInput-root': {
      fontSize: 13,
      borderRadius: 1,
      borderColor: '#ccc',
      '&:hover fieldset': {
        borderColor: '#007bff',
      },
    },
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
};

export default elbowPanelStyles;
