export const equipmentsOptions = [
  { value: 'AHU', label: 'AHU', icon: '/images/Ahu_Outdoor_Black.png', height: 30, width: 30 },
  {
    value: 'Diffuser',
    label: 'Diffuser',
    icon: '/images/Diffuser.png',
    height: 30,
    width: 30,
  },
  { value: 'Elbow', label: 'Elbow', icon: '/images/Elbow.png', height: 30, width: 30 },
];
