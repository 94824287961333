// diffuserPanelStyles.js

const diffuserPanelStyles = {
  container: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 365,
    maxHeight: '60vh',
    backgroundColor: '#fff',
    boxShadow: 3,
    padding: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 1,
    zIndex: 1000,
    overflowY: 'auto',
  },
  title: {
    marginBottom: 2,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    columnGap: 2,
    marginTop: 2,
  },
  button: {
    marginRight: 1,
  },
  divider: {
    marginBottom: 2,
  },
};

export default diffuserPanelStyles;
