import React from "react";
import { Grid, Box, Divider, Link, Typography } from "@mui/material";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";

const Contact = () => {
  
  const styles = {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    width: "60%",
    justifyContent: { xs: "flex-start", lg: "space-around" },
    height: "100%",
    alignItems: { xs: "flex-start", md: "center" },
    flexWrap: "wrap",
    rowGap: 5,
    columnGap: 6,
  };
  const styles2 = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: "10px"
  };

  return (
    <Box
      id="contact"
      sx={{
        backgroundColor: "#4E5FBF",
        color: "white",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-evenly",
        alignItems: { xs: "baseline", md: "center" },
        columnGap: 5,
        rowGap: 3,
        p: 4,
        pb: 8,
        zIndex: 1,
      }}
    >
      <Box sx={styles}>
        <Typography variant="h4" sx={{ display: { xs: "flex", md: "none" } }}>
          Contacts
        </Typography>
        <Box sx={styles2}>
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: { xs: "1.25rem", md: "2rem" },
            }}
          >
            Call
          </Typography>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            <Grid display={{ xs: "none", md: "block" }} >
              <CallOutlinedIcon sx={{ verticalAlign: "middle" }} />

            </Grid>
            +1 8185712890
          </Typography>
        </Box>
        <Box sx={styles2}>
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: { xs: "1.25rem", md: "2rem" },
            }}
          >
            Email
          </Typography>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            <Grid display={{ xs: "none", md: "block" }} ><EmailOutlinedIcon sx={{ verticalAlign: "middle" }} /></Grid>
            info@bim-bots.com
          </Typography>
        </Box>
        <Box sx={styles2}>
          <Typography
            variant="h4"
            sx={{
              display: "flex",
              alignItems: "center",
              fontSize: { xs: "1.25rem", md: "2rem" },
            }}
          >
            Address
          </Typography>
          <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >   <Grid display={{ xs: "none", md: "block" }} >
              <LocationOnOutlinedIcon sx={{ verticalAlign: "middle" }} />
            </Grid> Artesia, CA
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          width: "auto",
          columnGap: 10,
          display: "flex",
          height: "100%",
        }}
      >
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Divider
            variant="fullWidth"
            orientation="vertical"
            sx={{backgroundColor: "white"}}
          ></Divider>
        </Box>
        <Box
          id="details-div"
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-around",
            flexDirection: "column",
            paddingTop: "2vh",
            rowGap: 4,
          }}
        >
          <Typography
            variant="h4"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Company
          </Typography>
          <Link href="#about-us" color="inherit" underline="none">
            <Typography variant="h6">About</Typography>
          </Link>
          {/* <Typography
            variant="h6"
            sx={{ display: "flex", alignItems: "center", columnGap: 1 }}
          >
            Pricing
          </Typography> */}
        </Box>
      </Box>
    </Box>
  );
};

export default Contact;
