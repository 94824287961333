import MenuIcon from '@mui/icons-material/Menu';
import SaveIcon from '@mui/icons-material/Save';
import SettingsIcon from '@mui/icons-material/Settings';
import { Alert, Box, Button, Drawer, IconButton, Snackbar, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { setAutosaveStatus, setProjectSettings } from '../../../redux/slice/projectSlice';
import { getProject } from '../../../services/projectService';
import NavigationBar from './NavigationBar';
import ProjectForm from './info/ProjectForm';
import MethodForm from './methods/MethodForm';
import SystemForm from './systems/SystemForm';
import UnitsForm from './units/UnitsForm';
import WorkflowForm from './workflows/WorkflowForm';

const Settings = () => {
  const projectSettings = useSelector(state => state.project.projectSettings);
  const autosaveStatus = useSelector(state => state.project.autosaveStatus);
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState('workflows');
  const [formState, setFormState] = useState(projectSettings);
  const [autosaveTimer, setAutosaveTimer] = useState(null);
  const [unitSystem, setUnitSystem] = useState('Imperial');

  const triggerAutosave = useCallback(() => {
    if (autosaveTimer) {
      clearTimeout(autosaveTimer);
    }
    dispatch(setAutosaveStatus('saving'));
    const newTimer = setTimeout(() => {
      dispatch(setProjectSettings(formState));
      dispatch(setAutosaveStatus('saved'));
    }, 2000);
    setAutosaveTimer(newTimer);
  }, [dispatch, formState]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const response = await getProject(token, projectId);
      setUnitSystem(response.projectData?.projectCreation?.[0]?.projectInformation?.unitSystem);
      setFormState(prevState => ({
        ...prevState,
        projectTitle: response.projectData.projectCreation[0].projectInformation.projectName,
        projectInformation: {
          ...prevState.projectInformation,
          country: response.projectData.projectCreation[0].projectInformation.country,
          projectDetails: {
            number: '',
            client: '',
            description: response.projectData.projectCreation[0].projectInformation.projectDescription,
          },
        },
        calculations: {
          methods: {
            ventAirStandard: response.projectData.projectCreation[0].hvacDetails.ventilationStandard,
            ductSizingMethod: response.projectData.projectCreation[0].hvacDetails.ductSizingMethod,
          },
          systems: {
            general: {
              systemName: response.projectData.projectCreation[0].systemConfiguration.settingsFor,
            },
            ductSizing: {
              mains: {
                flowRate:
                  response.projectData.projectCreation[0].projectInformation.flowRate,
                materials:
                  response.projectData.projectCreation[0].systemConfiguration.components.mains[0].material,
                ductShape: 'Rectangular',
                ductWidth: 100,
                ductHeight: 100,
                // ductDiameter: 10,
              },
              branches: {
                flowRate:
                  response.projectData.projectCreation[0].projectInformation.flowRate,
                materials:
                  response.projectData.projectCreation[0].systemConfiguration.components.branches[0].material,
                ductShape: 'Rectangular',
                ductWidth: 100,
                ductHeight: 100,
                // ductDiameter: 10,
              },
            },
          },
        },
        units: {
          length: {
            metric: 'mm',
            imperial: 'ft',
          },
          ventilationFlowRate: {
            metric: <span>m<sup>3</sup>/s</span>,
          },
          pressureDrop: {
            metric: 'Pa',
            imperial: 'iwg',
          },
        },
        workflows: [],
      }));
    };
    fetchData();
  }, [projectId]);

  const handleProjectTitle = e => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      projectTitle: value,
    }));
    triggerAutosave();
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    const nameParts = name.split('.');

    setFormState(prevState => {
      // Create a deep copy of the previous state
      const newState = JSON.parse(JSON.stringify(prevState));

      // Navigate to the correct nested object
      let nestedState = newState;
      for (let i = 0; i < nameParts.length - 1; i++) {
        if (!nestedState[nameParts[i]]) {
          nestedState[nameParts[i]] = {};
        }
        nestedState = nestedState[nameParts[i]];
      }

      // Set the new value
      nestedState[nameParts[nameParts.length - 1]] = value;

      return newState;
    });

    triggerAutosave();
  };


  const handleCountryChange = value => {
    setFormState(prevState => ({
      ...prevState,
      projectInformation: { ...prevState.projectInformation, country: value },
    }));
    triggerAutosave();
  };

  const handleSubmit = e => {
    e.preventDefault();
    dispatch(setProjectSettings(formState));
    history.push(`/project/${projectId}`);
  };

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleSectionChange = section => {
    setSelectedSection(section);
  };

  const handleWorkflowChange = workflow => {
    setFormState(prevState => ({
      ...prevState,
      workflows: prevState.workflows.includes(workflow)
        ? prevState.workflows.filter(w => w !== workflow)
        : [...prevState.workflows, workflow],
    }));
    triggerAutosave();
  };

  const renderSectionContent = () => {
    switch (selectedSection) {
      case 'workflows':
        return <WorkflowForm formState={formState} handleWorkflowChange={handleWorkflowChange} />;
      case 'info':
        return (
          <ProjectForm
            formState={formState}
            handleProjectTitle={handleProjectTitle}
            handleCountryChange={handleCountryChange}
            handleInputChange={handleInputChange}
          />
        );
      case 'methods':
        return (
          <MethodForm formState={formState.calculations.methods} handleInputChange={handleInputChange} />
        );
      case 'systems':
        return (
          <SystemForm formState={formState.calculations.systems} unitSystem={unitSystem} handleInputChange={handleInputChange} />
        );
      case 'units':
        return <UnitsForm formState={formState} unitSystem={unitSystem} handleInputChange={handleInputChange} />;
      default:
        return null;
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100vh',
        bgcolor: '#f7f7f7',
      }}
    >
      {isMobile ? (
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          <NavigationBar handleSectionChange={handleSectionChange} selectedSection={selectedSection} />
        </Drawer>
      ) : (
        <NavigationBar handleSectionChange={handleSectionChange} selectedSection={selectedSection} />
      )}
      <Box
        sx={{
          flexGrow: 1,
          p: 2,
          overflowY: 'auto',
          height: '100%',
        }}
      >
        {isMobile && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            color: '#4E5FBF',
            fontWeight: 'bolder',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <SettingsIcon sx={{ mr: 1 }} />
          Project Settings
        </Typography>
        <Box
          sx={{
            bgcolor: 'white',
            p: 3,
            borderRadius: 2,
            boxShadow: 3,
          }}
        >
          {renderSectionContent()}
          {selectedSection === 'units' && (
            <Button
              type="submit"
              variant="outlined"
              onClick={handleSubmit}
              sx={{
                mt: 3,
                display: 'flex',
                alignItems: 'flex-end',
              }}
            >
              <SaveIcon sx={{ mr: 1 }} />
              Save
            </Button>
          )}
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={autosaveStatus !== 'idle'}
        autoHideDuration={6000}
        onClose={() => dispatch(setAutosaveStatus('idle'))}
        message={autosaveStatus === 'saving' ? 'Autosaving...' : 'Autosave complete'}
      >
        <Alert variant="filled" sx={{ width: '100%', bgcolor: '#4E5FBF' }}>
          {autosaveStatus === 'saving' ? 'Autosaving...' : 'Autosave complete'}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Settings;
