import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { stopLineDraw } from '../../functions/utilFunctions';
import { setLevels, setSelectedLevel } from '../../redux/slice/drawingSlice';
import styles from './index.module.scss';
import { Fade, Tooltip } from '@mui/material';

const PdfList = ({ canvas }) => {
  const [expanded, setExpanded] = useState(false);
  const { levels } = useSelector(state => state.drawing);
  const [hideLevels, setHideLevels] = useState(false);
  let indexL1 = levels.findIndex(obj => obj.name === 'L1');
  const [levelIndex, setLevelIndex] = useState(indexL1 ? indexL1 : 0);

  const dispatch = useDispatch();

  useEffect(() => {
    setLevelIndex(indexL1);
  }, [indexL1]);

  const toggleExpand = () => {
    setExpanded(!expanded);
    setHideLevels(!hideLevels);
  };

  const updateDescription = (e, index) => {
    const updatedItems = [...levels];
    updatedItems[index] = {
      ...updatedItems[index],
      description: e.target.value,
    };
    dispatch(
      setLevels({
        type: 'clear',
        newItem: updatedItems,
      }),
    );
    // setItems(updatedItems);
    dispatch(setSelectedLevel(updatedItems[index]));
  };

  const updateFloorValue = (e, index) => {
    const updatedItems = [...levels];
    updatedItems[index] = {
      ...updatedItems[index],
      floorHeights: e.target.value,
    };
    dispatch(
      setLevels({
        type: 'clear',
        newItem: updatedItems,
      }),
    );
    dispatch(setSelectedLevel(updatedItems[index]));
  };

  const addItem = type => {
    const newItem = {
      type,
      name: `${type === 'level' ? 'L' : 'B'}${getNextAvailableNumber(type)}`,
      description: '',
      floorHeights: 0,
    };
    dispatch(
      setLevels({
        type: type,
        newItem: newItem,
      }),
    );
    setLevelIndex(0);
  };

  const getNextAvailableNumber = type => {
    const existingNumbers = levels
      .filter(item => item.type === type)
      .map(item => parseInt(item.name.replace(type === 'level' ? 'L' : 'B', ''), 10))
      .sort((a, b) => a - b);

    let nextAvailableNumber = 1;

    while (existingNumbers.includes(nextAvailableNumber)) {
      nextAvailableNumber++;
    }

    return nextAvailableNumber;
  };

  const deleteItem = index => {
    const itemToDelete = levels[index];
    if (itemToDelete.name === 'L1') {
      return;
    }

    const shouldDelete = window.confirm(`Are you sure you want to delete level ${itemToDelete.name}?`);

    if (shouldDelete) {
      const updatedItems = [...levels];
      updatedItems.splice(index, 1);
      dispatch(
        setLevels({
          type: 'clear',
          newItem: updatedItems,
        }),
      );
      // dispatch(setLevels(updatedItems));
    }
  };

  const renderExpandContent = () => {
    if (expanded) {
      return (
        <div className={`${styles.expandedContent} ${styles.fadeIn}`}>
          <table className={styles.tables}>
            <thead>
              <tr>
                <th>Abbrev</th>
                <th>Description</th>
                {/* <th>Reduced Floor Level</th> */}
                <th>Floor Height</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {levels.map((item, index) => (
                <>
                  {/* {hideLevels && ( */}
                  <tr
                    className={`${styles.buttonStyle} ${levelIndex === index ? styles.selectedButton : ''}`}
                    key={index}
                    onClick={() => {
                      stopLineDraw(canvas);
                      setLevelIndex(index);
                      dispatch(setSelectedLevel(item));
                    }}
                    style={{
                      backgroundColor: levelIndex === index ? '#4E5FBF' : 'whitesmoke',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  >
                    <td>{item.name}</td>
                    <td>
                      <input
                        type="text"
                        placeholder="Description"
                        value={item.description}
                        onChange={e => updateDescription(e, index)}
                      />
                    </td>
                    <td>
                      <input
                        type="number"
                        placeholder="Floor Height"
                        value={item.floorHeights}
                        onChange={e => updateFloorValue(e, index)}
                      />
                    </td>
                    <td className={styles.actionColumn}>
                      <button
                        className={`${styles.deleteButton} ${styles.iconButton}`}
                        onClick={() => deleteItem(index)}
                        disabled={item.name === 'L1'}
                      >
                        <MdDelete style={{ visibility: item.name === 'L1' ? 'hidden' : true }} />
                      </button>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.leftContent}>
        <div>
          <Tooltip
            title="Expand Levels"
            TransitionComponent={Fade}
            TransitionProps={600}
            placement="right-start"
          >
            <button onClick={toggleExpand} className={styles.buttonStyle}>
              {expanded ? '<<' : '>>'}
            </button>
          </Tooltip>
        </div>
        <hr />
        <div>
          <Tooltip
            title="Add Levels"
            TransitionComponent={Fade}
            TransitionProps={600}
            placement="right-start"
          >
            <button onClick={() => addItem('level')} className={styles.buttonStyle}>
              Add level
            </button>
          </Tooltip>
        </div>
        <hr />
        {levels.map((item, index) => (
          <>
            {!hideLevels && (
              <div key={index}>
                <button
                  className={`${styles.buttonStyle} ${levelIndex === index ? styles.selectedButton : ''}`}
                  onClick={() => {
                    stopLineDraw(canvas);
                    setLevelIndex(index);
                    dispatch(setSelectedLevel(item));
                  }}
                >
                  {item.name}
                </button>
                <hr />
              </div>
            )}
          </>
        ))}
      </div>
      <div className={styles.rightContent}>{renderExpandContent()}</div>
      <div>
        <Tooltip
          title="Add Bottom Levels"
          TransitionComponent={Fade}
          TransitionProps={600}
          placement="right-start"
        >
          <button onClick={() => addItem('below')} className={styles.buttonStyle}>
            +B
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

export default PdfList;
