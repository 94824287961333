import { useSelector } from 'react-redux';
import AhuPanel from './AhuPanel';
import DiffuserPanel from './DiffuserPanel';
import EqElbowPanel from './EqElbowPanel';
import LinePanel from './LinePanel';
import TeePanel from './TeePanel';
import ScalePdf from '../ScalePdf';
import TransitionPanel from './TransitionPanel';
import ElbowPanel from './ElbowPanel';

const PanelSwitcher = ({
  selectedLine,
  selectedTee,
  selectedEquipment,
  selectedElbow,
  selectedTransition,
  closeLinePanel,
  closeTeePanel,
  closeElbowPanel,
  closeTransitionPanel,
  elbows,
  setElbows,
  transitions,
  setTransitions,
  setDrawnLines,
  drawnLines,
  setTees,
  tees,
  setSelectedEquipment,
  setEquipments,
  equipments,
  rotateLeft,
  rotateRight,
  onPaperRatioChange,
  onPaperSizeChange,
  selectedDistanceRatio,
  selectedPaperSize,
  calculateScale,
  pdfFiles,
  setPdfFiles,
  loadedPDFs,
  setLoadedPDFs,
  selectedPdf,
  setSelectedPdf,
  projectData,
}) => {
  const { drawingMode } = useSelector(state => state.drawing);
  const unitSystem = projectData?.projectCreation?.[0]?.projectInformation?.unitSystem ?? 'Imperial';

  if (drawingMode) {
    return null;
  }

  if (selectedPdf) {
    return (
      <ScalePdf
        rotateLeft={rotateLeft}
        rotateRight={rotateRight}
        pdfName={pdfFiles[0]?.name}
        onPaperSizeChange={onPaperSizeChange}
        onPaperRatioChange={onPaperRatioChange}
        selectedDistanceRatio={selectedDistanceRatio}
        selectedPaperSize={selectedPaperSize}
        calculateScale={calculateScale}
        pdfFiles={pdfFiles}
        setPdfFiles={setPdfFiles}
        loadedPDFs={loadedPDFs}
        setLoadedPDFs={setLoadedPDFs}
        setSelectedPdf={setSelectedPdf}
        projectData={projectData}
      />
    );
  }

  if (selectedLine) {
    return (
      <LinePanel
        closeLinePanel={closeLinePanel}
        selectedLine={selectedLine}
        setDrawnLines={setDrawnLines}
        drawnLines={drawnLines}
        unitSystem={unitSystem}
      />
    );
  }

  if (selectedTee) {
    return <TeePanel closeTeePanel={closeTeePanel} selectedTee={selectedTee} setTees={setTees} tees={tees} />;
  }

  if (selectedElbow) {
    return (
      <ElbowPanel
        closeElbowPanel={closeElbowPanel}
        selectedElbow={selectedElbow}
        setElbows={setElbows}
        elbows={elbows}
      />
    );
  }

  if (selectedTransition) {
    return (
      <TransitionPanel
        closeTransitionPanel={closeTransitionPanel}
        selectedTransition={selectedTransition}
        transitions={transitions}
        setTransitions={setTransitions}
      />
    );
  }

  if (selectedEquipment) {
    switch (selectedEquipment.value) {
      case 'AHU':
        return (
          <AhuPanel
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipments={setEquipments}
            equipments={equipments}
          />
        );
      case 'Diffuser':
        return (
          <DiffuserPanel
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipments={setEquipments}
            equipments={equipments}
            unitSystem={unitSystem}
          />
        );
      case 'Elbow':
        return (
          <EqElbowPanel
            selectedEquipment={selectedEquipment}
            setSelectedEquipment={setSelectedEquipment}
            setEquipments={setEquipments}
            equipments={equipments}
          />
        );
      default:
        return null;
    }
  }

  return null;
};

export default PanelSwitcher;
