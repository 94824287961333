import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  projects: [],
  branchDrop: {},
  projectSettings: {
    projectInformation: {
      country: 'IN',
      "projectDetails": {
        "number": "",
        "client": "",
        "description": ""
      },
    },
    calculations: {
      "methods": {
        "ventAirStandard": "ASHRAE 62.1 2022",
        "ductSizingMethod": "Pressure drop"
      },
      "systems": {
        "general": {
          "systemName": ""
        },
        "ductSizing": {
          "mains": {
            "flowRate": "",
            "materials": "Galvanised Steel",
            "ductShape": "Rectangular",
            "ductWidth": "",
            "ductHeight": ""
          },
          "branches": {
            "flowRate": "",
            "materials": "Galvanised Steel",
            "ductShape": "Rectangular",
            "ductWidth": "",
            "ductHeight": ""
          },
          // "connectors": {
          //   "pressureDrop": 50,
          //   "materials": "Galvanised Steel",
          //   "ductShape": "Rectangular",
          //   "ductWidth": 0,
          //   "ductDiameter": 0
          // }
        }
      }
    },
    units: {
      "length": {
        "metric": "mm",
        "imperial": "ft"
      },
      "ventilationFlowRate": {
        "metric": "L/s"
      },
      "pressureDrop": {
        metric: 'Pa',
        imperial: 'iwg',
      }
    },
    workflows: []

  },
  autosaveStatus: 'idle'
};

export const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    setProjects: (state, action) => {
      state.projects = action.payload;
    },
    setBranchDrop: (state, action) => {
      state.branchDrop = action.payload;
    },
    setProjectSettings: (state, action) => {
      state.projectSettings = action.payload;
    },
    setAutosaveStatus: (state, action) => {
      state.autosaveStatus = action.payload;
    },
  },
});

export const { setProjects, setBranchDrop, setProjectSettings, setAutosaveStatus } = projectSlice.actions;

export default projectSlice.reducer;
