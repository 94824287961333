// styles.js

const teePanelstyles = {
  container: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 300,
    maxHeight: '50vh',
    bgcolor: 'background.paper',
    boxShadow: 1,
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: 1,
    zIndex: 1000,
    overflowY: 'auto',
  },

  controlGroup: {
    width: '100%',
    mb: 2,
  },
  inputGroup: {
    display: 'flex',
    columnGap: 1,
  },
  buttonGroup: {
    display: 'flex',
    flexDirection: 'row',
    gap: 1,
  },
};

export default teePanelstyles;
