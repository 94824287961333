import { configureStore } from '@reduxjs/toolkit';
import drawingReducer from '../redux/slice/drawingSlice.js';
import projectReducer from '../redux/slice/projectSlice.js';

export const store = configureStore({
  reducer: {
    drawing: drawingReducer,
    project: projectReducer,
  },
});
