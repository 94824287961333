import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import appTheme from './appTheme';
import "./index.scss";
import AppRoutes from './pages/AppRoute';


const App = () => {

  return (
    <>
      <ThemeProvider theme={appTheme}>
        <CssBaseline />
        <AppRoutes />
      </ThemeProvider>
    </>
  );
};

export default App;
