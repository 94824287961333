import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import Contact from "../../pages/Contact";
import aboutUs2 from "./Images/aboutUs2.jpg";
import aboutUs from "./Images/aboutus.jpg";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2),
  boxShadow: "0 8px 32px rgba(0,0,0,0.1)",
  transition: "all 0.3s ease-in-out",
  "&:hover": {
    transform: "translateY(-5px)",
    boxShadow: "0 12px 40px rgba(0,0,0,0.15)",
  },
}));

const StyledImage = styled("img")({
  width: "100%",
  height: "auto",
  borderRadius: "15px",
  boxShadow: "0 8px 32px rgba(0,0,0,0.15)",
  transition: "transform 0.3s ease-in-out",
  "&:hover": {
    transform: "scale(1.02)",
  },
});

const StyledTypography = styled(Typography)(({ theme }) => ({
  fontSize: "1.1rem",
  lineHeight: 1.8,
  color: theme.palette.text.secondary,
}));


const LandingPage = () => {
  return (
    <Box
      sx={{
        pt: { xs: 12, md: 3 },
        overflowY: "auto",
        height: "100vh",
        backgroundColor: "#f8f9fa",
      }}
      id="about-us"
    >
      <Container maxWidth="lg">
        <Box sx={{ textAlign: "center", mt: 7 }}>
          <Typography variant="h2" gutterBottom color="primary" fontWeight="bold">
            Welcome to calcme.ai
          </Typography>
          <Typography variant="h5" color="text.secondary" sx={{ mb: 4 }}>
            Revolutionizing HVAC Duct Sizing
          </Typography>
        </Box>

        <Grid container spacing={6} sx={{ my: 4 }}>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3}>
              <Typography variant="h3" gutterBottom color="primary" fontWeight="bold">
                About Us
              </Typography>
              <StyledTypography paragraph>
                <strong>calcme</strong> HVAC Duct Sizing Application is designed to
                make your ductwork planning and design as efficient and accurate as
                possible. Whether you're an engineer, architect, or HVAC contractor,
                our tool simplifies the process, saving you time and ensuring precision.
              </StyledTypography>
            </StyledPaper>
          </Grid>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <StyledImage src={aboutUs} alt="About Us" />
          </Grid>
        </Grid>

        <Grid container spacing={6} sx={{ my: 4 }}>
          <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <StyledImage src={aboutUs2} alt="About Us" />
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledPaper elevation={3}>
              <Typography variant="h4" gutterBottom color="primary" fontWeight="bold">
                Integration with Revit
              </Typography>
              <StyledTypography paragraph>
                <strong>calcme</strong> HVAC Duct Sizing Application can directly
                transfer data to Autodesk Revit, allowing you to:
              </StyledTypography>
              <ul>
                <StyledTypography component="li">
                  Export processed data directly into Autodesk Revit
                </StyledTypography>
                <StyledTypography component="li">
                  Incorporate ductwork designs seamlessly into your building plans
                </StyledTypography>
                <StyledTypography component="li">
                  Improve workflow by integrating with existing Revit projects
                </StyledTypography>
                <StyledTypography component="li">
                  Ensure all your design data is consistent and up-to-date
                </StyledTypography>
              </ul>
            </StyledPaper>
          </Grid>
        </Grid>
      </Container>
      <Contact />
    </Box>
  );
};

export default LandingPage;