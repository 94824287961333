import CloseIcon from '@mui/icons-material/Close';
import {
  AppBar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Step,
  StepLabel,
  Stepper,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
  useTheme,
} from '@mui/material';
import { motion } from 'framer-motion';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { setProjects } from '../../redux/slice/projectSlice';
import { createProject } from '../../services/projectService';

const steps = ['General', 'Calculations', 'Systems'];

const CreateProject = ({ createDialog, onClose }) => {
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { projects } = useSelector(state => state.project);

  const [activeStep, setActiveStep] = useState(0);
  const [projectName, setProjectName] = useState('');
  const [unitSystem, setUnitSystem] = useState('Imperial');
  const [lengthUnit, setLengthUnit] = useState('ft');
  const [flowRate, setflowRate] = useState(2118.87997);
  const [flowrateUnit, setFlowrateUnit] = useState('m3/s');
  const [pressureDrop, setPressureDrop] = useState(0.200932);
  const [pressureDropUnit, setPressureDropUnit] = useState('iwg');
  const [pressureUnit, setPressureUnit] = useState('psi');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectNameError, setProjectNameError] = useState(false);
  const [ventilationStandard, setVentilationStandard] = useState('ASHRAE 62.1 2022');
  const [heatingStandard, setHeatingStandard] = useState('ASHRAE 62.1 2022');
  const [ductSizingMethod, setDuctSizingMethod] = useState('MAX Pressure Drop');
  const [settingsFor, setSettingsFor] = useState('Supply');
  const [material, setMaterial] = useState('Galvanised steel');
  const [tabValue, setTabValue] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const [errorMsg, setErrorMsg] = useState("")
  const projectNameRegex = /^[a-zA-Z0-9\s\-]+$/;

  const handleUnitSyatem = e => {
    const system = e.target.value;
    setUnitSystem(system);
    if (system === 'Imperial') {
      setLengthUnit('ft');
      setFlowrateUnit('cfm');
      setPressureDropUnit('iwg');
      // setPressureUnit('psi');
      setPressureDrop(0.200932);
      setflowRate(2118.87997);
    } else {
      setLengthUnit('m');
      setFlowrateUnit('m3/s');
      setPressureDropUnit('pa');
      // setPressureUnit('Pa');
      setPressureDrop(50);
      setflowRate(1);
    }
  };

  const handleCloseWithAnimation = () => {
    setIsClosing(true);
    setTimeout(() => {
      setIsClosing(false);
      onClose();
    }, 300); // Duration of the animation
  };

  const handleNext = () => {
    if (activeStep === 0 && !validateProjectName()) {
      return;
    }

    if (activeStep === 0) {
      const projectExists = projects.some(project =>
        project.projectData.projectCreation[0].projectInformation.projectName === projectName
      );

      if (projectExists) {
        setErrorMsg('A project with this name already exists. Please choose a different name.');
        return;
      }
    }

    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const validateProjectName = () => {
    if (
      !projectName.trim() ||
      projectName.length < 4 ||
      projectName.length > 50 ||
      !projectNameRegex.test(projectName)
    ) {
      setProjectNameError(true);
      return false;
    }
    setProjectNameError(false);
    setErrorMsg('');
    return true;
  };

  const handleCreate = async () => {
    if (!validateProjectName()) {
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const projectData = {
        projectCreation: [
          {
            projectInformation: {
              projectName: projectName,
              projectDescription: projectDescription,
              unitSystem: unitSystem,
              lengthUnit: lengthUnit,
              flowrateUnit: flowrateUnit,
              pressureDropUnit: pressureDropUnit,
              pressureUnit: pressureUnit,
              flowRate: flowRate,
              pressureDrop: pressureDrop,
            },
            hvacDetails: {
              ventilationStandard: ventilationStandard,
              heatingStandard: heatingStandard,
              ductSizingMethod: ductSizingMethod,
            },
            systemConfiguration: {
              settingsFor: settingsFor,
              maxPressureDropPsiPer100Ft: 0.0000725,
              components: {
                mains: [
                  {
                    flowRate: flowRate,
                    pressureDropPa: pressureDrop,
                    material: material,
                    ductShape: 'Rectangular',
                    ductWidth: unitSystem === 'Imperial' ? 3.94 : 100,
                    ductHeight: unitSystem === 'Imperial' ? 3.94 : 100,
                  },
                ],
                branches: [
                  {
                    flowRate: flowRate,
                    pressureDropPa: pressureDrop,
                    material: material,
                    ductShape: 'Rectangular',
                    ductWidth: unitSystem === 'Imperial' ? 3.94 : 100,
                    ductHeight: unitSystem === 'Imperial' ? 3.94 : 100,
                  },
                ],
              },
            },
          },
        ],
      };


      const response = await createProject(token, projectData);
      if (response._id) {
        const newProjects = [...projects, response];
        dispatch(setProjects(newProjects));
        onClose();
        history.push(`/project/${response._id}`);
      }
    } catch (error) {
      console.error('Error creating project:', error);
      let errorMessage = 'An unexpected error occurred. Please try again later.';
      if (error.response) {
        if (error.response.status === 401) {
          errorMessage = 'You are not authorized. Please log in and try again.';
        } else if (error.response.status === 400) {
          errorMessage = 'Bad request. Please check the project data and try again.';
        } else if (error.response.status === 500) {
          errorMessage = 'Server error. Please try again later.';
        }
      } else if (error.request) {
        errorMessage = 'No response from the server. Please check your network connection and try again.';
      } else {
        errorMessage = 'Error creating project. Please try again.';
      }

      alert(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleProjectName = e => {
    setProjectName(e.target.value);
    if (projectNameError && e.target.value.trim()) {
      setProjectNameError(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const renderField = (label, field) => (
    <Grid container spacing={2} alignItems="center" sx={{ mb: 3 }}>
      <Grid item xs={12} sm={4}>
        <Typography variant="subtitle1" sx={{ fontWeight: 600, color: theme.palette.text.secondary }}>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={8}>
        {field}
        {label === "Project Name" && errorMsg && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {errorMsg
              ? 'This project name already exist, please use another one.'
              : ''}
          </Typography>
        )}
        {label === "Project Name" && projectNameError && (
          <Typography color="error" variant="body2" sx={{ mt: 1 }}>
            {projectNameError
              ? 'Please enter a valid project name (4-50 characters, alphanumeric, spaces, dashes)'
              : ''}
          </Typography>
        )}
      </Grid>
    </Grid>
  );

  const renderStepContent = step => {
    switch (step) {
      case 0:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper
              elevation={3}
              sx={{ p: 4, borderRadius: '20px', background: 'linear-gradient(145deg, #ffffff, #f0f0f0)' }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: theme.palette.primary.main, mb: 4, fontWeight: 700 }}
              >
                Project Information
              </Typography>
              {renderField(
                'Project Name',
                <TextField
                  fullWidth
                  required
                  size="small"
                  value={projectName}
                  onChange={handleProjectName}
                  variant="outlined"
                  error={projectNameError}
                  InputProps={{
                    'data-testid': 'project-name',
                    sx: { borderRadius: '10px' },
                  }}
                />,
                projectNameError
                  ? 'Please enter a valid project name (4-50 characters, alphanumeric, spaces, dashes)'
                  : ''
              )}
              {renderField(
                'Unit system',
                <FormControl fullWidth size="small">
                  <Select value={unitSystem} onChange={handleUnitSyatem} sx={{ borderRadius: '10px' }}>
                    <MenuItem value={'Imperial'}>Imperial</MenuItem>
                    <MenuItem value={'Metric'}>Metric</MenuItem>
                  </Select>
                </FormControl>,
              )}
              {renderField(
                'Project Description',
                <TextField
                  fullWidth
                  multiline
                  rows={4}
                  size="small"
                  value={projectDescription}
                  onChange={e => setProjectDescription(e.target.value)}
                  variant="outlined"
                  InputProps={{
                    'data-testid': 'project-description',
                    sx: { borderRadius: '10px' },
                  }}
                />,
              )}
            </Paper>
          </motion.div>
        );
      case 1:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper
              elevation={3}
              sx={{ p: 4, borderRadius: '20px', background: 'linear-gradient(145deg, #ffffff, #f0f0f0)' }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: theme.palette.primary.main, mb: 4, fontWeight: 700 }}
              >
                HVAC Details
              </Typography>
              {renderField(
                'Ventilation Standard',
                <FormControl fullWidth size="small">
                  <Select
                    value={ventilationStandard}
                    onChange={e => setVentilationStandard(e.target.value)}
                    sx={{ borderRadius: '10px' }}
                  >
                    <MenuItem value="ASHRAE 62.1 2022">ASHRAE 62.1 2022</MenuItem>
                  </Select>
                </FormControl>,
              )}
              {renderField(
                'Heating Standard',
                <FormControl fullWidth size="small">
                  <Select
                    value={heatingStandard}
                    onChange={e => setHeatingStandard(e.target.value)}
                    sx={{ borderRadius: '10px' }}
                  >
                    <MenuItem value="ASHRAE 62.1 2022">ASHRAE 62.1 2022</MenuItem>
                  </Select>
                </FormControl>,
              )}
              {renderField(
                'Duct Sizing Method',
                <FormControl fullWidth size="small">
                  <Select
                    value={ductSizingMethod}
                    onChange={e => setDuctSizingMethod(e.target.value)}
                    sx={{ borderRadius: '10px' }}
                  >
                    <MenuItem value="MAX Pressure Drop">MAX Pressure Drop</MenuItem>
                  </Select>
                </FormControl>,
              )}
            </Paper>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Paper
              elevation={3}
              sx={{ p: 4, borderRadius: '20px', background: 'linear-gradient(145deg, #ffffff, #f0f0f0)' }}
            >
              <Typography
                variant="h5"
                gutterBottom
                sx={{ color: theme.palette.primary.main, mb: 4, fontWeight: 700 }}
              >
                System Configuration
              </Typography>
              {renderField(
                'Settings for',
                <FormControl fullWidth size="small">
                  <Select
                    value={settingsFor}
                    onChange={e => setSettingsFor(e.target.value)}
                    sx={{ borderRadius: '10px' }}
                  >
                    <MenuItem value="Supply">Supply</MenuItem>
                  </Select>
                </FormControl>,
              )}
              {renderField(
                'Pressure Drop',
                <TextField
                  fullWidth
                  size="small"
                  type="number"
                  value={pressureDrop}
                  onChange={e => setPressureDrop(e.target.value)}
                  InputProps={{
                    endAdornment: <Typography variant="caption">{pressureDropUnit}</Typography>,
                    sx: { borderRadius: '10px' },
                  }}
                />,
              )}
              <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3, mt: 4 }}>
                <Tabs
                  value={tabValue}
                  onChange={handleTabChange}
                  aria-label="system configuration tabs"
                  sx={{ '& .MuiTabs-indicator': { height: 3, borderRadius: '3px 3px 0 0' } }}
                >
                  <Tab label="Mains" sx={{ fontWeight: 600 }} />
                  <Tab label="Branches" sx={{ fontWeight: 600 }} />
                  {/* <Tab label="Connections" sx={{ fontWeight: 600 }} /> */}
                </Tabs>
              </Box>
              {[0, 1, 2].map(
                index =>
                  tabValue === index && (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Grid container>
                        {renderField(
                          'Flow rate',
                          <TextField
                            fullWidth
                            size="small"
                            type="number"
                            value={flowRate}
                            onChange={e => setflowRate(e.target.value)}
                            InputProps={{
                              endAdornment: <Typography variant="caption">{flowrateUnit}</Typography>,
                              sx: { borderRadius: '10px' },
                            }}
                          />,
                        )}
                        {renderField(
                          'Material',
                          <FormControl fullWidth size="small">
                            <Select
                              value={material}
                              onChange={e => setMaterial(e.target.value)}
                              sx={{ borderRadius: '10px' }}
                            >
                              <MenuItem value="Galvanised steel">Galvanised steel</MenuItem>
                            </Select>
                          </FormControl>,
                        )}
                      </Grid>
                    </motion.div>
                  ),
              )}
            </Paper>
          </motion.div>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <Dialog fullScreen open={createDialog} onClose={onClose} aria-labelledby="form-dialog-title">
      <motion.div
        initial={{ opacity: 1, scale: 1 }}
        animate={isClosing ? { opacity: 0, scale: 0.98 } : { opacity: 1, scale: 1 }}
        transition={{ duration: 0.2, ease: 'easeInOut' }}
        style={{ height: '100%' }}
      >
        <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.primary.main, boxShadow: 'none' }}>
          <Toolbar>
            <Typography sx={{ flex: 1, fontWeight: 700 }} variant="h6" component="div">
              Create New Project
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton edge="end" color="inherit" onClick={handleCloseWithAnimation} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>

        <DialogContent
          sx={{
            p: 0,
            height: 'calc(100vh - 64px)',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.palette.background.default,
            backgroundImage: 'linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%)',
          }}
        >
          <Box sx={{ width: '100%', p: 4 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ mb: 5 }}>
              {steps.map(label => (
                <Step key={label}>
                  <StepLabel
                    StepIconProps={{
                      sx: {
                        '&.Mui-active': { color: theme.palette.primary.main },
                        '&.Mui-completed': { color: theme.palette.success.main },
                      },
                    }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto', p: 4 }}>
            {activeStep === steps.length ? (
              <Box sx={{ textAlign: 'center', mt: 4 }}>
                <Typography
                  variant="h5"
                  gutterBottom
                  sx={{ fontWeight: 700, color: theme.palette.primary.main }}
                >
                  All steps completed
                </Typography>
                <Button
                  variant="outlined"
                  onClick={handleCreate}
                  sx={{
                    mt: 3,
                    borderRadius: '20px',
                    textTransform: 'none',
                    fontSize: '1rem',
                    py: 1,
                    px: 4,
                    minWidth: '200px',
                  }}
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Project'}
                </Button>
              </Box>
            ) : (
              <Box>{renderStepContent(activeStep)}</Box>
            )}
          </Box>
          <Box sx={{ p: 4, borderTop: `1px solid ${theme.palette.divider}` }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Button
                disabled={activeStep === 0 || loading}
                onClick={handleBack}
                variant="outlined"
                sx={{ borderRadius: '20px', textTransform: 'none', fontSize: '1rem', py: 1, px: 4 }}
              >
                Back
              </Button>
              <Button
                variant="outlined"
                onClick={activeStep === steps.length - 1 ? handleCreate : handleNext}
                disabled={loading}
                sx={{
                  borderRadius: '20px',
                  textTransform: 'none',
                  fontSize: '1rem',
                  py: 1,
                  px: 4,
                  minWidth: '100px',
                }}
              >
                {activeStep === steps.length - 1 ? (
                  loading ? (
                    <CircularProgress size={24} color="inherit" />
                  ) : (
                    'Create Project'
                  )
                ) : (
                  'Next'
                )}
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </motion.div>
    </Dialog>
  );
};

export default CreateProject;
