import { Alert } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import { fabric } from 'fabric';
import jsPDF from 'jspdf';
import React, { useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { scalingRatio, standardPaperSizes } from '../../cordinates/distancePoints';
import {
  centerCanvasView,
  generateRandomId,
  getAdjustedStrokeWidth,
  getDistance,
  handleResize,
  initCanvas,
  resizeCanvas,
  updateCanvasZoom,
} from '../../functions/utilFunctions';
import {
  setDrawingMode,
  setIsPdfPresent,
  setLevels,
  setLineColor,
  setPdfIndex,
  setSelectedLevel,
  setSelectedPdfHeight,
  setSelectedPdfScale,
  setSelectedPdfWidth,
  setUploadedPdfIndex,
  showSelectedScale,
} from '../../redux/slice/drawingSlice';
import { setAutosaveStatus, setProjects } from '../../redux/slice/projectSlice';
import { getProject, updateProject } from '../../services/projectService';
import PdfReader from '../PdfReader/PDFReader';
import CanvasBar from './CanvasBar';
import Header from './Header';
import PdfList from './PdfList';
import PanelSwitcher from './SidePanels/PanelSwitcher';
import styles from './index.module.scss';

let mouseDown = false;
let points = [];
let line;
const MainBoard = ({ aspectRatio = 4 / 3 }) => {
  const history = useHistory();
  const autosaveStatus = useSelector(state => state.project.autosaveStatus);
  useEffect(() => {
    if (autosaveStatus === 'saved') {
      handleSave(true);
    }
  }, [autosaveStatus]);
  const [isPanning, setIsPanning] = useState(false);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [canvas, setCanvas] = useState(null);
  const [fileReaderInfoArray, setFileReaderInfoArray] = useState([]);
  const [fileReaderInfo, setFileReaderInfo] = useState({
    file: '',
    totalPages: null,
    currentPageNumber: 1,
    currentPage: '',
    name: '',
  });
  const [pdfFiles, setPdfFiles] = useState([]);
  const [projectData, setProjectData] = useState();
  const [drawnLines, setDrawnLines] = useState([]);
  const [tees, setTees] = useState([]);
  const [elbows, setElbows] = useState([]);
  const [transitions, setTransitions] = useState([]);
  const [equipments, setEquipments] = useState([]);
  const [selectedLine, setSelectedLine] = useState();
  const [selectedTee, setSelectedTee] = useState();
  const [selectedElbow, setSelectedElbow] = useState();
  const [selectedTransition, setSelectedTransition] = useState();
  const [selectedEquipment, setSelectedEquipment] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState();
  const [top, setPdfTop] = useState(400);
  const [left, setPdfLeft] = useState(600);
  const [showCoordinate, setShowCoordinate] = useState('blue');
  const [showLineButton, setShowLineButton] = useState(false);
  const [showUploadPdfButton, setShowUploadPdfButton] = useState(false);
  const [showScaleButton, setShowScaleButton] = useState(false);
  const [selectedPaperSize, setSelectedPaperSize] = useState(210); // A4
  const [lockedPdfIndex, setLockedPdfIndex] = useState(null);
  const [selectedDistanceRatio, setSelectedDistanceRatio] = useState(1);
  //Paper type
  const [paperType, setPaperType] = useState('A4');
  const [showScaleNextToCalculate, setShowScaleNextToCalculate] = useState(false);
  const [mode, setMode] = useState('draw');
  const [drawing, setDrawing] = useState(false);
  const {
    pdfindex,
    selectedlevel,
    toggleScale,
    pdfWidth,
    pdfHeight,
    pdfScale,
    dragid,
    lineColor,
    levels,
    drawingMode,
  } = useSelector(state => state.drawing);
  const { projects, projectSettings } = useSelector(state => state.project);
  const dispatch = useDispatch();
  const [loadedPDFs, setLoadedPDFs] = useState([
    {
      src: '',
      position: { selectable: false, selectedLevel: selectedlevel?.name },
      locked: true,
    },
  ]);

  const [open, setOpen] = useState(false);

  const handleDrawerToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const [isLoading, setIsLoading] = useState(false);
  let scaleX = (pdfScale * pdfWidth) / 1000;
  let scaleY = (pdfScale * pdfHeight) / 1000;
  let scale = Math.min(scaleX, scaleY);
  const canvasRef = useRef(null);
  const mainboardRef = useRef(null);
  const { projectId } = useParams();

  const handleSnackBar = flag => {
    setSnackBar(flag);
  };

  const navigateToDashboard = () => {
    handleCloseDrawer();
    history.push('/dashboard');
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  const closeLinePanel = () => {
    setSelectedLine();
  };

  const closeTeePanel = () => {
    setSelectedTee();
  };

  const closeElbowPanel = () => {
    setSelectedElbow();
  };

  const closeTransitionPanel = () => {
    setSelectedTransition();
  };

  const closeEquipmentPanel = () => {
    setSelectedEquipment();
  };

  const onPaperRatioChange = event => {
    setSelectedDistanceRatio(event.target.value);
    // const width = (event.target.value / 72) * 25.4;
    dispatch(setSelectedPdfScale(event.target.value));
    let zoom = updateCanvasZoom(pdfScale, paperType);
    // console.log('zoom:', zoom);
    canvas.setZoom(zoom);
  };

  const onPaperSizeChange = event => {
    setSelectedPaperSize(event.target.value);
    const paper = standardPaperSizes.find(size => size.width === event.target.value);
    setPaperType(paper.name);
    // const width = (event.target.value / 72) * 25.4;
    dispatch(setSelectedPdfWidth(event.target.value));
  };

  useEffect(() => {
    if (canvas) {
      let zoom = updateCanvasZoom(pdfScale, paperType);
      // console.log('zoom:', zoom);
      const canvasCenter = new fabric.Point(left, top);
      canvas.setZoom(1);
      const transform = [
        zoom,
        0,
        0,
        zoom,
        canvasCenter.x - zoom * canvasCenter.x,
        canvasCenter.y - zoom * canvasCenter.y - 300,
      ];
      canvas.setViewportTransform(transform);
    }
  }, [pdfScale]);

  const onDistanceRatioChange = event => {
    setSelectedDistanceRatio(event.target.value);
  };

  const rotateLeft = () => {
    rotatePdf(-45);
  };

  const rotateRight = () => {
    rotatePdf(45);
  };

  const rotatePdf = angle => {
    if (pdfindex !== null && canvas) {
      setLoadedPDFs(prevPDFs => {
        return prevPDFs.map((pdf, index) => {
          if (pdf?.position?.pdfId === pdfindex) {
            const rotatedPdf = {
              ...pdf,
              position: {
                ...pdf.position,
                angle: (pdf.position.angle || 0) + angle,
              },
            };
            return rotatedPdf;
          }
          return pdf;
        });
      });
    }
  };

  let updatedSelectedOptions;

  const equipmentDrop = () => {
    if (updatedSelectedOptions !== undefined) {
      setEquipments(updatedSelectedOptions);
    }
    canvas.getObjects('line').forEach(line => {
      if (line.id === 'snapIndicator') {
        canvas.remove(line);
      }
    });
  };

  const equipmentDrag = (event, option) => {
    const { target } = event;
    const { left, top } = target;
    const id = option.id;
    const optionIndex = equipments.findIndex(opt => opt.id === id);
    if (optionIndex !== -1) {
      updatedSelectedOptions = [...equipments];
      updatedSelectedOptions[optionIndex] = {
        ...updatedSelectedOptions[optionIndex],
        position: { left, top },
      };

      let snappedOption = snapToLine(updatedSelectedOptions[optionIndex]);
      updatedSelectedOptions[optionIndex] = snappedOption;
      // setEquipments(updatedSelectedOptions);
    }
  };

  const snapToLine = option => {
    // From center
    let center = {
      x: option.position.left,
      y: option.position.top,
    };
    let snapLine = null;
    let minDistance = 5;
    let snapPosition = { x: 0, y: 0 };

    canvas.forEachObject(function (target) {
      if (target.ductType) {
        let lineEndpoints = [
          { x: target.x1, y: target.y1 },
          { x: target.x2, y: target.y2 },
        ];

        lineEndpoints.forEach(function (endpoint) {
          let distance = getDistance(center.x, center.y, endpoint.x, endpoint.y);

          if (distance < minDistance) {
            minDistance = distance;
            snapLine = target;
            snapPosition = {
              x: endpoint.x,
              y: endpoint.y,
            };
          }
        });
      }
    });

    if (snapLine) {
      option.position.left = snapPosition.x;
      option.position.top = snapPosition.y;
      option.ductId = snapLine.id;
      // Draw a line on the canvas to indicate snapping
      let snapIndicator = new fabric.Line([snapLine.x1, snapLine.y1, snapLine.x2, snapLine.y2], {
        id: 'snapIndicator',
        selectable: false,
        evented: false,
        strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 1),
        stroke: 'blue',
        strokeDashArray: [5, 5],
        originX: 'center',
        originY: 'center',
      });

      // Remove existing snap indicators
      canvas.getObjects('line').forEach(line => {
        if (line.id === 'snapIndicator') {
          canvas.remove(line);
        }
      });

      // Add new snap indicator
      canvas.add(snapIndicator);
    } else {
      option.ductId = null;
      // Remove existing snap indicators
      canvas.getObjects('line').forEach(line => {
        if (line.id === 'snapIndicator') {
          canvas.remove(line);
        }
      });
    }

    return option;
  };

  // Get project details
  useEffect(() => {
    const fetchData = async () => {
      const project = projects.find(proj => proj._id === projectId);
      if (!project) {
        const token = localStorage.getItem('token');
        const response = await getProject(token, projectId);
        setProjectData(response.projectData);
      } else {
        setProjectData(project.projectData);
      }
    };
    fetchData();
  }, []);

  function renderPdfs(files) {
    if (files) {
      const pdfList = [];

      files.forEach(pdf => {
        const byteArray = Uint8Array.from(atob(pdf.buffer), c => c.charCodeAt(0));
        const blob = new Blob([byteArray], { type: pdf.mimetype });
        const file = new File([blob], pdf.originalname, { type: pdf.mimetype });
        pdfList.push({
          file: file,
          totalPages: null,
          currentPageNumber: 1,
          currentPage: '',
        });
        setPdfFiles(prevPDFs => [...prevPDFs, file]);
      });
      setFileReaderInfoArray(pdfList);
    }
  }

  function renderLines(lines) {
    if (lines) {
      let lineArray = [];
      lines.forEach(line => {
        fabric.util.enlivenObjects([line], function (enlivenedObjects) {
          lineArray.push(enlivenedObjects[0]);
        });
      });
      setDrawnLines(lineArray);
    }
  }

  function renderTees(tees) {
    if (tees) {
      let teesArray = [];
      tees.forEach(tee => {
        fabric.util.enlivenObjects([tee], function (enlivenedObjects) {
          teesArray.push(enlivenedObjects[0]);
        });
      });
      setTees(teesArray);
    }
  }

  function renderElbows(elbows) {
    if (elbows) {
      let elbowsArray = [];
      elbows.forEach(tee => {
        fabric.util.enlivenObjects([tee], function (enlivenedObjects) {
          elbowsArray.push(enlivenedObjects[0]);
        });
      });
      setElbows(elbowsArray);
    }
  }

  function renderTransitions(transitions) {
    if (transitions) {
      let transitionsArray = [];
      transitions.forEach(transition => {
        fabric.util.enlivenObjects([transition], function (enlivenedObjects) {
          transitionsArray.push(enlivenedObjects[0]);
        });
      });
      setTransitions(transitionsArray);
    }
  }

  function renderEquipments(equipments) {
    setEquipments(equipments);
  }

  function onDataLoad(projectData) {
    const { lines, files, equipments, levels, tees, elbows, transitions, pdfPosition } = projectData;
    dispatch(
      setLevels({
        type: 'clear',
        newItem: levels,
      }),
    );
    const indexL1 = levels.findIndex(obj => obj.name === 'L1');
    dispatch(setSelectedLevel(levels[indexL1]));
    setPdfLeft(pdfPosition?.left);
    setPdfTop(pdfPosition?.top);
    if (pdfPosition?.pdfScale && pdfPosition?.pdfWidth && pdfPosition?.pdfHeight) {
      setSelectedPaperSize(pdfPosition?.pdfWidth);
      setSelectedDistanceRatio(pdfPosition?.pdfScale);
      setPaperType(pdfPosition?.paperType || 'A4');
      dispatch(setSelectedPdfWidth(pdfPosition?.pdfWidth));
      dispatch(setSelectedPdfScale(pdfPosition?.pdfScale));
      dispatch(setSelectedPdfHeight(pdfPosition?.pdfHeight));
      scale = (pdfPosition?.pdfScale * pdfPosition?.pdfWidth) / 1000;
    }

    renderPdfs(files);
    renderLines(lines);
    renderTees(tees);
    renderElbows(elbows);
    renderTransitions(transitions);
    setTimeout(() => {
      renderEquipments(equipments);
    }, 1000);
  }

  useEffect(() => {
    if (projectData?.files) {
      onDataLoad(projectData);
    }
    if (canvas) {
      // Close panels if no element selected
      canvas.on('mouse:down', function (e) {
        if (e.target === null) {
          setSelectedLine();
          setSelectedTee();
          setSelectedElbow();
          setSelectedTransition();
          setSelectedEquipment();
          setSelectedPdf();
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvas]);

  useEffect(() => {
    if (projectData?.files) onDataLoad(projectData);
  }, [projectData]);

  // useEffect(() => {
  //   let distanceRatioParts = selectedDistanceRatio.split(':');
  //   let distanceScale = parseFloat(distanceRatioParts[1]) / parseFloat(distanceRatioParts[0]);
  //   dispatch(setScale(distanceScale));
  // }, [selectedDistanceRatio]);

  const toggleLineButton = () => {
    setShowLineButton(prevShowLineButton => !prevShowLineButton);
  };

  const toggleUploadPdfButton = () => {
    setShowUploadPdfButton(prevShowUploadPDfButton => !prevShowUploadPDfButton);
  };

  const toggleScaleButton = () => {
    setShowScaleButton(prevShowScaleButton => !prevShowScaleButton);
    setShowScaleNextToCalculate(prevShowScaleNextToCalculate => !prevShowScaleNextToCalculate);
  };

  const handleBodyClick = event => {
    // Check if the clicked element is inside the canvas
    const isClickInsideCanvas = canvasRef.current.contains(event.target);

    // If the click is outside the canvas, hide the ScalePdf component
    if (!isClickInsideCanvas) {
      showSelectedScale(false);
    }
  };

  useEffect(() => {
    // Add event listener when the component mounts
    document.body.addEventListener('click', handleBodyClick);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, [canvasRef]);

  useEffect(() => {
    if (!canvas && canvasRef.current) {
      const canvas = initCanvas(
        mainboardRef.current.clientWidth,
        mainboardRef.current.clientWidth / aspectRatio,
      );
      setCanvas(canvas);
      const center = canvas.getCenter();
      setPdfLeft(center.left);
      setPdfTop(center.top);
      handleResize(resizeCanvas(canvas, mainboardRef.current)).observe(mainboardRef.current);
    }
  }, [canvasRef]);

  useEffect(() => {
    if (canvas && loadedPDFs.length > 0) {
      canvas.clear();
      const uniquePdfIds = new Set();

      const filteredPDFs = loadedPDFs.filter(pdf => {
        if (
          pdf?.src !== '' &&
          pdf?.position?.selectedLevel === selectedlevel?.name &&
          !uniquePdfIds.has(pdf?.position?.pdfId)
        ) {
          uniquePdfIds.add(pdf.position.pdfId);
          return true;
        }
        return false;
      });
      if (filteredPDFs.length > 0) {
        dispatch(setIsPdfPresent(true));
      } else {
        dispatch(setIsPdfPresent(false));
      }
      const filteredLines = drawnLines?.filter(line => {
        return line?.level === selectedlevel?.name;
      });
      const filteredTees = tees?.filter(tee => {
        return tee?.level === selectedlevel?.name;
      });
      const filteredElbows = elbows?.filter(elbow => {
        return elbow?.level === selectedlevel?.name;
      });
      const filteredTransitions = transitions?.filter(transition => {
        return transition?.level === selectedlevel?.name;
      });
      const filteredEquipment = equipments?.filter(equipment => {
        return equipment?.selectedLevel === selectedlevel?.name;
      });

      filteredPDFs?.forEach((pdf, index) => {
        fabric.Image.fromURL(pdf?.src, img => {
          const newWidth = (img?.width * 45) / 100;
          const newHeight = (img?.height * 10) / 100;


          // img.scaleToWidth(pdfWidth);
          // img.scaleToHeight(newHeight);

          const originalWidth = img.width;
          const originalHeight = img.height;

          const dimension = Math.max(pdfWidth, pdfHeight);
          const targetWidth = dimension * pdfScale;
          const targetHeight = dimension * pdfScale;

          const scaleX = targetWidth / originalWidth;
          const scaleY = targetHeight / originalHeight;

          const scaleFactor = Math.min(scaleX, scaleY);
          img?.set({
            ...pdf?.position,
            selectable: !toggleScale,
            opacity: toggleScale ? 1 : 0.5,
            // scaleX: newWidth / img?.width,
            // scaleY: newHeight / img?.height,
            stroke: 'black', // Border color
            strokeWidth: 2,
            // scaleX: scale,
            // scaleY: scale * 0.85,

            scaleX: scaleFactor,
            scaleY: scaleFactor,
            left: (canvas.width - targetWidth * scaleFactor) / 2,
            top: (canvas.height - targetHeight * scaleFactor) / 2,
          });

          dispatch(setUploadedPdfIndex(index));

          img.on('mousedown', event => {
            setSelectedPdf(img);
            setSelectedLine();
            setSelectedTee();
            setSelectedElbow();
            setSelectedTransition();
            setSelectedEquipment();
            const clickedIndex = filteredPDFs.findIndex(item => item?.src === event?.target.getSrc());
            const clickedPdfId = filteredPDFs[clickedIndex]?.position?.pdfId;
            setLockedPdfIndex(clickedIndex);
            dispatch(setPdfIndex(clickedPdfId));
          });

          img.on('mousemove', event => {
            handlePdfMoving(event);
          });

          canvas.add(img);
          canvas.sendToBack(img);
        });
      });

      filteredLines?.forEach(line => {
        line.on('mousedown', event => {
          setSelectedPdf();
          setSelectedLine(line);
          setSelectedTee();
          setSelectedElbow();
          setSelectedTransition();
          setSelectedEquipment();
        });
        line.hasControls = false;
        line.lockMovementX = true;
        line.lockMovementY = true;
        line.lockScalingX = true;
        line.lockScalingY = true;
        // line.selectable = false;
        canvas.add(line);
        canvas.bringToFront(line);
        // line.intersectsWithObject()
        // line.arrows.forEach(head => {
        //   canvas.add(head);
        // });
      });

      filteredTees?.forEach(tee => {
        tee.on('mousedown', event => {
          setSelectedPdf();
          setSelectedTee(tee);
          setSelectedLine();
          setSelectedElbow();
          setSelectedTransition();
          setSelectedEquipment();
        });
        // tee.selectable = false;
        tee.hasControls = false;
        tee.lockMovementX = true;
        tee.lockMovementY = true;
        tee.lockScalingX = true;
        tee.lockScalingY = true;
        canvas.add(tee);
        canvas.bringToFront(tee);
      });

      filteredElbows?.forEach(elbow => {
        elbow.on('mousedown', event => {
          setSelectedPdf();
          setSelectedTee();
          setSelectedLine();
          setSelectedElbow(elbow);
          setSelectedTransition();
          setSelectedEquipment();
        });
        elbow.hasControls = false;
        elbow.lockMovementX = true;
        elbow.lockMovementY = true;
        elbow.lockScalingX = true;
        elbow.lockScalingY = true;
        // elbow.selectable = false;
        canvas.add(elbow);
        canvas.bringToFront(elbow);
      });

      filteredTransitions?.forEach(transition => {
        transition.on('mousedown', event => {
          setSelectedPdf();
          setSelectedTee();
          setSelectedLine();
          setSelectedElbow();
          setSelectedTransition(transition);
          setSelectedEquipment();
        });
        transition.hasControls = false;
        transition.lockMovementX = true;
        transition.lockMovementY = true;
        transition.lockScalingX = true;
        transition.lockScalingY = true;
        // transition.selectable = false;
        canvas.add(transition);
        canvas.bringToFront(transition);
      });

      filteredEquipment?.forEach((option, index) => {
        if (option.icon) {
          fabric.Image.fromURL(option.icon, fabricImage => {
            fabricImage.set({
              left: option.position.left,
              top: option.position.top,
              originX: 'center',
              originY: 'center',
              angle: option.rotation,
              selectable: !drawingMode,
              scaleX: scale / 10,
              scaleY: scale / 10,
              hasControls: true,
              hasBorders: true,
              hasRotatingPoint: true,
            });

            fabricImage.set('data', { option: option });

            fabricImage.on('mousedown', event => {
              setSelectedPdf();
              setSelectedEquipment(option);
              setSelectedLine();
              setSelectedTee();
              setSelectedElbow();
            });

            fabricImage.on('mousemove', event => {
              equipmentDrag(event, option);
            });

            fabricImage.on('mouseup', event => {
              equipmentDrop(event);
            });

            canvas.on('mouse:move', event => {
              const tooltip = document.getElementById('fabric-tooltip');
              if (tooltip) {
                const pointer = canvas.getPointer(event.e);
                const target = canvas.findTarget(event.e);

                if (target && target.data) {
                  const option = target.data.option;
                  let tooltipContent = `<b>${option.name || 'Equipment'}</b>`;

                  if (option.value === 'Diffuser' && option.pressureDrop !== undefined) {
                    tooltipContent += `<br><b>Pressure Drop :</b> ${option.pressureDrop.toFixed(3)}`;
                  }

                  tooltip.innerHTML = tooltipContent;
                  tooltip.style.display = 'block';

                  const left = event.e.clientX + 10;
                  const top = event.e.clientY + 10;

                  tooltip.style.left = left + 'px';
                  tooltip.style.top = top + 'px';
                } else {
                  tooltip.style.display = 'none';
                }
              }
            });

            fabricImage.hasControls = false;
            canvas.add(fabricImage);
            canvas.bringToFront(fabricImage);
          });
        }
      });
      // Add this CSS to your stylesheet
      const style = document.createElement('style');
      style.textContent = `
  #fabric-tooltip {
    display: none;
    position: fixed;
    background-color: rgba(255, 255, 255);
    color: black;
    padding: 5px 10px;
    border-radius: 2px;
    border: 1px solid black;
    font-size: 10px;
    pointer-events: none;
    z-index: 1000;
    transition: left 0.1s, top 0.1s;
    white-space: nowrap;
  }
`;
      document.head.appendChild(style);
      canvas.requestRenderAll();
    }
  }, [
    canvas,
    loadedPDFs,
    selectedlevel?.name,
    scale,
    pdfWidth,
    drawnLines,
    tees,
    elbows,
    equipments,
    transitions,
    drawingMode,
  ]);

  useEffect(() => {
    if (canvas) {
      const center = canvas.getCenter();
      setIsLoading(true);
      // setPdfLeft(center.left);
      // setPdfTop(center.top);

      const pdfId = generateRandomId();
      setLoadedPDFs(prevPDFs => [
        ...prevPDFs,
        {
          src: fileReaderInfo.currentPage,
          position: {
            // left: left,
            // top: top,
            originX: 'center',
            originY: 'center',
            selectable: !drawing,
            evented: true,
            hasControls: false,
            hasBorders: false,
            pdfId: pdfId,
            selectedLevel: selectedlevel?.name,
            fileName: fileReaderInfo.name,
          },
        },
      ]);
      // createCordinates();
      canvas.renderAll();
    }
  }, [fileReaderInfo.currentPage]);

  const handlePdfMoving = event => {
    const { target } = event;
    if (target) {
      // Make sure there is a target when this function is called
      setPdfLeft(target.left); // You may need to adjust how you calculate these based on your needs
      setPdfTop(target.top);
      createCordinates(); // Ensure createCordinates can handle being called in this context
    }
  };

  useEffect(() => {
    createCordinates();
  }, [showCoordinate, pdfScale]);

  useEffect(() => {
    if (canvas) {
      dispatch(showSelectedScale(true));
      canvas.on('mouse:wheel', event => {
        const delta = event.e.deltaY;
        let zoom = canvas.getZoom();
        zoom = zoom + delta / 1000;
        // if (zoom > 5) zoom = 5;
        if (zoom < 0.3) zoom = 0.3;
        canvas.zoomToPoint({ x: event.e.offsetX, y: event.e.offsetY }, zoom);
        event.e.preventDefault();
        event.e.stopPropagation();
      });

      setTimeout(() => {
        centerCanvasView(canvas);
        dispatch(setDrawingMode(false));
      }, 100);
    }
  }, [canvas]);

  useEffect(() => {
    let lastPosX = null;
    let lastPosY = null;
    let isDragging = false;
    let targetIsPdf = false;
    const panningSensitivity = 0.9;

    const startPan = opt => {
      const evt = opt.e;
      lastPosX = evt.clientX;
      lastPosY = evt.clientY;
      isDragging = true;
      canvas.selection = false;
      const activeObject = canvas.getActiveObject();
      targetIsPdf = activeObject && activeObject.type === 'image';
    };

    const movePan = opt => {
      if (isDragging) {
        const e = opt.e;
        if (targetIsPdf) {
          handlePdfMoving({
            target: canvas.getActiveObject(),
            left: e.clientX,
            top: e.clientY,
          });
        } else {
          const deltaX = (e.clientX - lastPosX) * panningSensitivity;
          const deltaY = (e.clientY - lastPosY) * panningSensitivity;
          lastPosX = e.clientX;
          lastPosY = e.clientY;
          const vpt = canvas.viewportTransform;
          vpt[4] += deltaX;
          vpt[5] += deltaY;
          canvas.requestRenderAll();
        }
      }
    };

    const endPan = () => {
      isDragging = false;
      targetIsPdf = false;
      canvas.selection = true;
      lastPosX = null;
      lastPosY = null;
    };

    if (canvas) {
      if (isPanning) {
        canvas.on('mouse:down', startPan);
        canvas.on('mouse:move', movePan);
        canvas.on('mouse:up', endPan);
        canvas.defaultCursor = 'grab';
      } else {
        canvas.off('mouse:down', startPan);
        canvas.off('mouse:move', movePan);
        canvas.off('mouse:up', endPan);
        canvas.defaultCursor = 'default';
      }
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:down', startPan);
        canvas.off('mouse:move', movePan);
        canvas.off('mouse:up', endPan);
      }
    };
  }, [canvas, handlePdfMoving, isPanning, toggleScale]);

  const handlePanningToggle = () => {
    setIsPanning(prev => !prev);
  };


  async function onFileChange(event) {
    const file = event.target.files[0];

    if (file && file.type === 'application/pdf') {
      try {
        const arrayBuffer = await file.arrayBuffer();
        const pdf = await pdfjs.getDocument(arrayBuffer).promise;

        if (pdf.numPages === 1) {
          updateFileReaderInfo({
            file: file,
            currentPageNumber: 1,
            name: file.name,
          });
          setPdfFiles(prevPDFs => [...prevPDFs, file]);
        } else {
          alert('Please upload only single-page PDFs.');
        }
      } catch (error) {
        console.error('Error reading PDF:', error);
        alert('Error reading PDF. Please try again.');
      }
    } else {
      alert('Please upload a valid PDF file.');
    }
  }


  function updateFileReaderInfo(data) {
    setFileReaderInfo(prevInfo => ({ ...prevInfo, ...data }));
  }

  const onKeyDown = event => {
    if (event.key === 'Escape') {
      mouseDown = false;
      dispatch(setLineColor(null));
    }
  };

  useEffect(() => {
    const cleanup = () => {
      document.removeEventListener('keydown', onKeyDown);
    };
    return cleanup;
  }, []);
  const dashed = false;
  const [allowLineCreation, setAllowLineCreation] = useState(false);

  const toggleLineCreation = () => {
    setAllowLineCreation(prevAllowLineCreation => !prevAllowLineCreation);
  };

  function enterDragMode() {
    setMode('drag');
  }

  // useEffect(() => {
  //   if (canvas) {
  //     canvas.on('mouse:dblclick', enterDragMode);
  //   }
  // }, [canvas, mode]);

  const calculateScale = () => {
    if (canvas && fileReaderInfo.currentPage && selectedPaperSize) {
      const pdfWidths = canvas.width / canvas.getZoom();
      const pdfHeights = canvas.height / canvas.getZoom();

      let paperSize = 'Unknown';
      let targetWidth, targetHeight;

      standardPaperSizes.forEach(size => {
        if (size.name === selectedPaperSize) {
          paperSize = size.name;
          // targetWidth = (size.width*72)/10
          targetHeight = (size.height / 72) * 25.4;
          // dispatch(setSelectedPdfWidth(targetWidth));
          dispatch(setSelectedPdfHeight(targetHeight));
          setPaperType(paperSize);
        }
      });
    }
  };

  const downloadPdf = () => {
    if (canvas) {
      // Create a new canvas to render only the user's drawings
      const drawingCanvas = new fabric.Canvas();

      // Iterate through canvas objects to find the drawings
      canvas.getObjects().forEach(obj => {
        if (obj.name !== 'horizontalLine' && obj.name !== 'verticalLine') {
          // Clone the object and add it to the drawing canvas
          const cloneObj = fabric.util.object.clone(obj);
          drawingCanvas.add(cloneObj);
        }
      });

      // Convert drawings to an image
      drawingCanvas.renderAll();
      const drawingDataURL = drawingCanvas.toDataURL('image/png');

      // Create a new PDF document
      const pdf = new jsPDF('p', 'pt', paperType);

      // Add the original PDF content
      const originalPdfWidth = pdf.internal.pageSize.getWidth();
      const originalPdfHeight = pdf.internal.pageSize.getHeight();
      pdf.addImage(fileReaderInfo.currentPage, 'JPEG', 0, 0, originalPdfWidth, originalPdfHeight);

      // Add the user's drawings as an image
      const aspectRatio = originalPdfWidth / originalPdfHeight;
      let imgWidth, imgHeight;

      if (aspectRatio > 1) {
        imgWidth = originalPdfWidth;
        imgHeight = originalPdfWidth / aspectRatio;
      } else {
        imgHeight = originalPdfHeight;
        imgWidth = originalPdfHeight * aspectRatio;
      }
      pdf.addImage(drawingDataURL, 'PNG', 0, 0, imgWidth, imgHeight);

      // Download the PDF
      pdf.save(`${fileReaderInfo.file.name}_edited.pdf`);
    }
  };

  const lockPdf = () => {
    // if (lockedPdfIndex !== null) {
    setLoadedPDFs(prevPDFs => {
      return prevPDFs.map((pdf, index) => {
        if (pdf?.position?.pdfId === pdfindex) {
          dispatch(showSelectedScale(!toggleScale));
        }
        return pdf;
      });
    });
    setLockedPdfIndex(null);
    // }
  };

  const createCordinates = () => {
    if (canvas) {
      const calculateLineLengths = () => {
        const horizontalLineLength = (canvas.width / canvas.getZoom()) * 60;
        const verticalLineLength = (canvas.height / canvas.getZoom()) * 60;
        return { horizontalLineLength, verticalLineLength };
      };

      const existingHorizontalLine = canvas.getObjects().find(obj => obj.name === 'horizontalLine');
      const existingVerticalLine = canvas.getObjects().find(obj => obj.name === 'verticalLine');

      const centerX = canvas.width / 2;
      const centerY = canvas.height / 2;

      if (existingHorizontalLine && existingVerticalLine) {
        const lineLengths = calculateLineLengths();
        existingHorizontalLine.set({
          x1: centerX - lineLengths.horizontalLineLength,
          x2: centerX + lineLengths.horizontalLineLength,
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 0.5),
        });
        existingVerticalLine.set({
          y1: centerY - lineLengths.verticalLineLength,
          y2: centerY + lineLengths.verticalLineLength,
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 0.5),
        });
      } else {
        const horizontalLine = new fabric.Line([centerX - 1, centerY, centerX + 1, centerY], {
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 3),
          stroke: showCoordinate,
          selectable: false,
          name: 'horizontalLine',
        });

        const verticalLine = new fabric.Line([centerX, centerY - 1, centerX, centerY + 1], {
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 3),
          stroke: showCoordinate,
          selectable: false,
          name: 'verticalLine',
        });

        canvas.add(horizontalLine, verticalLine);
      }

      canvas.on('object:scaling', event => {
        const target = event.target;
        if (target.name === 'horizontalLine' || target.name === 'verticalLine') {
          const lineLengths = calculateLineLengths();
          target.set({
            x1: centerX - lineLengths.horizontalLineLength,
            x2: centerX + lineLengths.horizontalLineLength,
            y1: centerY - lineLengths.verticalLineLength,
            y2: centerY + lineLengths.verticalLineLength,
            strokeWidth: 1 / canvas.getZoom(),
          });

          canvas.requestRenderAll();
        }
      });

      canvas.renderAll();
    }
  };

  const allowDrop = event => {
    event.preventDefault();
  };

  const dropElement = event => {
    event.preventDefault();
    const data = event.dataTransfer.getData('text');
    const draggedElement = document.getElementById(dragid);
    const canvas = document.getElementById('canvas');
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    ctx.drawImage(draggedElement, x, y, 15, 15); // Adjust width and height as needed
  };

  const handleSave = async (isAutosave = false) => {
    if (isAutosave) {
      dispatch(setAutosaveStatus('saving'));
    } else {
      setSnackBarText('Saving...');
      handleSnackBar(true);
    }
    let linesArray = [];
    let teesArray = [];
    let elbowsArray = [];
    let transitionArray = [];
    let filesArray = [];
    drawnLines.forEach(line => {
      const isDuplicate = linesArray.some(existingLine => existingLine.id === line.id);
      if (isDuplicate) {
        return;
      } else {
        let tempLine = line.toJSON([
          'id',
          'level',
          'arrows',
          'position',
          'flow',
          'angle',
          'teeType',
          'lineIds',
          'ductType',
          'diameter',
          'flowRate',
          'pressure',
          'direction',
          'ductWidth',
          'ductLength',
          'ductHeight',
          'systemType',
          'orientation',
        ]);
        tempLine = {
          ...tempLine,
          x1: line.x1,
          y1: line.y1,
          x2: line.x2,
          y2: line.y2,
        };
        linesArray.push(tempLine);
      }
    });

    tees.forEach(tee => {
      let tempTee = tee.toJSON([
        'id',
        'level',
        'teeType',
        'line1Id',
        'line2Id',
        'lineIds',
        'connection',
        'fittingType',
      ]);
      teesArray.push(tempTee);
    });

    elbows.forEach(elbow => {
      let tempElbow = elbow.toJSON([
        'id',
        'level',
        'line1Id',
        'line2Id',
        'lineIds',
        'elbowType',
        'bendAngle',
        'bendRadius',
        'connection',
        'rotationAngle',
      ]);
      elbowsArray.push(tempElbow);
    });

    transitions.forEach(transition => {
      let tempTransition = transition.toJSON([
        'id',
        'level',
        'connection',
        'lineIds',
        'transitionType',
        'transitionAngle',
      ]);
      transitionArray.push(tempTransition);
    });

    pdfFiles.forEach(file => {
      const isDuplicate = filesArray.some(
        uniqueFile =>
          uniqueFile.name === file.name &&
          uniqueFile.size === file.size &&
          uniqueFile.lastModified === file.lastModified,
      );

      if (!isDuplicate) {
        filesArray.push(file);
      }
    });

    const token = localStorage.getItem('token');
    let uniqueSrcMap = loadedPDFs.reduce((acc, item) => {
      if (item.src !== '') {
        acc[item.src] = item.position;
      }
      return acc;
    }, {});
    let pdfPositions = Object.values(uniqueSrcMap);
    let canvasCenter = canvas.getCenter();
    let updatedProjectData = {
      ...projectData,
      lines: linesArray,
      equipments: equipments,
      pdfPositions: pdfPositions,
      levels: levels,
      center: canvasCenter,
      pdfPosition: {
        top: top,
        left: left,
        pdfWidth: pdfWidth,
        pdfHeight: pdfHeight,
        pdfScale: pdfScale,
        paperType: paperType,
      },
      tees: teesArray,
      elbows: elbowsArray,
      transitions: transitionArray,
    };

    if (updatedProjectData.files) {
      delete updatedProjectData.files;
    }

    const formData = new FormData();
    formData.append('projectData', JSON.stringify(updatedProjectData));
    formData.append('projectSettings', JSON.stringify(projectSettings));
    filesArray.forEach((src, index) => {
      formData.append('pdfFiles', src);
    });

    const response = await updateProject(token, projectId, formData);
    if (response) {
      setProjectData(updatedProjectData);
      const newProjects = projects.map(project => (project._id === response._id ? response : project));
      dispatch(setProjects(newProjects));
      if (isAutosave) {
        dispatch(setAutosaveStatus('saved'));
      } else {
        setSnackBarText('Project saved');
      }
    } else {
      if (isAutosave) {
        dispatch(setAutosaveStatus('idle'));
      } else {
        setSnackBarText('Something went wrong');
      }
    }
    if (!isAutosave) {
      setTimeout(() => {
        handleSnackBar(false);
      }, 6000);
    }
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <div id="fabric-tooltip"></div>
      <div ref={mainboardRef}>
        <Header
          toggleLineButton={toggleLineButton}
          showUploadPdfButton={showUploadPdfButton}
          fileReaderInfo={fileReaderInfo}
          scalingRatio={scalingRatio}
          onDistanceRatioChange={onDistanceRatioChange}
          toggleUploadPdfButton={toggleUploadPdfButton}
          onFileChange={onFileChange}
          canvas={canvas}
          paperType={paperType}
          toggleScaleButton={toggleScaleButton}
          showLineButton={showLineButton}
          showScaleNextToCalculate={showScaleNextToCalculate}
          selectedDistanceRatio={selectedDistanceRatio}
          showScaleButton={showScaleButton}
          lockPdf={lockPdf}
          createCordinates={createCordinates}
          download={downloadPdf}
          toggleLineCreation={toggleLineCreation}
          drawnLines={drawnLines}
          setDrawnLines={setDrawnLines}
          setElbows={setElbows}
          transitions={transitions}
          setTransitions={setTransitions}
          allowLineCreation={allowLineCreation}
          save={handleSave}
          setSelectedLine={setSelectedLine}
          setSelectedTee={setSelectedTee}
          setSelectedElbow={setSelectedElbow}
          equipments={equipments}
          tees={tees}
          elbows={elbows}
          setEquipments={setEquipments}
          setTees={setTees}
          selectedEquipment={selectedEquipment}
          setSelectedEquipment={setSelectedEquipment}
          projectData={projectData}
        />
        <div className={styles.canvasContainer} onDragOver={allowDrop} onDrop={dropElement}>
          <div className={styles.responsiveBox}>
            <div className={styles.leftBox}>
              <PdfList canvas={canvas} updateFileReaderInfo={updateFileReaderInfo} pdfFiles={pdfFiles} />
            </div>
          </div>
          <canvas ref={canvasRef} id="canvas"></canvas>
        </div>
        <PanelSwitcher
          selectedLine={selectedLine}
          selectedTee={selectedTee}
          selectedEquipment={selectedEquipment}
          selectedPdf={selectedPdf}
          selectedElbow={selectedElbow}
          selectedTransition={selectedTransition}
          closeLinePanel={closeLinePanel}
          closeTeePanel={closeTeePanel}
          setDrawnLines={setDrawnLines}
          closeElbowPanel={closeElbowPanel}
          closeTransitionPanel={closeTransitionPanel}
          drawnLines={drawnLines}
          elbows={elbows}
          setElbows={setElbows}
          transitions={transitions}
          setTransitions={setTransitions}
          setTees={setTees}
          tees={tees}
          setSelectedEquipment={setSelectedEquipment}
          setEquipments={setEquipments}
          equipments={equipments}
          rotateLeft={rotateLeft}
          rotateRight={rotateRight}
          onPaperRatioChange={onPaperRatioChange}
          onPaperSizeChange={onPaperSizeChange}
          selectedDistanceRatio={selectedDistanceRatio}
          selectedPaperSize={selectedPaperSize}
          calculateScale={calculateScale}
          pdfFiles={pdfFiles}
          setPdfFiles={setPdfFiles}
          loadedPDFs={loadedPDFs}
          setLoadedPDFs={setLoadedPDFs}
          setSelectedPdf={setSelectedPdf}
          projectData={projectData}
        />
        <PdfReader
          fileReaderInfoArray={fileReaderInfoArray}
          fileReaderInfo={fileReaderInfo}
          fabric={fabric}
          canvas={canvas}
          updateFileReaderInfo={updateFileReaderInfo}
          selectedPaperSize={selectedPaperSize}
          drawing={drawing}
          mode={mode}
          projectData={projectData}
          setLoadedPDFs={setLoadedPDFs}
        />
        <CanvasBar
          canvas={canvas}
          lockPdf={lockPdf}
          selectedPdf={selectedPdf}
          isPanning={isPanning}
          handlePanningToggle={handlePanningToggle}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={snackBar}
          autoHideDuration={2000}
          message={snackBarText}
        >
          <Alert onClose={handleCloseSnackBar} variant="filled" sx={{ width: '100%', bgcolor: '#4E5FBF' }}>
            {snackBarText}
          </Alert>
        </Snackbar>
      </div>
    </>
  );
};

export default MainBoard;
