import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React, { useMemo } from 'react';
import countryList from 'react-select-country-list';

function CountrySelector({ value, onChange }) {
    const options = useMemo(() => countryList().getData(), []);

    const handleCountryChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth margin="normal">
            <InputLabel size='small'>Country</InputLabel>
            <Select value={value} onChange={handleCountryChange} label="Country" size='small'>
                {options.map((country) => (
                    <MenuItem key={country.value} value={country.value}>
                        {country.label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default CountrySelector;
