import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import linePanelStyles from '../PanelStyles/LinePanelStyles';

const GeneralInfo = ({ handleChange, generalInfo, selectedLine, unitSystem }) => (
  <Box sx={{ ...linePanelStyles.propertiesBox }}>
    <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          type="number"
          label="Flow rate"
          variant="outlined"
          sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
          value={generalInfo.flowRate}
          onChange={e => handleChange('flowRate', parseFloat(e.target.value))}
          InputProps={{
            endAdornment: (
              <Typography variant="caption">
                {unitSystem === 'Imperial' ? (
                  'cfm'
                ) : (
                  <>
                    m<sup>3</sup>/s
                  </>
                )}
              </Typography>
            ),
          }}
        />
      </Box>
    </Box>
    {/* <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          type="number"
          label="Pressure Drop"
          variant="outlined"
          sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
          value={selectedLine.pressure}
          onChange={e => handleChange('pressure', parseFloat(e.target.value))}
        />
      </Box>
    </Box> */}
  </Box>
);

const TechnicalInfo = ({ handleChange, selectedLine, unitSystem }) => (
  <Box sx={{ ...linePanelStyles.propertiesBox }}>
    <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <TextField
        variant="outlined"
        label="Material Type"
        sx={{ ...linePanelStyles.inputNumber, width: '100%' }}
        disabled
        defaultValue={'Galvanized steel'}
        value={selectedLine.materialType}
        onChange={e => handleChange('materialType', e.target.value)}
      />
    </Box>
    <Box sx={{ width: '100%', marginBottom: '20px' }}>
      <FormControl fullWidth sx={{ ...linePanelStyles.inputNumber, marginBottom: 2 }}>
        <InputLabel>Duct type</InputLabel>
        <Select
          variant="outlined"
          label="Duct type"
          disabled
          value={selectedLine.ductType}
          onChange={e => handleChange('ductType', e.target.value)}
          sx={{ width: '100%' }}
        >
          <MenuItem value="Rectangular">Rectangular</MenuItem>
          <MenuItem value="Round">Round</MenuItem>
        </Select>
      </FormControl>
    </Box>
    {selectedLine.ductType === 'Round' && (
      <Box sx={{ width: '100%', marginBottom: '20px' }}>
        <TextField
          type="number"
          variant="outlined"
          label="Diameter"
          sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
          value={selectedLine.diameter}
        />
      </Box>
    )}
    {selectedLine.ductType === 'Rectangular' && (
      <>
        <Box sx={{ width: '100%', marginBottom: '20px' }}>
          <Typography
            sx={{
              ...linePanelStyles.inputNumber,
              textAlign: 'left',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            Length
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
            value={selectedLine.ductLength}
            onChange={e => handleChange('ductLength', parseFloat(e.target.value))}
            InputProps={{
              endAdornment: (
                <Typography variant="caption">{unitSystem === 'Imperial' ? 'ft' : 'm'}</Typography>
              ),
            }}
          />
        </Box>
        <Box sx={{ width: '100%', marginBottom: '20px' }}>
          <Typography
            sx={{
              ...linePanelStyles.inputNumber,
              textAlign: 'left',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            Width
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
            value={selectedLine.ductWidth}
            onChange={e => handleChange('ductWidth', parseFloat(e.target.value))}
            InputProps={{
              endAdornment: (
                <Typography variant="caption">{unitSystem === 'Imperial' ? 'in' : 'mm'}</Typography>
              ),
            }}
          />
        </Box>
        <Box sx={{ width: '100%', marginBottom: '20px' }}>
          <Typography
            sx={{
              ...linePanelStyles.inputNumber,
              textAlign: 'left',
              fontWeight: 'bold',
              marginBottom: '5px',
            }}
          >
            Height
          </Typography>
          <TextField
            type="number"
            variant="outlined"
            size="small"
            sx={{ ...linePanelStyles.inputNumber, flex: 1, textAlign: 'right' }}
            value={selectedLine.ductHeight}
            onChange={e => handleChange('ductHeight', parseFloat(e.target.value))}
            InputProps={{
              endAdornment: (
                <Typography variant="caption">{unitSystem === 'Imperial' ? 'in' : 'mm'}</Typography>
              ),
            }}
          />
        </Box>
      </>
    )}
  </Box>
);

const LinePanel = ({ closeLinePanel, selectedLine, setDrawnLines, drawnLines, unitSystem }) => {
  const [view, setView] = useState('general');
  const [generalInfo, setGeneralInfo] = useState({ flowRate: '' });

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setGeneralInfo({ flowRate: selectedLine.flowRate });
  }, [selectedLine]);

  const handleDelete = () => {
    if (selectedLine?.id) {
      const filteredItems = drawnLines.filter(item => item.id !== selectedLine.id);
      setDrawnLines(filteredItems);
      closeLinePanel();
    }
  };

  const handleChange = (property, newValue) => {
    const updatedLines = drawnLines.map(item => {
      if (item.id === selectedLine?.id) {
        item.set(property, newValue);
        return item;
      }
      return item;
    });
    setGeneralInfo(prevInfo => ({ ...prevInfo, [property]: newValue }));
    setDrawnLines(updatedLines);
  };

  return (
    <Container sx={linePanelStyles.container}>
      <Typography variant="h6" sx={linePanelStyles.ductPropsHeader}>
        Duct Properties
      </Typography>
      <Box sx={{ width: '100%', marginBottom: '20px', display: 'flex', justifyContent: 'center' }}>
        <Button
          variant={view === 'general' ? 'contained' : 'outlined'}
          sx={{ ...linePanelStyles.tabButton }}
          onClick={() => setView('general')}
        >
          General
        </Button>
        <Button
          variant={view === 'technical' ? 'contained' : 'outlined'}
          size="small"
          sx={{ ...linePanelStyles.tabButton }}
          onClick={() => setView('technical')}
        >
          Technical
        </Button>
      </Box>
      <Box sx={{ width: '100%' }}>
        {view === 'general' ? (
          <GeneralInfo
            generalInfo={generalInfo}
            selectedLine={selectedLine}
            setDrawnLines={setDrawnLines}
            drawnLines={drawnLines}
            handleChange={handleChange}
            unitSystem={unitSystem}
          />
        ) : (
          <TechnicalInfo
            selectedLine={selectedLine}
            setDrawnLines={setDrawnLines}
            drawnLines={drawnLines}
            handleChange={handleChange}
            unitSystem={unitSystem}
          />
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'row', columnGap: 2, marginTop: 2 }}>
        <Button
          variant="outlined"
          onClick={handleDelete}
          sx={{ ...linePanelStyles.deleteButton, bgcolor: 'red', color: 'white' }}
          color="error"
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={closeLinePanel} sx={{ ...linePanelStyles.deleteButton }}>
          Cancel
        </Button>
      </Box>
    </Container>
  );
};

export default LinePanel;
