import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, InputAdornment, Skeleton, TextField, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardView from '../components/Cards/CardView';
import CreateProject from '../components/Dialog/CreateProject';
import { setProjects } from '../redux/slice/projectSlice';
import { getProjects } from '../services/projectService';

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [createDialog, setCreateDialog] = useState(false);
  const { projects } = useSelector(state => state.project);

  const [filterProjects, setFilterProjects] = useState([]);



  useEffect(() => {
    const fetchData = async () => {
      if (projects.length === 0) {
        setLoadingProjects(true);
        const token = localStorage.getItem('token');
        const response = await getProjects(token);
        dispatch(setProjects(response));
        setFilterProjects(response);
        setLoadingProjects(false);
      }
    };
    fetchData();
  }, [projects, dispatch]);

  useEffect(() => {
    setSearchTerm('');
    setFilterProjects(projects);
  }, [projects]);


  function handleProjectSearch(e) {
    const projectName = e.target.value.toLowerCase();
    setSearchTerm(projectName);

    const filtered = projects.filter(project => {
      if (project.projectData) {
        if (Array.isArray(project.projectData.projectCreation)) {
          return project.projectData.projectCreation.some(creation =>
            creation.projectInformation &&
            creation.projectInformation.projectName &&
            creation.projectInformation.projectName.toLowerCase().includes(projectName)
          );
        } else if (project.projectData.projectName) {
          return project.projectData.projectName.toLowerCase().includes(projectName);
        } else {
          return "No Project Name".includes(projectName);
        }
      }
      return false;
    });

    setFilterProjects(filtered);
  }





  function createNewProject() {
    setCreateDialog(true);
  }

  return (
    <Grid container spacing={0} sx={{ height: '100vh', overflow: 'hidden' }}>
      <Grid item xs={12} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ padding: '30px' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            {loadingProjects ? (
              <Skeleton variant="rectangular" width={100} height={40} />
            ) : (
              <Box sx={{ alignItems: 'center' }}>
                <Typography variant="h6">Projects({filterProjects.length})</Typography>
              </Box>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 4,
                alignItems: 'center',
              }}
            >
              <Box sx={{ width: '500px' }}>
                <TextField
                  sx={{
                    width: '100%',
                    '& fieldset': {
                      borderColor: theme.palette.text.main,
                    },
                  }}
                  size="small"
                  variant="outlined"
                  onChange={handleProjectSearch}
                  placeholder="Search"
                  value={searchTerm}
                  type="text"
                  id="project-search"
                  color="secondary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Button
                  id="createNew"
                  variant="outlined"
                  startIcon={<AddIcon />}
                  onClick={createNewProject}
                >
                  NEW PROJECT
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 4, flexGrow: 1, overflow: 'auto' }}>
          <Grid container spacing={2}>
            {loadingProjects ? (
              Array.from(new Array(6)).map((_, index) => (
                <Grid item xs={12} sm={6} md={2} lg={2} key={index}>
                  <CardView loading={true} />
                </Grid>
              ))
            ) : (
              filterProjects.map((project, index) => (
                <Grid item xs={12} sm={6} md={2} lg={2} key={index}>
                  <CardView
                    project={project}
                    setProjects={setProjects}
                    loading={false}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Box>
      </Grid>
      {createDialog && (
        <CreateProject createDialog={createDialog} onClose={() => setCreateDialog(false)} />
      )}
    </Grid>
  );
};

export default Dashboard;
