const ahuPanelStyles = {
  container: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: 350,
    maxHeight: '60vh',
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: 2,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1000,
    overflowY: 'auto',
  },
  title: {
    textAlign: 'center',
    marginBottom: 2,
  },
  rotationSection: {
    marginBottom: 2,
  },
  rotationControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  rotationButton: {
    margin: '0 5px',
  },
  rightToLeftSelect: {
    minWidth: 120,
    margin: 'auto',
  },
  propertiesButton: {
    width: '100%',
  },
  additionalPropertiesBox: {
    padding: 2,
    marginTop: 2,
  },
  propertiesDivider: {
    width: '100%',
    height: 2,
    backgroundColor: '#ccc',
    margin: '10px 0',
  },
  shapeSelect: {
    marginBottom: 1,
  },
  dimensionInput: {
    my: 1,
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    mt: 2,
  },
};

export default ahuPanelStyles;
