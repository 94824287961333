import {
    Box,
    Card,
    CardContent,
    Grid,
    InputAdornment,
    MenuItem,
    Select,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from '@mui/material';
import React from 'react';
import { mmToInches } from '../../../../functions/utilFunctions';

const SystemForm = ({ formState, handleInputChange, unitSystem }) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    const sections = [
        { name: 'Mains', key: 'mains' },
        { name: 'Branches', key: 'branches' },
    ];

    const renderField = (field, sectionKey) => {
        const fieldKey = field
            .toLowerCase()
            .replace(/[^a-z0-9]+(.)/g, (_, chr) => chr.toUpperCase());
        const name = `${sectionKey}.${fieldKey}`;
        let value = formState.ductSizing[sectionKey][fieldKey];

        if (unitSystem === 'Imperial' && (field === 'Duct Width' || field === 'Duct Height')) {
            value = mmToInches(value).toFixed(2);
        }

        const commonProps = {
            fullWidth: true,
            name,
            value,
            onChange: handleInputChange,
            size: "small"
        };

        switch (field) {
            case 'Materials':
                return (
                    <Select {...commonProps}>
                        <MenuItem value={value}>Galvanised Steel</MenuItem>
                    </Select>
                );

            case 'Duct Shape':
                return (
                    <Select {...commonProps}>
                        <MenuItem value="Rectangular">Rectangular</MenuItem>
                    </Select>
                );

            default:
                return (
                    <TextField
                        {...commonProps}
                        type={['Flow Rate', 'Duct Width', 'Duct Height'].includes(field) ? 'number' : 'text'}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                {field === 'Flow Rate' ? (unitSystem === 'Metric' ? <span>m<sup>3</sup>/s</span> : "cfm") :
                                    (field.includes('Duct') ? (unitSystem === 'Metric' ? 'mm' : 'in') : '')}
                            </InputAdornment>,
                        }}
                    />
                );
        }
    };

    return (
        <Card sx={{ width: '100%', mb: 2 }}>
            <CardContent>
                <Typography
                    variant="h6"
                    gutterBottom
                    sx={{
                        color: 'white',
                        bgcolor: '#8091f2',
                        display: 'inline-block',
                        padding: '5px 10px',
                        borderRadius: '4px',
                        mb: 2
                    }}
                >
                    Mechanical
                </Typography>

                <Typography variant="h6" sx={{ color: '#1976d2', mb: 1, fontSize: '1.25rem' }}>
                    General
                </Typography>

                <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            name={formState.general.systemName}
                            label="System Name"
                            value={formState.general.systemName}
                            onChange={handleInputChange}
                            size="small"
                            sx={{ mb: 1 }}
                        />
                    </Grid>
                </Grid>

                <Typography variant="h6" sx={{ color: '#1976d2', my: 1, fontSize: '1.25rem' }}>
                    Duct Sizing
                </Typography>

                <Grid container spacing={1}>
                    {sections.map((section) => (
                        <Grid item xs={12} md={6} key={section.key}>
                            <Box sx={{ border: '1px solid #ddd', borderRadius: 2, p: 1, mb: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: '#1976d2', mb: 1, fontSize: '1rem' }}>
                                    {section.name}
                                </Typography>

                                <Grid container spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="body2">Flow Rate</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        {renderField('Flow Rate', section.key)}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="body2">Materials</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        {renderField('Materials', section.key)}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="body2">Duct Shape</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        {renderField('Duct Shape', section.key)}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="body2">Duct Width</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        {renderField('Duct Width', section.key)}
                                    </Grid>
                                </Grid>

                                <Grid container spacing={1} alignItems="center" sx={{ mt: 0.5 }}>
                                    <Grid item xs={6} sm={6}>
                                        <Typography variant="body2">Duct Height</Typography>
                                    </Grid>
                                    <Grid item xs={6} sm={6}>
                                        {renderField('Duct Height', section.key)}
                                    </Grid>
                                </Grid>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default SystemForm;
