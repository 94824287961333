import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  Grid,
  IconButton,
  TextField,
  Typography
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import bgImage from "../resources/images/background.jpg";
// import { saveAllProject } from "../actions/app";

const WaitList = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [snackbar, setSnackbar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [organization, setOrganization] = useState("");
  const [phone, setPhone] = useState("");
  const [designation, setDesignation] = useState("");
  const [validEmailError, setValidEmailError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const isSignInDisabled =
    !name ||
    !email ||
    !organization ||
    !phone ||
    !designation ||
    validEmailError;

  const validateEmail = (event) => {
    setEmail(event.target.value);
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value)) {
      setValidEmailError(true);
      setErrorMessage("Invalid Email Address");
    } else {
      setValidEmailError(false);
      setErrorMessage("");
    }
  };

  const handleClose = () => {
    setSnackbar(false);
    dispatch(
      //   saveAllProject({ load: true, message: "Redirecting to home page" })
    );
    setTimeout(() => {
      //   dispatch(saveAllProject({ load: false, message: "" }));
      history.push("/");
    }, 2000);
  };

  const handleSubmit = async (e) => {
    setIsLoading(true);
    const formElement = document.querySelector("form");
    e.preventDefault();
    const formData = new FormData(formElement);
    await fetch(
      "https://script.google.com/macros/s/AKfycbwgURZC819nmiQ30NRbmTUHnoSjRTtTyQ2Y5KvbQ9bs90v2sbNFb4WstKzvAQmSbS4zUA/exec",
      {
        method: "POST",
        body: formData,
      }
    )
      .then((res) => res.text())
      .then(() => {
        setIsLoading(false);
        setSnackbar(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <Grid container style={{ height: "92vh" }}>
      <Grid
        item
        xs={12}
        md={6}
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          clipPath: "polygon(0 0, 75% 0, 100% 100%, 0% 100%)",
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            width: "80%", // Default width for small devices
            "@media (min-width:600px)": {
              width: "50%", // Width for medium devices and larger
            },
          }}
        >
          <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
            Join the Waitlist
          </Typography>
          <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
            Be the first to know when we launch beta program for early access.
          </Typography>
          <form className="form" onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              name="name"
              label="Name"
              autoComplete="name"
              autoFocus
              value={name}
              sx={{ backgroundColor: "transparent" }}
              onChange={(event) => setName(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="email"
              id="email"
              label="Email"
              autoComplete="email"
              value={email}
              sx={{ backgroundColor: "transparent" }}
              onChange={validateEmail}
              error={validEmailError}
              helperText={validEmailError ? errorMessage : ""}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="phone"
              id="phone"
              label="Phone"
              value={phone}
              sx={{ backgroundColor: "transparent" }}
              onChange={(event) => setPhone(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="organization"
              id="organization"
              label="Organization"
              value={organization}
              sx={{ backgroundColor: "transparent" }}
              onChange={(event) => setOrganization(event.target.value)}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="designation"
              id="designation"
              label="Designation"
              value={designation}
              sx={{ backgroundColor: "transparent" }}
              onChange={(event) => setDesignation(event.target.value)}
            />
            {isLoading ? (
              <Box display="flex" justifyContent="center">
                <CircularProgress color="success" />
              </Box>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="active"
                disabled={isSignInDisabled}
                sx={{ mt: 4 }}
              >
                Join
              </Button>
            )}
          </form>
        </Box>
      </Grid>
      <Dialog
        fullWidth={true}
        maxWidth='md'
        open={snackbar}
        onClose={handleClose}

      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          style={{ position: 'absolute', top: "2px", right: "15px" }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent sx={{ textAlign: "center" }}>
          <CheckCircleOutlineIcon sx={{ fontSize: { xs: "4rem", md: "5rem" }, mt: 1, color: "#4EBFB9" }} />
          <Typography sx={{ fontSize: { xs: "1.8rem", md: "3rem" }, pt: 1, pb: 2, fontWeight: "bold" }}>You are in Queue !</Typography>
          <DialogContentText sx={{ fontWeight: 600, pb: 4, fontSize: { xs: ".9rem", md: "1.2rem" } }}>
            We'll let you know when we launch the beta program.
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default WaitList;
