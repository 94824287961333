import EditOffIcon from '@mui/icons-material/EditOff';
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PanToolIcon from '@mui/icons-material/PanTool';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { Alert, Box, ButtonGroup, IconButton, Paper, Snackbar, Tooltip, Zoom } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { centerCanvasView, setZoom } from '../../functions/utilFunctions';
import { setDrawingMode } from '../../redux/slice/drawingSlice';

const CanvasBar = ({ canvas, lockPdf, selectedPdf, isPanning, handlePanningToggle }) => {
  const { toggleScale, drawingMode } = useSelector(state => state.drawing);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const dispatch = useDispatch();
  const handleSnackBar = (flag, message) => {
    setSnackBarText(message);
    setSnackBar(flag);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  return (
    <Box sx={{ position: 'absolute', bottom: '5%', left: '44%', display: 'flex', gap: 2 }}>
      <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup>
          <Tooltip title="Fit to Center" placement="top" TransitionComponent={Zoom}>
            <IconButton
              aria-label="Recenter"
              size="large"
              onClick={() => {
                centerCanvasView(canvas);
                handleSnackBar(true, 'Canvas is centered');
              }}
            >
              <FilterCenterFocusIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom in" placement="top" TransitionComponent={Zoom}>
            <IconButton
              aria-label="Zoom In"
              size="large"
              onClick={() => {
                setZoom(canvas, 0.5);
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom out" placement="top" TransitionComponent={Zoom}>
            <IconButton
              aria-label="Zoom Out"
              size="large"
              onClick={() => {
                setZoom(canvas, -0.5);
              }}
            >
              <ZoomOutIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={isPanning ? 'Disable panning mode' : 'Enable panning mode'}
            placement="top"
            TransitionComponent={Zoom}
          >
            <IconButton
              aria-label="Toggle Panning"
              size="large"
              onClick={() => {
                handlePanningToggle();
                handleSnackBar(true, isPanning ? 'Panning mode turned off' : 'Panning mode turned on');
              }}
            >
              <PanToolIcon color={isPanning ? 'primary' : 'default'} />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Paper>

      <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup>
          <Tooltip title="Exit draw mode (Esc)" placement="top" TransitionComponent={Zoom}>
            <IconButton
              id="stopDrawMode"
              aria-label="Exit draw mode"
              size="large"
              onClick={() => {
                dispatch(setDrawingMode(false));
                handleSnackBar(true, 'Drawing mode disabled');
              }}
              sx={{ display: drawingMode ? 'flex' : 'none' }}
            >
              <EditOffIcon />
            </IconButton>
          </Tooltip>
        </ButtonGroup>
      </Paper>

      <Paper elevation={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup>
          {toggleScale ? (
            <Tooltip title="Unlock PDF" placement="top" TransitionComponent={Zoom}>
              <IconButton
                aria-label="Unlock PDF"
                size="large"
                onClick={() => {
                  lockPdf();
                  handleSnackBar(true, 'PDF is unlocked');
                }}
                disabled={selectedPdf === undefined}
              >
                <LockOpenOutlinedIcon />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Lock PDF" placement="top" TransitionComponent={Zoom}>
              <IconButton
                aria-label="Lock PDF"
                size="large"
                onClick={() => {
                  lockPdf();
                  handleSnackBar(true, 'PDF is locked');
                }}
                disabled={selectedPdf === undefined}
              >
                <LockOutlinedIcon />
              </IconButton>
            </Tooltip>
          )}
        </ButtonGroup>
      </Paper>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar}
        autoHideDuration={3000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} variant="filled" sx={{ width: '100%', bgcolor: '#4E5FBF' }}>
          {snackBarText}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CanvasBar;
