import { Box, Button, FormControl, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FaArrowRotateLeft, FaArrowRotateRight } from 'react-icons/fa6';
import { useDispatch, useSelector } from 'react-redux';
import { scalingRatio, standardPaperSizes } from '../../cordinates/distancePoints';
import { setSelectedPdfScale } from '../../redux/slice/drawingSlice';
import { scalePdfStyles } from './PanelStyles/ScalePdfStyles';

const ScalePdf = ({
  rotateLeft,
  rotateRight,
  pdfName,
  onPaperRatioChange,
  onPaperSizeChange,
  selectedDistanceRatio,
  selectedPaperSize,
  calculateScale,
  pdfFiles,
  setPdfFiles,
  loadedPDFs,
  setLoadedPDFs,
  setSelectedPdf,
  projectData,
}) => { 
  const { pointDistance, ductDistance, selectedlevel } = useSelector(state => state.drawing);
  const [isCustomScale, setIsCustomScale] = useState(false);
  const [isStdScale, setIsStdScale] = useState(true);
  const [isUpdateClicked, setIsUpdateClicked] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [selectedScale, setSelectedScale] = useState(
    projectData?.pdfPosition?.pdfScale ? projectData?.pdfPosition?.pdfScale : 1,
  ); //1:100 default

  const dispatch = useDispatch();

  const handleDelete = () => {
    const pdfDetails = loadedPDFs?.find(
      item => item.position.selectedLevel === selectedlevel.name && item.src !== '',
    );
    if (pdfDetails) {
      const filteredloadedPdfs = loadedPDFs?.filter(
        item => item.position.selectedLevel !== selectedlevel.name && item.src !== '',
      );
      const filteredPdf = pdfFiles?.filter(item => item.name !== pdfDetails.position.fileName);
      if (filteredloadedPdfs.length === 0) {
        setLoadedPDFs([
          {
            src: '',
            position: { selectable: false, selectedLevel: selectedlevel?.name },
            locked: true,
          },
        ]);
      } else {
        setLoadedPDFs(filteredloadedPdfs);
      }
      setPdfFiles(filteredPdf);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setSelectedPdf();
  };

  return (
    <Box sx={scalePdfStyles.container}>
      <Box sx={scalePdfStyles.title}>
        <Typography
          variant="h5"
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            maxWidth: 200,
            fontWeight: 'bold',
            whiteSpace: 'nowrap',
          }}
        >
          {pdfName}
        </Typography>
      </Box>
      <Box sx={scalePdfStyles.scaleSection}>
        <Typography
          variant="h5"
          sx={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 200, fontWeight: 'bold', mb: 2 }}
        >
          Scale
        </Typography>
        <Button variant="outlined">Standard Scale</Button>
        <Typography>{ductDistance}-:mm</Typography>
      </Box>
      {isStdScale && (
        <Box sx={{ display: 'flex', gap: 1, mt: 2 }}>
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select size="small" value={selectedDistanceRatio} onChange={onPaperRatioChange}>
              <MenuItem value="">Select Scale</MenuItem>
              {scalingRatio.map(ratio => (
                <MenuItem key={ratio.value} value={ratio.value}>
                  {ratio.scale}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          @
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select size="small" value={selectedPaperSize} onChange={onPaperSizeChange}>
              <MenuItem value="">Select Paper Size</MenuItem>
              {standardPaperSizes.map(size => (
                <MenuItem key={size.name} value={size.width}>
                  {size.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {isCustomScale && (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: 2 }}>
            <Box>
              <Typography>Choose</Typography>
              <Button variant="outlined" color="primary">
                A
              </Button>
            </Box>
            <Box>
              <Typography>Choose</Typography>
              <Button variant="outlined" color="primary">
                B
              </Button>
            </Box>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography>
              Distance mm: <TextField type="number" sx={{ width: 150 }} />
            </Typography>
          </Box>
        </>
      )}
      <Box sx={scalePdfStyles.rotateSection}>
        <Typography variant="h5">Rotate</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Button variant="outlined" color="primary" onClick={rotateLeft}>
            <FaArrowRotateLeft style={{ fontSize: '1.5rem' }} />
          </Button>
          <Button variant="outlined" color="primary" onClick={rotateRight}>
            <FaArrowRotateRight style={{ fontSize: '1.5rem' }} />
          </Button>
        </Box>
      </Box>
      {/* <Button variant="outlined" sx={{ mt: 2 }} onClick={calculateScale}>
        Update Scale
      </Button> */}
      <Box sx={scalePdfStyles.buttonGroup}>
        <Button
          variant="outlined"
          onClick={handleDelete}
          color="error"
          sx={{ bgcolor: 'red', color: 'white' }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default ScalePdf;
