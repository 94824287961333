const linePanelStyles = {
  container: {
    position: 'fixed',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
    width: '300px',
    maxHeight: '50vh',
    backgroundColor: '#fff',
    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    zIndex: 1,
    overflowY: 'auto',
  },
  ductPropsHeader: {
    marginBottom: '15px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#333',
  },
  tabButton: {
    marginRight: '1px',
  },
  deleteButton: {
    marginRight: '5px',
  },
};

export default linePanelStyles;
