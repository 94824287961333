import ArchitectureOutlinedIcon from '@mui/icons-material/ArchitectureOutlined';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Fade,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Tooltip,
} from '@mui/material';
import { fabric } from 'fabric';
import { motion } from 'framer-motion';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { equipmentsOptions } from '../../equipments/equipment';
import { generateUniqueId, paToInWG, stopLineDraw } from '../../functions/utilFunctions';

const mainOptions = [
  { value: 'Ventilation', label: 'Ventilation' },
  { value: 'HeatingCooling', label: 'Heating / cooling' },
  { value: 'WaterFireGas', label: 'water/fire/gas' },
  { value: 'Drainage', label: 'drainage' },
];

const supplyOptions = [
  { value: 'Supply', label: 'Supply' },
  { value: 'Extract', label: 'Extract' },
  { value: 'Intake', label: 'Intake' },
  { value: 'Exhaust', label: 'Exhaust' },
  { value: 'DirtyExhaust', label: 'Dirty Exhaust' },
];

const Dropdown = ({ projectData, canvas, equipments, setEquipments, setUnsavedChanges }) => {
  const { selectedlevel, toggleScale, isPdfPresent } = useSelector(state => state.drawing);
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [selectedMainOption, setSelectedMainOption] = useState(null);
  const [selectedSupplyOption, setSelectedSupplyOption] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const [selectedAHU, setSelectedAHU] = useState(null);
  const [selectedDiffuser, setSelectedDiffuser] = useState(null);
  const [selectedElbow, setSelectedElbow] = useState(null);

  const unitSystem = projectData?.projectCreation?.[0]?.projectInformation?.unitSystem ?? 'Imperial';

  // New state to track if AHU is added
  const [isAHUAdded, setIsAHUAdded] = useState(false);

  const handleClose = () => {
    setSelectedAHU(null);
    setSelectedDiffuser(null);
    setSelectedElbow(null);
    setDialogOpen(false);
  };

  const handleMainChange = selectedOption => {
    setSelectedMainOption(selectedOption);
    setSelectedSupplyOption(null);
    setSelectedAHU(null);
    setSelectedDiffuser(null);
    setSelectedElbow(null);
  };

  const handleSupplyChange = selectedOption => {
    setSelectedSupplyOption(selectedOption);
    setSelectedAHU(null);
    setSelectedDiffuser(null);
    setSelectedElbow(null);
  };

  const handleSnackBar = (flag, message) => {
    setSnackBar(flag);
    setSnackBarText(message);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  const addEquipmentsToCanvas = () => {
    const canvasCenter = {
      left: canvas?.width / 2,
      top: canvas?.height / 2,
    };
    stopLineDraw(canvas);

    const equipmentsToAdd = [];
    if (selectedAHU) equipmentsToAdd.push(selectedAHU);
    if (selectedDiffuser) equipmentsToAdd.push(selectedDiffuser);
    if (selectedElbow) equipmentsToAdd.push(selectedElbow);

    const existingEquipmentsCount = equipments.length;

    // Create an array of letters from B to Z for diffusers
    let diffuserNames = Array.from({ length: 25 }, (_, i) => `Diffuser_${String.fromCharCode(66 + i)}`);

    const getRandomDiffuserName = () => {
      const randomIndex = Math.floor(Math.random() * diffuserNames.length);
      const name = diffuserNames[randomIndex];
      diffuserNames = diffuserNames.filter((_, index) => index !== randomIndex); // Remove the selected name
      return name;
    };

    equipmentsToAdd.forEach((selectedOption, index) => {
      const id = generateUniqueId();
      const equipmentType = getEquipmentType(selectedOption.value);

      let copiedOption = {
        ...selectedOption,
        selectedLevel: selectedlevel?.name,
        id,
        position: {
          left: canvasCenter.left,
          top: canvasCenter.top,
        },
        rotation: 0,
        equipmentType,
        originX: 'center',
        originY: 'center',
        opacity: 0,
      };

      if (selectedOption.value === 'Diffuser') {
        copiedOption = {
          ...copiedOption,
          pressureDrop: unitSystem === 'Imperial' ? paToInWG(50) : 50,
          name: getRandomDiffuserName(),
        };
      } else if (selectedOption.value === 'AHU') {
        copiedOption = {
          ...copiedOption,
          name: 'AHU_A',
        };
        // Set AHU added state to true
        setIsAHUAdded(true);
      }

      setEquipments(prevEquipments => [...prevEquipments, copiedOption]);
      setUnsavedChanges(true);

      if (canvas) {
        const newEquip = new fabric.Rect({
          left: canvasCenter.left,
          top: canvasCenter.top,
          fill: 'red',
          width: 50,
          height: 50,
          id,
          equipmentType,
          originX: 'center',
          originY: 'center',
          opacity: 0,
          scaleX: 0,
          scaleY: 0,
          angle: 0,
        });
        canvas.add(newEquip);

        // Animate the addition of the equipment
        fabric.util.animate({
          startValue: 0,
          endValue: 1,
          duration: 1500,
          easing: fabric.util.ease.easeOutElastic,
          onChange: value => {
            const progress = value / 1;
            const bounceProgress = fabric.util.ease.easeOutBounce(progress);
            const elasticProgress = fabric.util.ease.easeOutElastic(progress);

            newEquip.set({
              left: canvasCenter.left,
              top: canvasCenter.top,
              opacity: progress,
              scaleX: bounceProgress,
              scaleY: bounceProgress,
              angle: 360 * (1 - elasticProgress),
            });
            canvas.renderAll();
          },
          onComplete: () => {
            newEquip.setCoords();
          },
        });
      }
    });

    // Reset selects after adding equipments
    setSelectedAHU(null);
    setSelectedDiffuser(null);
    setSelectedElbow(null);

    if (equipmentsToAdd.length > 1) {
      handleSnackBar(true, 'Equipments added');
    } else if (equipmentsToAdd.length === 1) {
      handleSnackBar(true, `${equipmentsToAdd[0].label} added`);
    }

    setTimeout(() => {
      handleSnackBar(false);
    }, 2000);
  };

  const getEquipmentType = value => {
    switch (value) {
      case 'Diffuser':
        return 'Supply Diffuser';
      case 'AHU':
        return '10';
      case 'Elbow':
        return 'Elbow - Generic';
      default:
        return '';
    }
  };

  const handleUndo = useCallback(() => {
    if (canvas && canvas.getObjects().length > 0) {
      const lastObject = canvas.getObjects().pop();
      canvas.remove(lastObject);
      canvas.renderAll();
    }
  }, [canvas]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey && event.key.toLowerCase() === 'z') {
        handleUndo();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleUndo]);

  const renderSelect = (label, value, options, onChange, disabled = false) => (
    <motion.div
      initial={{ opacity: 0, y: -20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      style={{ marginBottom: '10px', width: '48%' }}
    >
      <FormControl sx={{ m: 1, minWidth: 200 }} disabled={disabled}>
        <InputLabel>{label}</InputLabel>
        <Select value={value} onChange={e => onChange(e.target.value)} label={label}>
          {options.map(option => (
            <MenuItem key={option.value} value={option}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </motion.div>
  );

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Tooltip title="Add Equipments" TransitionComponent={Fade} TransitionProps={600}>
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setDialogOpen(true)}
            disabled={!toggleScale || !isPdfPresent}
          >
            <Stack direction="column" alignItems="center">
              <ArchitectureOutlinedIcon />
              <div>Tools</div>
            </Stack>
          </Button>
        </Tooltip>
        <Dialog open={dialogOpen} onClose={handleClose}>
          <DialogTitle color="#4E5FBF">Tools</DialogTitle>
          <Divider />
          <DialogContentText sx={{ padding: '20px' }}>
            Select a variety of tools for your application.
          </DialogContentText>
          <DialogContent
            sx={{ padding: '20px', display: 'flex', flexDirection: 'row', gap: 2, flexWrap: 'wrap' }}
          >
            {renderSelect('MainOpt', selectedMainOption, mainOptions, handleMainChange)}
            {renderSelect(
              'Type',
              selectedSupplyOption,
              supplyOptions.filter(opt => opt.value === 'Supply'), // Only show "Supply"
              handleSupplyChange,
              !selectedMainOption,
            )}
            {selectedSupplyOption && (
              <>
                {renderSelect(
                  'AHU',
                  selectedAHU,
                  equipmentsOptions.filter(opt => opt.value === 'AHU'),
                  setSelectedAHU,
                  isAHUAdded, // Disable if AHU is already added
                )}
                {renderSelect(
                  'Diffuser',
                  selectedDiffuser,
                  equipmentsOptions.filter(opt => opt.value === 'Diffuser'),
                  setSelectedDiffuser,
                )}
                {renderSelect(
                  'Elbow',
                  selectedElbow,
                  equipmentsOptions.filter(opt => opt.value === 'Elbow'),
                  setSelectedElbow,
                )}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
              <Button
                variant="outlined"
                onClick={() => (addEquipmentsToCanvas(), setDialogOpen(false))}
                disabled={!selectedAHU && !selectedDiffuser && !selectedElbow}
              >
                ADD
              </Button>
              <Button onClick={handleClose} sx={{ color: 'black', bgcolor: 'white', ml: 2 }}>
                CANCEL
              </Button>
            </Box>
          </DialogActions>
        </Dialog>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar}
        autoHideDuration={2000}
        onClose={handleCloseSnackBar}
      >
        <Alert onClose={handleCloseSnackBar} variant="filled" sx={{ width: '100%', bgcolor: '#4E5FBF' }}>
          {snackBarText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Dropdown;
