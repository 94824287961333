import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import teePanelstyles from '../PanelStyles/TeePanelStyles';

const TeePanel = ({ closeTeePanel, selectedTee, setTees, tees }) => {
  const [fittingType, setFittingType] = useState(selectedTee?.fittingType);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFittingType(selectedTee?.fittingType);
  }, [selectedTee]);

  const handleDelete = () => {
    if (selectedTee?.id) {
      const filteredItems = tees?.filter(item => item.id !== selectedTee.id);
      setTees(filteredItems);
      closeTeePanel();
    }
  };

  const handleFittingType = e => {
    const updatedTees = tees.map(tee => {
      const updatedTee = fabric.util.object.clone(tee);
      if (tee.id === selectedTee?.id) {
        updatedTee.fittingType = e.target.value;
        setFittingType(e.target.value);
        return updatedTee;
      }
      return updatedTee;
    });
    setTees(updatedTees);
  };

  return (
    <Box sx={teePanelstyles.container}>
      <Typography variant="h6">{fittingType} Fitting</Typography>

      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="fitting-type">Fitting type</InputLabel>
          <Select
            labelId="fitting-type"
            id="fittingType"
            label="Fitting type"
            value={fittingType}
            onChange={handleFittingType}
          >
            <MenuItem value="Tee">Tee</MenuItem>
            <MenuItem value="Wye">Wye</MenuItem>
          </Select>
        </FormControl>
      </Box>

      {/* <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="flowSystem-label">Flow System</InputLabel>
          <Select labelId="flowSystem-label" id="flowSystem" label="Flow System" size="small">
            <MenuItem value="Supply">Supply</MenuItem>
            <MenuItem value="Extract">Extract</MenuItem>
            <MenuItem value="Intake">Intake</MenuItem>
            <MenuItem value="Exhaust">Exhaust</MenuItem>
            <MenuItem value="Dirty Exhaust">Dirty Exhaust</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      {/* <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="cElbowType-label">Circular Elbow Type</InputLabel>
          <Select labelId="cElbowType-label" id="cElbowType" label="Circular Elbow Type" size="small">
            <MenuItem value="Square">Square</MenuItem>
            <MenuItem value="MultiPiece">Multi Piece</MenuItem>
            <MenuItem value="Smooth">Smooth</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="rElbowType-label">Rectangular Elbow Type</InputLabel>
          <Select labelId="rElbowType-label" id="rElbowType" label="Rectangular Elbow Type" size="small">
            <MenuItem value="Square">Square</MenuItem>
            <MenuItem value="SquareWithVanes">Square with Vanes</MenuItem>
            <MenuItem value="Smooth">Smooth</MenuItem>
            <MenuItem value="SmoothWithVanes">Smooth With Vanes</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      {/* <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="rectToRect-label">Tee Type - Main to Sub Branch</InputLabel>
          <Select labelId="rectToRect-label" id="rectToRect" label="Tee Type - Main to Sub Branch">
            <MenuItem value="Square">Rectangular</MenuItem>
            <MenuItem value="Shoe">Shoe</MenuItem>
            <MenuItem value="Bell">Bell</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="angle-label">Generated Transition Angle</InputLabel>
          <Select labelId="angle-label" id="angle" label="Generated Transition Angle">
            <MenuItem value="15">15°</MenuItem>
            <MenuItem value="30">30°</MenuItem>
            <MenuItem value="45">45°</MenuItem>
            <MenuItem value="60">60°</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      {/* <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="symRectangular-label">Symmetrical Tee - Rectangular</InputLabel>
          <Select
            labelId="symRectangular-label"
            id="symRectangular"
            label="Symmetrical Tee - Rectangular"
            size="small"
          >
            <MenuItem value="Square">Square</MenuItem>
          </Select>
        </FormControl>
      </Box>

      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="symCircular-label">Symmetrical Tee - Circular</InputLabel>
          <Select
            labelId="symCircular-label"
            id="symCircular"
            label="Symmetrical Tee - Circular"
            size="small"
          >
            <MenuItem value="Square">Square</MenuItem>
            <MenuItem value="YBreech">Y Breech</MenuItem>
            <MenuItem value="YPiece">Y Piece</MenuItem>
          </Select>
        </FormControl>
      </Box> */}

      <Box sx={teePanelstyles.buttonGroup}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDelete}
          sx={{ bgcolor: 'red', color: 'white' }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={closeTeePanel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TeePanel;
