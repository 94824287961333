import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ahuPanelStyles from '../PanelStyles/AhuPanelStyles';

const AhuPanel = ({
  selectedEquipment,
  setSelectedEquipment,
  setEquipments,
  equipments,
}) => {
  const [rotation, setRotation] = useState(selectedEquipment.rotation);
  const [isRightToLeft, setIsRightToLeft] = useState(false);
  const [showAdditionalProperties, setShowAdditionalProperties] = useState(false);
  const [shape, setShape] = useState('');
  const [isOpen, setIsOpen] = useState(true);
  const [dimensions, setDimensions] = useState({
    depth: '',
    width: '',
    height: '',
  });

  useEffect(() => {
    setRotation(selectedEquipment.rotation);
  }, [selectedEquipment.rotation]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = () => {
    if (selectedEquipment?.id) {
      const filteredItems = equipments.filter(item => item.id !== selectedEquipment.id);
      setEquipments(filteredItems);
    }
    setSelectedEquipment(null);
  };

  const handleRotationChange = value => {
    const optionIndex = equipments.findIndex(opt => opt.id === selectedEquipment?.id);
    if (optionIndex !== -1) {
      let newRotation = rotation + value;
      newRotation = Math.max(0, Math.min(180, newRotation));
      const updatedSelectedOptions = [...equipments];
      updatedSelectedOptions[optionIndex] = {
        ...updatedSelectedOptions[optionIndex],
        rotation: newRotation,
      };
      setRotation(newRotation);
      setEquipments(updatedSelectedOptions);
    }
  };

  const toggleRightToLeft = () => {
    setIsRightToLeft(!isRightToLeft);
  };

  const toggleAdditionalProperties = () => {
    setShowAdditionalProperties(!showAdditionalProperties);
  };

  const handleDimensionChange = (key, value) => {
    setDimensions({
      ...dimensions,
      [key]: value,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Container sx={ahuPanelStyles.container}>
      <Box sx={ahuPanelStyles.title}>
        <Typography variant="h6">AHU (Air handled unit)</Typography>
      </Box>
      <Box sx={ahuPanelStyles.rotationSection}>
        <FormControl fullWidth>
          <Typography sx={{ marginBottom: 1 }}>Rotation</Typography>
          <Box sx={ahuPanelStyles.rotationControls}>
            <Button
              onClick={() => handleRotationChange(-45)}
              variant="outlined"
              sx={ahuPanelStyles.rotationButton}
            >
              <RotateLeftIcon />
            </Button>
            <TextField
              value={rotation}
              onChange={() => { }}
              variant="outlined"
              size="small"
              sx={{ width: 80, textAlign: 'center', mx: 1 }}
            />
            <Button
              onClick={() => handleRotationChange(45)}
              variant="outlined"
              sx={ahuPanelStyles.rotationButton}
            >
              <RotateRightIcon />
            </Button>
          </Box>
        </FormControl>
      </Box>
      {/* <Box sx={ahuPanelStyles.rotationSection}>
        <FormControl sx={ahuPanelStyles.rightToLeftSelect}>
          <InputLabel>Right to Left?</InputLabel>
          <Select
            size="small"
            value={isRightToLeft ? 'yes' : 'no'}
            onChange={toggleRightToLeft}
            variant="outlined"
            label="Right to Left?"
          >
            <MenuItem value="yes">Yes</MenuItem>
            <MenuItem value="no">No</MenuItem>
          </Select>
        </FormControl>
      </Box> */}
      <Box sx={ahuPanelStyles.rotationSection}>
        <Button onClick={toggleAdditionalProperties} variant="outlined" sx={ahuPanelStyles.propertiesButton}>
          {showAdditionalProperties ? 'Hide Properties' : 'Show Properties'}
        </Button>
        {showAdditionalProperties && (
          <Box sx={ahuPanelStyles.additionalPropertiesBox}>
            <Box sx={ahuPanelStyles.propertiesDivider} />
            <FormControl fullWidth sx={ahuPanelStyles.shapeSelect}>
              <InputLabel>Shape</InputLabel>
              <Select value={shape} onChange={e => setShape(e.target.value)} variant="outlined" label="Shape">
                <MenuItem value="">Select Shape</MenuItem>
                <MenuItem value="rectangular">Rectangular</MenuItem>
                <MenuItem value="cylindrical">Cylindrical</MenuItem>
              </Select>
            </FormControl>
            <Box sx={ahuPanelStyles.dimensionInput}>
              <TextField
                type="number"
                value={dimensions.depth}
                onChange={e => handleDimensionChange('depth', e.target.value)}
                variant="outlined"
                size="small"
                label="Depth (in)"
              />
            </Box>
            <Box sx={ahuPanelStyles.dimensionInput}>
              <TextField
                type="number"
                value={dimensions.width}
                onChange={e => handleDimensionChange('width', e.target.value)}
                variant="outlined"
                size="small"
                label="Width (in)"
              />
            </Box>
            <Box sx={ahuPanelStyles.dimensionInput}>
              <TextField
                type="number"
                value={dimensions.height}
                onChange={e => handleDimensionChange('height', e.target.value)}
                variant="outlined"
                size="small"
                label="Height (in)"
              />
            </Box>
          </Box>
        )}
      </Box>
      <Box sx={ahuPanelStyles.buttonsContainer}>
        <Button onClick={handleDelete} variant="outlined" color='error' sx={{ mr: 1, bgcolor: "red", color: "white" }}>
          Delete
        </Button>
        <Button onClick={handleClose} variant="outlined">
          Cancel
        </Button>
      </Box>
    </Container>
  );
};

export default AhuPanel;
