import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Logo } from '../images/commonSvg';
// import { fetchUserDetails } from "../actions/app";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { withStyles } from '@mui/styles';
import { confirmUser, loginUser, resendCode } from '../services/authService';

const styles = () => ({});

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      isLoading: false,
      message: '',
      invalidCred: false,
      showPassword: false,
      verificationCodeDialog: false,
      verificationCode: '',
      resentCode: false,
      resentCodeMessage: '',
      invalidCode: false,
      invalidCodeMessage: '',
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleClickShowPassword = () => {
    this.setState({ showPassword: !this.state.showPassword });
  };

  handleMouseDownPassword = event => {
    event.preventDefault();
  };

  componentDidMount() {
    if (localStorage.getItem('token')) {
      localStorage.removeItem('token');
      window.location.reload();
    }
  }

  goTosignup = () => {
    this.props.history.push('/signup');
  };
  goToWaitlist = () => {
    this.props.history.push('/waitlist');
  };
  goToForgetPassword = () => {
    this.props.history.push('/forget-password');
  };

  handleVerificationCode(event) {
    this.setState({ verificationCode: event.target.value });
  }

  async handleSubmit(event) {
    event.preventDefault();
    this.setState({ isLoading: true });
    const response = await loginUser({
      email: this.state.email,
      password: this.state.password,
    });
    if (response.status === 401) {
      this.setState({
        isLoading: false,
        invalidCred: true,
        message: response.message,
      });
      if (response.message === 'User not confirmed') {
        this.setState({ verificationCodeDialog: true });
      }
    } else {
      this.setState({ isLoading: false });
      localStorage.setItem('token', response.data.idToken);
      localStorage.setItem('email', this.state.email);
      localStorage.setItem('refreshToken', response.data.refreshToken);
      //   await this.props.fetchUserDetails();
      this.props.history.push('/dashboard');
    }
  }

  partiallyHideEmail(email) {
    const [username, domain] = email.split('@');
    const maskedUsername = username.replace(/./g, '*');
    const visibleUsername = username[0] + maskedUsername.slice(1);
    return visibleUsername + '@' + domain;
  }

  async submitVerificationCode(event, email, verificationCode) {
    event.preventDefault();
    const response = await confirmUser({
      email: email,
      verificationCode: verificationCode,
    });
    if (response.status === 400) {
      this.setState({ invalidCode: true });
      this.setState({ invalidCodeMessage: response.message });
    } else {
      this.setState({ verificationCodeDialog: false });
      this.setState({ invalidCred: false });
      this.props.history.push('/login');
    }
  }
  async resendCode(event, email) {
    event.preventDefault();
    const response = await resendCode({
      email: email,
    });
    this.setState({ invalidCode: false });
    if (response.status === 200) {
      this.setState({ resentCode: true });
      this.setState({ resentCodeMessage: response.message });
    } else {
      this.setState({ resentCode: true });
      this.setState({ resentCodeMessage: 'Something went wrong' });
    }
  }

  render() {
    const { theme } = this.props;
    const isSignInDisabled = this.state.email.length === 0 || this.state.password.length === 0;

    return (
      <Grid
        container
        style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        {/* <Grid
          item
          xs={12}
          md={6}
          style={{
            // backgroundImage: `url(${bgImage})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            clipPath: 'polygon(0 0, 75% 0, 100% 100%, 0% 100%)',
          }}
        ></Grid> */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Logo />
          <Box
            sx={{
              mt: 4,
              width: '80%', // Default width for small devices
              '@media (min-width:600px)': {
                width: '50%', // Width for medium devices and larger
              },
            }}
          >
            <Typography variant="h4" gutterBottom>
              Sign in
            </Typography>
            <form onSubmit={this.handleSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{
                  backgroundColor: 'transparent',
                }}
                InputLabelProps={{ shrink: true }}
                value={this.state.email}
                onChange={event => this.setState({ email: event.target.value })}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={this.state.showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                value={this.state.password}
                sx={{ backgroundColor: 'transparent' }}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      <IconButton
                        onClick={this.handleClickShowPassword}
                        onMouseDown={this.handleMouseDownPassword}
                        data-testid="change-password-visibilty"
                      >
                        {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                onChange={event => {
                  this.setState({
                    invalidCred: false,
                    password: event.target.value,
                  });
                }}
              />
              {this.state.invalidCred ? (
                <Box
                  sx={{
                    fontWeight: 'bold',
                    color: 'black',
                  }}
                >
                  {this.state.message}
                </Box>
              ) : null}
              {/* <Box
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Typography
                  variant="caption"
                  gutterBottom
                  onClick={this.goToForgetPassword}
                  sx={{
                    cursor: 'pointer',
                    transition: 'color 0.3s ease',
                    margin: '5px 0px 15px',
                    '&:hover': {
                      color: 'black',
                    },
                    fontSize: '14px',
                  }}
                >
                  Forget Password?
                </Typography>
              </Box> */}
              {this.state.isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress color="success" />
                </Box>
              ) : (
                <Button
                  type="submit"
                  sx={{
                    '&:hover': {
                      backgroundColor: '#4e5fbf',
                      color: 'whitesmoke'
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  disabled={this.state.isLoading || isSignInDisabled}
                >
                  Login
                </Button>
              )}
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="caption"
                  onClick={this.goTosignup}
                  sx={{
                    cursor: 'pointer',
                    color: theme.palette.links.main,
                    transition: 'color 0.3s ease',
                    margin: '15px 0px',
                    fontSize: '14px',
                  }}
                >
                  Create new account
                </Typography>
              </Box>
            </form>
          </Box>
        </Grid>
        <Dialog maxWidth="sm" fullWidth={true} open={this.state.verificationCodeDialog}>
          <DialogTitle>Verify your account</DialogTitle>
          <DialogContent dividers>
            <Typography>
              Please enter the verification code sent to your email{' '}
              {this.partiallyHideEmail(this.state.email)}
            </Typography>
            {this.state.resentCode && (
              <Typography sx={{ color: 'black' }}>{this.state.resentCodeMessage}</Typography>
            )}
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="verification-code"
              label="Verification Code"
              value={this.state.verificationCode}
              onChange={event => this.handleVerificationCode(event)}
              error={this.state.invalidCode}
              helperText={this.state.invalidCode ? this.state.invalidCodeMessage : ''}
            />
          </DialogContent>
          <DialogActions>
            <Button
              variant="outlined"
              sx={{ mx: 2, my: 1 }}
              onClick={event => this.resendCode(event, this.state.email)}
            >
              Resend code
            </Button>
            <Button
              variant="contained"
              sx={{ mx: 2, my: 1 }}
              onClick={event =>
                this.submitVerificationCode(event, this.state.email, this.state.verificationCode)
              }
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
    // userDetails: state.user.userDetails,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    // fetchUserDetails: () => dispatch(fetchUserDetails()),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(withStyles(styles, { withTheme: true })(LoginPage)));
