// WorkflowForm.jsx

import CheckIcon from '@mui/icons-material/Check';
import { Button, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import React from 'react';

const WorkflowForm = ({ formState, handleWorkflowChange }) => {
    const workflowCategories = [
        {
            name: "Mechanical",
            workflows: ["Ventilation"]
        },
    ];

    return (
        <Card sx={{ mb: 3, width: "100%" }}>
            <CardContent>
                <Typography
                    variant="h5"
                    gutterBottom
                    sx={{ color: '#4E5FBF', marginBottom: 2 }}
                >
                    Workflows
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                    {workflowCategories.map((category, index) => (

                        <Grid item xs={12} key={index}>
                            <Typography variant="h6" gutterBottom
                                sx={{
                                    color: 'white',
                                    bgcolor: '#8091f2',
                                    display: 'inline-block',
                                    padding: '5px 10px',
                                    borderRadius: '4px',
                                    mb: 3
                                }}>
                                {category.name}
                            </Typography>
                            <Grid container spacing={2}>
                                {category.workflows.map((workflow, wIndex) => (
                                    <Grid item key={wIndex}>
                                        <Button
                                            variant={formState.workflows.includes(workflow) ? "contained" : "outlined"}
                                            onClick={() => handleWorkflowChange(workflow)}
                                            startIcon={formState.workflows.includes(workflow) ? <CheckIcon /> : null}
                                            disabled={formState.workflows.includes(workflow)}
                                            sx={{
                                                textTransform: 'none',
                                                height: 90,
                                                width: 180,
                                                borderRadius: 8,
                                                fontWeight: 600,
                                                fontSize: '1rem',
                                                backgroundColor: formState.workflows.includes(workflow) ? '#4E5FBF' : '#FFFFFF',
                                                color: formState.workflows.includes(workflow) ? '#FFFFFF' : '#4E5FBF',
                                                '&:hover': {
                                                    backgroundColor: formState.workflows.includes(workflow) ? '#3C4D9F' : '#ECEFFB',
                                                },
                                            }}
                                        >
                                            {workflow}
                                        </Button>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default WorkflowForm;
