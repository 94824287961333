const API_HOST = process.env.REACT_APP_API_ENDPOINT;

const options = {
  credentials: 'omit',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
  },
};

const loginUser = async data => {
  return await fetch(`${API_HOST}/users/login`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const tokenRefresh = async data => {
  return await fetch(`${API_HOST}/users/refresh-token`, {
    ...options,
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${data.token}`,
    },
  }).then(response => response.json());
};

const signUpUser = async data => {
  return await fetch(`${API_HOST}/users/signup`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const forgetPassword = async data => {
  return await fetch(`${API_HOST}/users/forget-password`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const resetPassword = async (data, id, token) => {
  return await fetch(`${API_HOST}/users/reset-password/${id}/${token}`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const confirmUser = async data => {
  return await fetch(`${API_HOST}/users/confirm-user`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const resendCode = async data => {
  return await fetch(`${API_HOST}/users/resend-code`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

const verifyToken = async data => {
  return await fetch(`${API_HOST}/users/verify-token`, {
    ...options,
    body: JSON.stringify(data),
  }).then(response => response.json());
};

export {
  loginUser,
  tokenRefresh,
  signUpUser,
  forgetPassword,
  resetPassword,
  confirmUser,
  resendCode,
  verifyToken,
};
