import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  polyline: false,
  scale: 10,
  drawingMode: false,
  isPdfPresent: false,
  pdfindex: null,
  uploadedPdfIndex: null,
  toggleScale: true, // Pdf lock
  toggleEquipments: false,
  selectedlevel: {
    type: 'level',
    name: 'L1',
    description: '',
    floorHeights: 0,
  },
  levels: [{ type: 'level', name: 'L1', description: '', floorHeights: 0 }],
  pdfWidth: 210,
  pdfHeight: 297,
  pdfScale: 1,
  dragid: '',
  lineColor: '#e35d76ff',
  pointDistance: [],
  ductDistance: 0,
};

export const drawingSlice = createSlice({
  name: 'drawing',
  initialState,
  reducers: {
    setPolyline: (state, action) => {
      state.polyline = action.payload;
    },

    setScale: (state, action) => {
      state.scale = action.payload;
    },
    setDrawingMode: (state, action) => {
      state.drawingMode = action.payload;
    },
    setIsPdfPresent: (state, action) => {
      state.isPdfPresent = action.payload;
    },
    setPdfIndex: (state, action) => {
      state.pdfindex = action.payload;
    },
    setUploadedPdfIndex: (state, action) => {
      state.uploadedPdfIndex = action.payload;
    },
    setSelectedLevel: (state, action) => {
      state.selectedlevel = action.payload;
    },
    showSelectedScale: (state, action) => {
      state.toggleScale = action.payload;
    },
    showSelectedEquipment: (state, action) => {
      state.toggleEquipments = action.payload;
    },
    setSelectedPdfWidth: (state, action) => {
      state.pdfWidth = action.payload;
    },
    setSelectedPdfHeight: (state, action) => {
      state.pdfHeight = action.payload;
    },
    setSelectedPdfScale: (state, action) => {
      state.pdfScale = action.payload;
    },
    setDraggableElement: (state, action) => {
      state.dragid = action.payload;
    },
    setLineColor: (state, action) => {
      state.lineColor = action.payload;
    },
    setPointDistance: (state, action) => {
      const newValue = action.payload;
      state.pointDistance = [...state.pointDistance, newValue];
    },
    setDuctDistance: (state, action) => {
      state.ductDistance = action.payload;
    },
    setLevels: (state, action) => {
      const { type, newItem } = action.payload;
      if (type === 'clear') {
        state.levels = newItem;
      } else {
        state.levels = type === 'level' ? [newItem, ...state.levels] : [...state.levels, newItem];
      }
    },
  },
});

export const {
  setPolyline,
  setScale,
  setDrawingMode,
  setIsPdfPresent,
  setPdfIndex,
  setUploadedPdfIndex,
  setSelectedLevel,
  showSelectedScale,
  setSelectedPdfHeight,
  setSelectedPdfWidth,
  setSelectedPdfScale,
  setDraggableElement,
  setLineColor,
  setPointDistance,
  setDuctDistance,
  showSelectedEquipment,
  setLevels,
} = drawingSlice.actions;

export default drawingSlice.reducer;
