// DiffuserPanel.js

import { Box, Button, Container, Divider, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import diffuserPanelStyles from '../PanelStyles/DiffuserPanelStyles';

const GeneralInfo = ({ selectedEquipment, handleChange }) => (
  <Box sx={{ padding: 2, marginBottom: 2 }}>
    {/* <FormControl fullWidth sx={{ marginBottom: 2 }}>
      <InputLabel>Flow Rate Mode</InputLabel>
      <Select
        value={selectedEquipment?.flowRateMode}
        onChange={e => handleChange('flowRateMode', e.target.value, 'general')}
        label="Flow Rate Mode"
      >
        <MenuItem value="constant">Constant</MenuItem>
        <MenuItem value="variable">Variable</MenuItem>
      </Select>
    </FormControl>
    <TextField
      fullWidth
      label="Air Flow Rate (m³/s)"
      type="number"
      value={selectedEquipment?.airFlowRate}
      onChange={e => handleChange('airFlowRate', e.target.value, 'general')}
      sx={{ marginBottom: 2 }}
    />
    <FormControl fullWidth sx={{ marginBottom: 2 }}>
      <InputLabel>Orientation</InputLabel>
      <Select
        value={selectedEquipment?.orientation}
        onChange={e => handleChange('orientation', e.target.value, 'general')}
        label="Orientation"
      >
        <MenuItem value="horizontal">Horizontal</MenuItem>
        <MenuItem value="cylindrical">Cylindrical</MenuItem>
      </Select>
    </FormControl>
    <FormControl fullWidth sx={{ marginBottom: 2 }}>
      <InputLabel>Shape</InputLabel>
      <Select
        value={selectedEquipment?.shape}
        onChange={e => handleChange('shape', e.target.value, 'general')}
        label="Shape"
      >
        <MenuItem value="rectangular">Rectangular</MenuItem>
        <MenuItem value="circular">Circular</MenuItem>
      </Select>
    </FormControl> */}
    <TextField
      fullWidth
      label="Height (mm)"
      type="number"
      value={selectedEquipment?.height}
      onChange={e => handleChange('height', e.target.value, 'general')}
      sx={{ marginBottom: 2 }}
    />
    <TextField
      fullWidth
      label="Width (mm)"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      value={selectedEquipment?.width}
      onChange={e => handleChange('width', e.target.value, 'general')}
      sx={{ marginBottom: 2 }}
    />
    {/* <TextField
      fullWidth
      label="Height Above Floor (m)"
      type="number"
      value={selectedEquipment?.heightAboveFloor}
      onChange={e => handleChange('heightAboveFloor', e.target.value, 'general')}
      sx={{ marginBottom: 2 }}
    /> */}
  </Box>
);

const TechnicalInfo = ({ technicalInfo, selectedEquipment, handleChange, unitSystem }) => (
  <Box sx={{ padding: 2, marginBottom: 2 }}>
    <TextField
      fullWidth
      label="Pressure Drop"
      type="number"
      value={technicalInfo.pressureDrop}
      onChange={e => handleChange('pressureDrop', parseFloat(e.target.value))}
      sx={{ marginBottom: 2 }}
      InputProps={{
        endAdornment: <Typography variant="caption">{unitSystem === 'Imperial' ? 'iwg' : 'pa'}</Typography>,
      }}
    />
    {/* <TextField
      fullWidth
      label="Friction Loss (Pa)"
      type="number"
      value={selectedEquipment?.frictionLoss}
      onChange={e => handleChange('frictionLoss', e.target.value, 'technical')}
      sx={{ marginBottom: 2 }}
    /> */}
  </Box>
);

const ResultInfo = ({ branchDrop, selectedEquipment, unitSystem }) => (
  <Box sx={{ padding: 2, marginBottom: 2 }}>
    <TextField
      fullWidth
      label="External static pressure for branch"
      type="number"
      InputLabelProps={{
        shrink: true,
      }}
      value={branchDrop[selectedEquipment?.id]?.toFixed(2)}
      sx={{ marginBottom: 2 }}
      InputProps={{
        endAdornment: <Typography variant="caption">{unitSystem === 'Imperial' ? 'iwg' : 'pa'}</Typography>,
      }}
    />
  </Box>
);

const DiffuserPanel = ({
  selectedEquipment,
  setSelectedEquipment,
  setEquipments,
  equipments,
  unitSystem,
}) => {
  const [view, setView] = useState('technical');
  const { branchDrop } = useSelector(state => state.project);
  const [isPanelVisible, setIsPanelVisible] = useState(true);
  const [technicalInfo, setTechnicalInfo] = useState({
    pressureDrop: selectedEquipment.pressureDrop,
    frictionLoss: '',
  });

  useEffect(() => {
    setTechnicalInfo({
      pressureDrop: selectedEquipment.pressureDrop,
      frictionLoss: '',
    });
  }, [selectedEquipment]);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const handleDelete = () => {
    if (selectedEquipment?.id) {
      const filteredItems = equipments.filter(item => item.id !== selectedEquipment.id);
      setEquipments(filteredItems);
    }
    setSelectedEquipment(null);
  };

  const handleChange = (property, newValue) => {
    const updatedEquipments = equipments.map(item => {
      if (item.id === selectedEquipment?.id) {
        return {
          ...item,
          [property]: newValue,
        };
      }
      return item;
    });
    setTechnicalInfo(prevInfo => ({ ...prevInfo, [property]: newValue }));
    setEquipments(updatedEquipments);
  };

  const handleClose = () => {
    setIsPanelVisible(false);
  };

  if (!isPanelVisible) {
    return null;
  }

  return (
    <Container sx={diffuserPanelStyles.container}>
      <Typography variant="h6" sx={diffuserPanelStyles.title}>
        Diffuser Properties
      </Typography>
      <Box sx={{ display: 'flex', marginBottom: 2 }}>
        {/* <Button
          onClick={() => setView('general')}
          variant={view === 'general' ? 'outlined' : 'outlined'}
          sx={diffuserPanelStyles.button}
        >
          General
        </Button> */}
        <Button
          onClick={() => setView('technical')}
          variant={view === 'technical' ? 'outlined' : 'outlined'}
          sx={diffuserPanelStyles.button}
        >
          Technical
        </Button>
        <Button
          onClick={() => setView('result')}
          variant={view === 'result' ? 'outlined' : 'outlined'}
          sx={diffuserPanelStyles.button}
        >
          Result
        </Button>
      </Box>
      <Divider sx={diffuserPanelStyles.divider} />
      <Box sx={{ width: '100%' }}>
        {view === 'general' ? (
          <GeneralInfo
            selectedEquipment={selectedEquipment}
            handleChange={handleChange}
            unitSystem={unitSystem}
          />
        ) : view === 'technical' ? (
          <TechnicalInfo
            technicalInfo={technicalInfo}
            selectedEquipment={selectedEquipment}
            handleChange={handleChange}
            unitSystem={unitSystem}
          />
        ) : (
          <ResultInfo branchDrop={branchDrop} selectedEquipment={selectedEquipment} unitSystem={unitSystem} />
        )}
      </Box>
      <Box sx={diffuserPanelStyles.buttonGroup}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDelete}
          sx={{ bgcolor: 'red', color: 'white' }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
      </Box>
    </Container>
  );
};

export default DiffuserPanel;
