// UnitsForm.jsx

import { Card, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

const UnitsForm = ({ formState, unitSystem, handleInputChange }) => {
  const unitFields = [
    { name: 'length', label: 'Length', valueMetric: 'Metric (mm, m)', valueImperial: 'Imperial (in, ft)' },
    {
      name: 'ventilationFlowRate',
      label: 'Flow Rate',
      valueMetric: (
        <>
          Metric (m<sup>3</sup>/s)
        </>
      ),
      valueImperial: 'Imperial (cfm)',
    },
    {
      name: 'pressureDrop',
      label: 'Pressure Drop',
      valueMetric: 'Metric (pa)',
      valueImperial: 'Imperial (iwg)',
    },
  ];

  return (
    <Card sx={{ width: '100%', mb: 2 }}>
      <CardContent sx={{ p: 2 }}>
        <Typography variant="subtitle1" sx={{ color: '#1976d2', mb: 1 }}>
          Units
        </Typography>
        <Grid container spacing={1} alignItems="center">
          {unitFields.map(field => (
            <React.Fragment key={field.name}>
              <Grid item xs={4}>
                <Typography variant="body2">{field.label}</Typography>
              </Grid>
              <Grid item xs={8}>
                <Select
                  disabled
                  fullWidth
                  size="small"
                  name={field.name}
                  value={unitSystem === 'Imperial' ? field.valueImperial : field.valueMetric}
                  onChange={handleInputChange}
                >
                  {unitSystem === 'Imperial' ? (
                    <MenuItem value={field.valueImperial}>{field.valueImperial}</MenuItem>
                  ) : (
                    <MenuItem value={field.valueMetric}>{field.valueMetric}</MenuItem>
                  )}
                </Select>
              </Grid>
            </React.Fragment>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default UnitsForm;
