import AirIcon from '@mui/icons-material/Air';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ConstructionOutlinedIcon from '@mui/icons-material/ConstructionOutlined';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import EmojiObjectsIcon from '@mui/icons-material/EmojiObjects';
import HvacIcon from '@mui/icons-material/Hvac';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SearchIcon from '@mui/icons-material/Search';
import {
  AppBar,
  Box,
  Button,
  Chip,
  Drawer,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography
} from '@mui/material';
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import React, { useEffect, useState } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import * as XLSX from 'xlsx';
import { logoUrl } from '../../images/commonSvg';
import { getProject } from '../../services/projectService';

ChartJS.register(ArcElement, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title);

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
const drawerWidth = 240;

const Activity = () => {
  const history = useHistory();
  const { projectId } = useParams();
  const { branchDrop, projects } = useSelector(state => state.project);
  const project = projects.find(p => p._id === projectId);
  const projectDates = project ? {
    createdAt: new Date(project.createdAt).toDateString(),
    updatedAt: new Date(project.updatedAt).toTimeString().split('G')[0]
  } : {};


  const [isLoading, setIsLoading] = useState(true);
  const [projectData, setProjectData] = useState(null);
  const [equipmentUsage, setEquipmentUsage] = useState({});
  const [filteredData, setFilteredData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [branchDropData, setBranchDropData] = useState([]);
  const [pressureUnit, setPressureUnit] = useState('iwg');
  const [activeTab, setActiveTab] = useState('designParameter');
  const [hvacDetails, setHvacDetails] = useState([]);
  const [systemConfiguration, setSystemConfiguration] = useState([]);
  const [units, setUnits] = useState([]);
  const [ahuToDiffuserMapping, setAhuToDiffuserMapping] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem('token');
      const response = await getProject(token, projectId);
      if (response && response.projectData) {
        setProjectData(response.projectData);
        const usage = countEquipmentUsage(response.projectData.equipments);
        setEquipmentUsage(usage);
        setFilteredData(Object.entries(usage));
        setBranchDropData(Object.entries(branchDrop));
        setHvacDetails(response.projectData.projectCreation[0].hvacDetails);
        setSystemConfiguration(response.projectData.projectCreation[0].systemConfiguration);
        setUnits(response.projectData.units);

        const ahuNames = response.projectData.equipments
          .filter((equipment) => equipment.value === "AHU")
          .map(ahu => ahu.name);

        const diffuserNames = response.projectData.equipments
          .filter(equipment => equipment.value === 'Diffuser')
          .map(diffuser => diffuser.name);

        const mapping = Object.entries(branchDrop).map(([id, value], index) => {
          const ahuIndex = index % ahuNames.length;
          return {
            name: `${ahuNames[ahuIndex]} --> ${diffuserNames[index]}`,
            value: value
          };
        });
        setAhuToDiffuserMapping(mapping);
      }
      setIsLoading(false);
    };
    fetchData();
  }, [projectId, branchDrop]);

  const projectTitle = projectData?.projectCreation[0]?.projectInformation?.projectName
  const projectDescription = projectData?.projectCreation[0]?.projectInformation?.projectDescription

  const elbowData = projectData?.elbows.map(elbow => ({
    ElbowType: elbow.elbowType,
    AngleOfBend: elbow.bendAngle,
    RadiusOfBend: elbow.bendRadius,
  }));

  const teeData = projectData?.tees.map((tee) => ({
    FittingType: tee.fittingType === 'Tee' ? 'Tee (SR5 - 13)' : tee.fittingType === 'Wye' ? 'Wye (SR5 - 14)' : tee.fittingType
  }));


  const transitionData = projectData?.transitions.map((transition) => ({
    TransitionType: transition.transitionType,
    AngleOfTransition: transition.transitionAngle
  }))


  const countEquipmentUsage = equipments => {
    const countMap = {};
    if (equipments && Array.isArray(equipments)) {
      equipments.forEach(equipment => {
        const key = equipment.label;
        countMap[key] = (countMap[key] || 0) + 1;
      });
    }
    return countMap;
  };

  const handleSearch = event => {
    const value = event.target.value;
    setSearchText(value);
    const filtered = Object.entries(equipmentUsage).filter(([key]) =>
      key.toLowerCase().includes(value.toLowerCase()),
    );
    setFilteredData(filtered);
  };

  const handleExcelDownload = () => {
    const equipmentData = filteredData.map(([key, count]) => ({
      Equipment: key,
      Usage: count,
    }));

    const branchDropSheet = ahuToDiffuserMapping.map((item) => ({
      Branches: item.name,
      'Pressure Drop': `${convertPressure(item.value)} ${pressureUnit}`,
    }));

    const hvacDetailsSheet = Object.entries(hvacDetails).map(([key, value]) => ({
      Parameter: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
      Value: value,
    }));

    const systemConfigSheet = [
      { Parameter: 'Pressure Drop', Value: `${systemConfiguration.maxPressureDropPsiPer100Ft}` },
      { Parameter: 'Settings For', Value: systemConfiguration.settingsFor },
      { Parameter: 'Material', Value: systemConfiguration.components.branches[0].material },
    ];

    const unitsSheet = [
      { Unit: 'Length', Metric: units.length.metric, Imperial: units.length.imperial },
      { Unit: 'Pressure Drop', Metric: units.pressureDrop.metric, Imperial: units.pressureDrop.imperial },
      { Unit: 'Flow Rate', Metric: units.ventilationFlowRate.metric, Imperial: flowRateUnit },
    ];

    const wb = XLSX.utils.book_new();
    const ws1 = XLSX.utils.json_to_sheet(equipmentData);
    const ws2 = XLSX.utils.json_to_sheet(branchDropSheet);
    const ws3 = XLSX.utils.json_to_sheet(hvacDetailsSheet);
    const ws4 = XLSX.utils.json_to_sheet(systemConfigSheet);
    const ws5 = XLSX.utils.json_to_sheet(unitsSheet);

    XLSX.utils.book_append_sheet(wb, ws1, 'Equipment Usage');
    XLSX.utils.book_append_sheet(wb, ws2, 'Pressure Drop');
    XLSX.utils.book_append_sheet(wb, ws3, 'HVAC Details');
    XLSX.utils.book_append_sheet(wb, ws4, 'System Configuration');
    XLSX.utils.book_append_sheet(wb, ws5, 'Units');

    XLSX.writeFile(wb, `${projectTitle}.xlsx`);
  };

  const handlePdfDownload = () => {
    const pdf = new jsPDF();

    const addLogo = () => {
      const logoData = logoUrl
      const logoWidth = 40;
      const logoHeight = 20;
      const pageWidth = pdf.internal.pageSize.width;
      pdf.addImage(logoData, 'PNG', pageWidth - logoWidth - 10, 2, logoWidth, logoHeight);
    };

    addLogo()
    const addTableToPDF = (data, title, headers, startY, fontSize = 10) => {
      pdf.setFontSize(14);
      pdf.setFont(undefined, 'bold');
      pdf.text(title, 14, startY);
      pdf.setFont(undefined, 'normal');

      autoTable(pdf, {
        head: [headers],
        body: data.map(Object.values),
        startY: startY + 8,
        styles: { fontSize: fontSize, cellPadding: 3 },
        headStyles: { fillColor: [200, 200, 200], textColor: 20, fontStyle: 'bold' },
        bodyStyles: { textColor: 20 },
        alternateRowStyles: { fillColor: [245, 245, 245] },
        margin: { top: 8, right: 8, bottom: 8, left: 8 },
        tableWidth: 'auto',
      });

      return pdf.lastAutoTable.finalY;
    };

    pdf.setFontSize(18);
    pdf.setFont(undefined, 'bold');
    pdf.text(`Project Name: ${projectTitle}`, 14, 15);
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'normal');
    pdf.text(`Project Description : ${projectDescription}`, 14, 25);
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'normal');
    pdf.text(`Project Created At : ${projectDates.createdAt}`, 120, 27);
    pdf.setFontSize(12);
    pdf.setFont(undefined, 'normal');
    pdf.text(`Project Updated At : ${projectDates.updatedAt}`, 120, 32);

    let yPosition = 40;

    yPosition = addTableToPDF(
      Object.entries(hvacDetails).map(([key, value]) => ({
        Parameter: key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase()),
        Value: value
      })),
      'HVAC Details',
      ['Parameter', 'Value'],
      yPosition,
      9
    ) + 15;

    yPosition = addTableToPDF(
      [
        { Parameter: 'Pressure Drop', Value: `${systemConfiguration.maxPressureDropPsiPer100Ft}` },
        { Parameter: 'Settings For', Value: systemConfiguration.settingsFor },
        { Parameter: 'Material', Value: systemConfiguration.components.branches[0].material },
      ],
      'System Configuration',
      ['Parameter', 'Value'],
      yPosition,
      9
    ) + 15;

    yPosition = addTableToPDF(
      [
        { Unit: 'Length', Metric: units.length.metric, Imperial: units.length.imperial },
        { Unit: 'Pressure Drop', Metric: units.pressureDrop.metric, Imperial: units.pressureDrop.imperial },
        { Unit: 'Flow Rate', Metric: flowRateUnit, Imperial: flowRateUnit },
      ],
      'Units',
      ['Unit', 'Metric', 'Imperial'],
      yPosition,
      9
    ) + 15;

    pdf.addPage();
    yPosition = 15;
    addLogo();

    yPosition = addTableToPDF(
      elbowData,
      'Elbows (CR3 - 1)',
      ['Type', 'Angle Of Bend', 'Radius Of Bend'],
      yPosition,
      9
    ) + 15;

    yPosition = addTableToPDF(
      teeData,
      'Fittings',
      ['Fitting Type'],
      yPosition,
      9
    ) + 15;

    yPosition = addTableToPDF(
      transitionData,
      'Transitions (SR4 - 1)',
      ['Transition Type', "Angle of Transition"],
      yPosition,
      9
    ) + 15;

    pdf.addPage();
    yPosition = 15;
    addLogo();

    yPosition = addTableToPDF(
      filteredData.map(([key, count]) => ({ Equipment: key, Usage: count })),
      'Equipment Usage',
      ['Equipment', 'Usage'],
      yPosition,
      9
    ) + 15;

    yPosition = addTableToPDF(
      ahuToDiffuserMapping.map((item) => ({ Branches: item.name, 'Pressure Drop': `${convertPressure(item.value).toFixed(3)} ${pressureUnit}` })),
      'Pressure Drop',
      ['Branches', 'Pressure Drop'],
      yPosition,
      9
    ) + 15;


    pdf.save(`${projectTitle}.pdf`);
  };


  const renderPieChart = data => {
    const chartData = {
      labels: data.map(entry => entry.name),
      datasets: [
        {
          data: data.map(entry => entry.value),
          backgroundColor: COLORS,
          borderColor: COLORS,
          borderWidth: 1,
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right',
          labels: {
            boxWidth: 10,
            font: {
              size: 10,
            },
          },
        },
      },
    };

    return (
      <Box sx={{ height: '200px', width: '200px', margin: 'auto' }}>
        <Pie data={chartData} options={options} />
      </Box>
    );
  };

  const renderBarChart = data => {
    const chartData = {
      labels: data.map(entry => entry.name),
      datasets: [
        {
          label: 'Pressure Drop',
          data: data.map(entry => entry.value),
          backgroundColor: '#8884d8',
        },
      ],
    };

    const options = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: true,
          text: 'Pressure Drop by Branch',
        },
      },
      scales: {
        x: {
          ticks: {
            callback: function (val, index) {
              return this.getLabelForValue(val).split(' to ')[1];
            },
          },
        },
      },
    };

    return (
      <Box sx={{ height: '300px', width: '100%' }}>
        <Bar data={chartData} options={options} />
      </Box>
    );
  };

  const pieData = filteredData.map(([name, value]) => ({ name, value }));

  const convertPressure = value => {
    switch (pressureUnit) {
      case 'bar':
        return (value / 100000);
      case 'psi':
        return (value / 6894.75729);
      default:
        return value;
    }
  };
  const barData = ahuToDiffuserMapping.map(item => ({
    name: item.name,
    value: convertPressure(item.value)
  }));

  const getChipColor = value => {
    if (value < 1000) return 'success';
    if (value < 5000) return 'warning';
    return 'error';
  };

  if (isLoading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <ConstructionOutlinedIcon sx={{ color: '#5864A6', mr: 2 }} />
        <Typography variant="h3" color="#5864A6">
          Loading...
        </Typography>
      </Box>
    );
  }

  if (!projectData || !projectData.equipments || Object.keys(equipmentUsage).length === 0) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Button variant='outlined' edge="start" onClick={() => history.goBack()} sx={{ mr: 2 }}>
          <ArrowBackOutlinedIcon />
        </Button>
        <ConstructionOutlinedIcon sx={{ color: '#5864A6', mr: 2 }} />
        <Typography variant="h6" color="#5864A6">
          No equipment data available
        </Typography>
      </Box>
    );
  }

  const unitSystem = projectData.projectCreation[0].projectInformation.unitSystem
  const flowRateUnit = projectData.projectCreation[0].projectInformation.flowrateUnit

  return (
    <Box sx={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
        <Toolbar>
          <IconButton color="inherit" edge="start" onClick={() => history.goBack()} sx={{ mr: 2 }}>
            <ArrowBackOutlinedIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div">
            Project Activity
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            <ListItem
              button
              onClick={() => setActiveTab('designParameter')}
              selected={activeTab === 'designParameter'}
            >
              <ListItemIcon>
                <HvacIcon />
              </ListItemIcon>
              <ListItemText primary="Design Parameter" />
            </ListItem>
            <ListItem button onClick={() => setActiveTab('equipment')} selected={activeTab === 'equipment'}>
              <ListItemIcon>
                <EmojiObjectsIcon />
              </ListItemIcon>
              <ListItemText primary="Equipment Usage" />
            </ListItem>
            <ListItem button onClick={() => setActiveTab('branchDrop')} selected={activeTab === 'branchDrop'}>
              <ListItemIcon>
                <AirIcon />
              </ListItemIcon>
              <ListItemText primary="Pressure Drop" />
            </ListItem>
          </List>
        </Box>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 2, height: '100%', overflow: 'auto' }}>
        <Toolbar />
        <Paper elevation={3} sx={{ p: 3, mb: 2 }}>
          {activeTab === 'designParameter' && (
            <Box
              sx={{ p: 4, backgroundColor: '#f5f5f5', maxHeight: 'calc(100vh - 200px)', overflowY: 'auto' }}
            >
              <Typography
                variant="h4"
                gutterBottom
                sx={{ mb: 4, fontWeight: 'bold', color: '#2c3e50', textAlign: 'center' }}
              >
                Design Parameter
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={6}
                    sx={{
                      height: '250px',
                      borderRadius: 4,
                      overflow: 'hidden',
                      transition: 'transform 0.3s',
                      '&:hover': { transform: 'scale(1.02)' },
                    }}
                  >
                    <Box sx={{ p: 3, backgroundColor: '#3498db', color: 'white' }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
                        HVAC Details
                      </Typography>
                    </Box>
                    <Box sx={{ p: 3, height: 'calc(100% - 76px)', overflowY: 'auto' }}>
                      <Table size="small" stickyHeader>
                        <TableBody>
                          {Object.entries(hvacDetails).map(([key, value], index) => (
                            <TableRow
                              key={key}
                              sx={{ backgroundColor: index % 2 === 0 ? '#ecf0f1' : 'white' }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: 'bold', color: '#34495e' }}
                              >
                                {key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                              </TableCell>
                              <TableCell align="right" sx={{ color: '#2980b9' }}>
                                {value}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Paper
                    elevation={6}
                    sx={{
                      height: '250px',
                      borderRadius: 4,
                      overflow: 'hidden',
                      transition: 'transform 0.3s',
                      '&:hover': { transform: 'scale(1.02)' },
                    }}
                  >
                    <Box sx={{ p: 3, backgroundColor: '#2ecc71', color: 'white' }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
                        System Configuration
                      </Typography>
                    </Box>
                    <Box sx={{ p: 3, height: 'calc(100% - 76px)', overflowY: 'auto' }}>
                      <Table size="small" stickyHeader>
                        <TableBody>
                          {[
                            { label: 'Settings For', value: systemConfiguration.settingsFor },
                            { label: 'Material', value: systemConfiguration.components.branches[0].material },
                          ].map((row, index) => (
                            <TableRow
                              key={row.label}
                              sx={{ backgroundColor: index % 2 === 0 ? '#ecf0f1' : 'white' }}
                            >
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ fontWeight: 'bold', color: '#34495e' }}
                              >
                                {row.label}
                              </TableCell>
                              <TableCell align="right" sx={{ color: '#27ae60' }}>
                                {row.value}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </Grid>
                <Grid item xs={12}>
                  <Paper
                    elevation={6}
                    sx={{
                      height: '280px',
                      borderRadius: 4,
                      overflow: 'hidden',
                      transition: 'transform 0.3s',
                      '&:hover': { transform: 'scale(1.02)' },
                    }}
                  >
                    <Box sx={{ p: 3, backgroundColor: '#ff8800', color: 'white' }}>
                      <Typography variant="h5" gutterBottom sx={{ mb: 2, fontWeight: 'bold' }}>
                        Units
                      </Typography>
                    </Box>
                    <Box sx={{ p: 3, height: 'calc(100% - 76px)', overflowY: 'auto' }}>
                      <Table size="small" stickyHeader>
                        <TableHead>
                          <TableRow sx={{ backgroundColor: '#ecf0f1' }}>
                            <TableCell sx={{ fontWeight: 'bold', color: '#34495e' }}>Unit</TableCell>
                            <TableCell align="center" sx={{ fontWeight: 'bold', color: '#34495e' }}>
                              {unitSystem === 'Imperial' ? 'Imperial' : 'Metric'}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {[
                            { label: 'Length', metric: units.length.metric, imperial: units.length.imperial },
                            { label: 'Pressure Drop', metric: units.pressureDrop.metric, imperial: units.pressureDrop.imperial },
                            { label: 'Flow Rate', metric: flowRateUnit, imperial: flowRateUnit },
                          ].map((row, index) => (
                            <TableRow key={row.label} sx={{ backgroundColor: index % 2 === 0 ? '#ecf0f1' : 'white' }}>
                              <TableCell component="th" scope="row" sx={{ fontWeight: 'bold', color: '#34495e' }}>
                                {row.label}
                              </TableCell>
                              <TableCell align="center" sx={{ color: '#ff8800' }}>
                                {unitSystem === 'Imperial' ? row.imperial : row.metric}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Box>
          )}
          {activeTab === 'equipment' && (
            <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
              <Typography variant="h5" gutterBottom>
                Equipment Usage
              </Typography>
              <TextField
                placeholder="Search Equipment..."
                value={searchText}
                onChange={handleSearch}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  startAdornment: <SearchIcon color="action" sx={{ mr: 1 }} />,
                }}
              />
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Equipment Type</TableCell>
                          <TableCell>Usage Count</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {filteredData.map(([key, count], index) => (
                          <TableRow key={index}>
                            <TableCell>{key}</TableCell>
                            <TableCell>{count}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                >
                  {renderPieChart(pieData)}
                </Grid>
              </Grid>
            </Box>
          )}
          {activeTab === 'branchDrop' && (
            <Box sx={{ maxHeight: 'calc(100vh - 200px)', overflow: 'auto' }}>
              <Typography variant="h5" gutterBottom>
                Pressure Drop
              </Typography>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <TableContainer component={Paper}>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Branches</TableCell>
                          <TableCell>Pressure Drop</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {ahuToDiffuserMapping.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>
                              <Chip
                                icon={<AirIcon />}
                                label={`${convertPressure(item.value).toFixed(3)} ${pressureUnit}`}
                                color={getChipColor(item.value)}
                                variant="outlined"
                                size="small"
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
                <Grid item xs={12} md={6}>
                  {renderBarChart(barData)}
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
        <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
          <Button variant="outlined" startIcon={<DocumentScannerIcon />} onClick={handleExcelDownload}>
            Download Excel
          </Button>
          <Button variant="outlined" startIcon={<PictureAsPdfIcon />} onClick={handlePdfDownload}>
            Download PDF
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Activity;