const API_HOST = process.env.REACT_APP_API_ENDPOINT;

const createProject = async (token, data) => {
  return await fetch(`${API_HOST}/projects/project`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

const getProjects = async token => {
  return await fetch(`${API_HOST}/projects/projects`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

const getProject = async (token, id) => {
  return await fetch(`${API_HOST}/projects/project/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

const getProjectCalculation = async (token, id) => {
  return await fetch(`${API_HOST}/projects/project/${id}/calculate`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

//For pdf
const updateProject = async (token, id, data) => {
  return await fetch(`${API_HOST}/project/${id}`, {
    method: 'POST',
    body: data,
    headers: {
      //  'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

const updateProjectData = async (token, id, data) => {
  return await fetch(`${API_HOST}/projects/project/${id}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
  }).then(response => response.json());
};

const deleteProject = async (token, id) => {
  const response = await fetch(`${API_HOST}/projects/project/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  if (response.status === 204) {
    return { success: true };
  } else {
    const errorData = await response.json();
    return { success: false, error: errorData.error || 'Error deleting project' };
  }
};

export {
  createProject,
  getProjects,
  getProject,
  getProjectCalculation,
  updateProject,
  deleteProject,
  updateProjectData,
};
