import { Box, Button, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { fabric } from 'fabric';
import teePanelstyles from '../PanelStyles/TeePanelStyles';

const TransitionPanel = ({ closeTransitionPanel, selectedTransition, transitions, setTransitions }) => {
  const [transitionAngle, setTransitiongAngle] = useState(selectedTransition?.transitionAngle);

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Delete' || event.key === 'Del') {
        handleDelete();
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setTransitiongAngle(selectedTransition?.transitionAngle);
  }, [selectedTransition]);

  const handleDelete = () => {
    if (selectedTransition?.id) {
      const filteredItems = transitions?.filter(item => item.id !== selectedTransition.id);
      setTransitions(filteredItems);
      closeTransitionPanel();
    }
  };

  const handleTransitionAngle = e => {
    const updatedTransitions = transitions.map(transition => {
      const updatedTransition = fabric.util.object.clone(transition);
      if (transition.id === selectedTransition?.id) {
        updatedTransition.transitionAngle = parseInt(e.target.value);
        setTransitiongAngle(e.target.value);
        return updatedTransition;
      }
      return updatedTransition;
    });
    setTransitions(updatedTransitions);
  };

  return (
    <Box sx={teePanelstyles.container}>
      <Typography variant="h6">Transition</Typography>
      <Box sx={teePanelstyles.controlGroup}>
        <FormControl fullWidth>
          <InputLabel id="tansitionAngle">Angle of transition</InputLabel>
          <Select
            labelId="tansitionAngle"
            id="tansitionAngle"
            label="Angle of transition"
            defaultValue={90}
            value={transitionAngle}
            onChange={handleTransitionAngle}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={15}>15</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={30}>30</MenuItem>
            <MenuItem value={45}>45</MenuItem>
            <MenuItem value={60}>60</MenuItem>
            <MenuItem value={90}>90</MenuItem>
            <MenuItem value={120}>120</MenuItem>
            <MenuItem value={150}>150</MenuItem>
            <MenuItem value={180}>180</MenuItem>
          </Select>
        </FormControl>
      </Box>
      <Box sx={teePanelstyles.buttonGroup}>
        <Button
          variant="outlined"
          color="error"
          onClick={handleDelete}
          sx={{ bgcolor: 'red', color: 'white' }}
        >
          Delete
        </Button>
        <Button variant="outlined" onClick={closeTransitionPanel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default TransitionPanel;
