import {
  CloudUploadOutlined as CloudUploadOutlinedIcon,
  DrawOutlined as DrawOutlinedIcon,
  SaveOutlined as SaveOutlinedIcon,
  VerifiedOutlined as VerifiedOutlinedIcon,
  ViewQuiltOutlined as ViewQuiltOutlinedIcon,
} from '@mui/icons-material';
import AlignVerticalCenterIcon from '@mui/icons-material/AlignVerticalCenter';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import PolylineIcon from '@mui/icons-material/Polyline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import VerticalAlignCenterIcon from '@mui/icons-material/VerticalAlignCenter';
import WhatshotOutlinedIcon from '@mui/icons-material/WhatshotOutlined';
import {
  Alert,
  AppBar,
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  keyframes,
} from '@mui/material';
import { fabric } from 'fabric';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import {
  generateUniqueId,
  getAdjustedStrokeWidth,
  setLineProperties,
  splitLineAtPoint,
} from '../../../functions/utilFunctions';
import { setDrawingMode } from '../../../redux/slice/drawingSlice';
import { setBranchDrop } from '../../../redux/slice/projectSlice';
import { getProject, getProjectCalculation } from '../../../services/projectService';
import Dragables from '../Dragables';

const shimmer = keyframes`
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
`;

const HeaderUi = ({
  projectData,
  toggleUploadPdfButton,
  showUploadPdfButton,
  onFileChange,
  toggleLineCreation,
  createLine,
  createPolyline,
  showLineButton,
  canvas,
  paperType,
  toggleLineButton,
  save,
  drawnLines,
  setDrawnLines,
  equipments,
  tees,
  elbows,
  setTees,
  setElbows,
  setTransitions,
  setEquipments,
  setSelectedLine,
  selectedEquipment,
  setSelectedEquipment,
}) => {
  const dispatch = useDispatch();
  const { isPdfPresent, toggleScale, drawingMode, selectedlevel, pdfScale } = useSelector(
    state => state.drawing,
  );
  const { projectId } = useParams();
  const [snackBar, setSnackBar] = useState(false);
  const [snackBarText, setSnackBarText] = useState('');
  const [showDesignTools, setShowDesignTools] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [nextRoute, setNextRoute] = useState(null);
  const [drawType, setDrawType] = useState(null);
  const [projectTitle, setProjectTitle] = useState(
    projectData?.projectCreation?.[0]?.projectInformation?.projectName ?? 'Loading...',
  );

  useEffect(() => {
    if (projectData?.projectCreation?.[0]?.projectInformation?.projectName) {
      setProjectTitle(projectData.projectCreation[0].projectInformation.projectName);
    }
  }, [projectData]);

  const [isLoading, setIsLoading] = useState(false);
  const linesRef = useRef(drawnLines);
  const lineStrokeWidth = 1;
  const unitSystem = projectData?.projectCreation?.[0]?.projectInformation?.unitSystem ?? 'Imperial';
  const lineCalculation = projectData?.projectCreation?.[0]?.systemConfiguration?.components

  useEffect(() => {
    linesRef.current = drawnLines;
  }, [drawnLines]);


  // useEffect(() => {
  //   const fetchData = async () => {
  //     setIsLoading(true);
  //     try {
  //       const token = localStorage.getItem('token');
  //       const response = await getProject(token, projectId);
  //       if (response && response.projectData) {
  //         setProjectTitle(response.projectData.projectCreation[0].projectInformation.projectName);
  //       }
  //     } catch (err) {
  //       console.error(err);
  //     } finally {
  //       setIsLoading(false);
  //     }
  //   };
  //   fetchData();
  // }, [projectId]);
  const history = useHistory();
  const open = Boolean(anchorEl);

  const options = ['Logout'];
  const ITEM_HEIGHT = 48;
  let transitionWidth = 1;

  const projectCalculation = async () => {
    setSnackBarText('Calculating...');

    const token = localStorage.getItem('token');
    const data = await getProjectCalculation(token, projectId);

    if (data.error || Object.keys(data).length === 0) {
      setSnackBarText('Something went wrong');
    } else {
      setSnackBarText('Calculated');
      dispatch(setBranchDrop(data));
    }
  };

  useEffect(() => {
    if (snackBarText) {
      setSnackBar(true);
      const timer = setTimeout(() => {
        setSnackBar(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [snackBarText]);

  const transitionLine = () => {
    function stopDrawing() {
      dispatch(setDrawingMode(false));
      canvas.off('mouse:down', mouseDownHandler);
      document.removeEventListener('keydown', onKeyDown);
      document.getElementById('stopButton').removeEventListener('click', stopDrawing);
    }

    function onKeyDown(event) {
      if (event.key === 'Escape' || event.keyCode === 27) {
        stopDrawing();
        document.removeEventListener('keydown', onKeyDown);
        document.getElementById('stopButton').removeEventListener('click', stopDrawing);
      }
    }
    document.getElementById('stopDrawMode')?.addEventListener('click', stopDrawing);
    document.addEventListener('keydown', onKeyDown);

    function mouseDownHandler(options) {
      if (options.target instanceof fabric.Line) {
        const splittingLine = options.target;
        const clickPoint = canvas.getPointer(options.e);
        let [lineSplit1, lineSplit2, closestPoint] = splitLineAtPoint(
          splittingLine,
          selectedlevel,
          clickPoint,
          pdfScale,
          paperType,
          lineStrokeWidth,
        );
        lineSplit1 = setLineProperties(lineSplit1, unitSystem, lineCalculation.branches);
        lineSplit2 = setLineProperties(lineSplit2, unitSystem, lineCalculation.branches);
        lineSplit1.on('mousedown', event => {
          setSelectedLine(lineSplit1);
        });
        lineSplit2.on('mousedown', event => {
          setSelectedLine(lineSplit2);
        });

        let transition = new fabric.Rect({
          id: generateUniqueId(),
          left: closestPoint.x,
          top: closestPoint.y,
          fill: '#FFD700', // A more muted yellow color
          stroke: '#DAA520', // Gold border for better visibility
          strokeWidth: getAdjustedStrokeWidth(pdfScale, paperType, 2),
          width: getAdjustedStrokeWidth(pdfScale, paperType, transitionWidth),
          height: getAdjustedStrokeWidth(pdfScale, paperType, transitionWidth),
          transitionAngle: 90,
          originX: 'center',
          originY: 'center',
          level: selectedlevel?.name || 'L1',
          lineIds: [lineSplit1.id, lineSplit2.id],
          connection: {
            line1: {
              id: lineSplit1.id,
              flow: 'in',
            },
            line2: {
              id: lineSplit2.id,
              flow: 'out',
            },
          },
          transitionType: 'Rectangular',
          shadow: new fabric.Shadow({
            color: 'rgba(0,0,0,0.3)',
            blur: 5,
            offsetX: 2,
            offsetY: 2,
          }),
          selectable: true,
          hasControls: true,
          hasBorders: true,
          cornerSize: 8,
          transparentCorners: false,
          padding: 5,
        });
        transition.on('mousedown', event => {
          // setSelectedTransition(transition);
        });
        setTransitions(prevTrans => [...prevTrans, transition]);

        canvas.remove(splittingLine);
        canvas.add(lineSplit1);
        canvas.bringToFront(lineSplit1);
        canvas.add(lineSplit2);
        canvas.bringToFront(lineSplit2);
        canvas.requestRenderAll();

        const updatedDrawnLines = [
          ...linesRef.current.filter(l => l.id !== splittingLine.id),
          lineSplit1,
          lineSplit2,
        ];
        setDrawnLines(updatedDrawnLines);
        handleTees(splittingLine, lineSplit2);
        handleElbows(splittingLine, lineSplit2);
        handleDiffusers(splittingLine, lineSplit2);
      }
    }

    canvas.on('mouse:down', mouseDownHandler);
  };

  // Replace previous incoming connection with new splitted line for tees
  const handleTees = (splittingLine, lineSplit2) => {
    const updatedTees = tees.map(tee => {
      const updatedTee = fabric.util.object.clone(tee);
      if (updatedTee.connection.line1.id === splittingLine.id) {
        updatedTee.connection.line1.id = lineSplit2.id;
        return updatedTee;
      }
      return updatedTee;
    });
    setTees(updatedTees);
  };

  // Replace previous incoming connection with new splitted line for elbows
  const handleElbows = (splittingLine, lineSplit2) => {
    const updatedElbows = elbows.map(elbow => {
      const updatedElbow = fabric.util.object.clone(elbow);
      if (updatedElbow.connection.line1.id === splittingLine.id) {
        updatedElbow.connection.line1.id = lineSplit2.id;
        return updatedElbow;
      }
      return updatedElbow;
    });
    setElbows(updatedElbows);
  };

  // Replace connection with diffuser
  const handleDiffusers = (splittingLine, lineSplit2) => {
    const updatedEq = equipments.map(eq => {
      const newEq = fabric.util.object.clone(eq);
      if (newEq.ductId === splittingLine.id) {
        newEq.ductId = lineSplit2.id;
        return newEq;
      }
      return newEq;
    });
    setEquipments(updatedEq);
  };

  const handleTransition = () => {
    transitionLine();
  };

  const handleSnackBar = (flag, message) => {
    setSnackBarText(message);
    setSnackBar(flag);
  };

  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackBar(false);
  };

  const handleDesignClick = () => {
    setShowDesignTools(true);
    toggleLineButton();
  };

  const handleFloorPlanClick = () => {
    setShowDesignTools(false);
    toggleUploadPdfButton();
  };

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    window.location.href = '/';
  };

  const handleAction = option => {
    switch (option) {
      case 'Logout':
        handleLogout();
        break;
      default:
        break;
    }
  };

  const navigateTo = route => {
    if (unsavedChanges) {
      setNextRoute(route);
      setConfirmDialogOpen(true);
    } else {
      history.push(route);
    }
  };

  const navigateToDashboard = () => {
    navigateTo('/dashboard');
  };

  const navigateToSettings = () => {
    navigateTo(`/settings/${projectId}`);
  };

  const navigateToReports = () => {
    navigateTo(`/reports/${projectId}`);
  };

  const handleDrawerToggle = () => {
    setOpenDrawer(prevOpen => !prevOpen);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const showActivityLogView = () => {
    handleDrawerToggle();
    navigateToReports();
  };

  const handleFileChange = event => {
    onFileChange(event);
    setUnsavedChanges(true);
  };

  const handleSave = () => {
    save();
    setUnsavedChanges(false);
  };

  const handleConfirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmDialogProceed = () => {
    setConfirmDialogOpen(false);
    if (nextRoute) {
      history.push(nextRoute);
    }
  };

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.key === 'Escape') {
        if (drawingMode) {
          dispatch(setDrawingMode(false));
          handleSnackBar(true, 'Drawing mode disabled');
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [dispatch]);

  const handleEnableDrawingMode = type => {
    setDrawType(type);
    dispatch(setDrawingMode(true));
  };

  useEffect(() => {
    if (drawingMode) {
      if (drawType === 'Polyline') {
        createPolyline(canvas);
      } else if (drawType === 'Line') {
        createLine(canvas);
      } else if (drawType === 'Transition') {
        handleTransition();
      }
      toggleLineCreation();
      handleSnackBar(true, 'Drawing mode enabled');
      setUnsavedChanges(true);
    }
  }, [drawingMode]);

  const renderProjectTitle = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            height: '24px',
            width: '150px',
            background: '#f6f7f8',
            backgroundImage: 'linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '800px 104px',
            display: 'inline-block',
            position: 'relative',
            animation: `${shimmer} 1.2s ease-in-out infinite`,
          }}
        />
      );
    }

    return (
      <Typography
        noWrap
        variant="subtitle1"
        sx={{
          fontWeight: 'bold',
          color: '#333',
          maxWidth: 200,
          borderBottom: '2px solid #5864A6',
          paddingBottom: '2px',
          position: 'relative',
          opacity: 0,
          animation: 'fadeIn 0.5s ease-out forwards',
          '@keyframes fadeIn': {
            '0%': { opacity: 0, transform: 'translateY(10px)' },
            '100%': { opacity: 1, transform: 'translateY(0)' },
          },
          '&::after': {
            content: '""',
            position: 'absolute',
            bottom: '-2px',
            left: '0',
            width: '0',
            height: '2px',
            backgroundColor: '#ff6b6b',
            transition: 'width 0.3s ease',
          },
          '&:hover::after': {
            width: '100%',
          },
        }}
      >
        {projectTitle || 'Untitled Project'}
      </Typography>
    );
  };

  return (
    <>
      <Tooltip title="Side Tool Bar">
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', top: 26, left: 25 }}
        >
          <MenuOutlinedIcon />
        </IconButton>
      </Tooltip>
      <AppBar
        position="static"
        color="default"
        sx={{
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          borderBottom: '1px solid #e0e0e0',
        }}
      >
        <Toolbar
          sx={{
            flexWrap: 'wrap',
            m: '1%',
            ml: '4%',
          }}
        >
          <Box display="flex" alignItems="center" gap={2} sx={{ flexGrow: 1, flexShrink: 0 }}>
            <ButtonGroup sx={{ width: { xs: '100%', sm: 'auto' } }}>
              <Tooltip title="Upload Floor Plan">
                <Button variant="outlined" onClick={handleFloorPlanClick} sx={{ width: '25%' }}>
                  <Stack direction="column" alignItems="center">
                    <ViewQuiltOutlinedIcon />
                    <div>Draft</div>
                  </Stack>
                </Button>
              </Tooltip>
              <Tooltip title="Draw Ducts">
                <Button
                  variant="outlined"
                  onClick={handleDesignClick}
                  disabled={!toggleScale || !isPdfPresent}
                  sx={{ width: '25%' }}
                >
                  <Stack direction="column" alignItems="center">
                    <DrawOutlinedIcon />
                    <div>Design</div>
                  </Stack>
                </Button>
              </Tooltip>
              <Tooltip title="Calculations">
                <Button
                  variant="outlined"
                  onClick={projectCalculation}
                  disabled={!toggleScale || !isPdfPresent}
                  sx={{ width: '25%' }}
                >
                  <Stack direction="column" alignItems="center">
                    <VerifiedOutlinedIcon />
                    <div>Result</div>
                  </Stack>
                </Button>
              </Tooltip>
              <Tooltip title="Save Project">
                <Button
                  variant="outlined"
                  onClick={handleSave}
                  disabled={!toggleScale || !isPdfPresent}
                  sx={{ width: '25%' }}
                >
                  <Stack direction="column" alignItems="center">
                    <SaveOutlinedIcon />
                    <div>Save</div>
                  </Stack>
                </Button>
              </Tooltip>
              <Dragables
                projectData={projectData}
                canvas={canvas}
                equipments={equipments}
                setEquipments={setEquipments}
                selectedEquipment={selectedEquipment}
                setSelectedEquipment={setSelectedEquipment}
                setUnsavedChanges={setUnsavedChanges}
              />
            </ButtonGroup>

            {showUploadPdfButton && !showDesignTools && (
              <ButtonGroup variant="outlined">
                <Tooltip title="Upload PDF">
                  <Button component="label">
                    <Stack direction="column" alignItems="center">
                      <CloudUploadOutlinedIcon />
                      <div>Upload File</div>
                      <input type="file" accept="application/pdf" hidden onChange={handleFileChange} />
                    </Stack>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            )}
            {showLineButton && showDesignTools && (
              <ButtonGroup sx={{ width: { xs: '100%', sm: 'auto' } }}>
                <Tooltip title="Draw mainline">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleEnableDrawingMode('Polyline');
                    }}
                    sx={{ width: '35%' }}
                  >
                    <Stack direction="column" justifyContent="center" alignItems="center">
                      <PolylineIcon />
                      <div>Main</div>
                    </Stack>
                  </Button>
                </Tooltip>
                <Tooltip title="Draw branch">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleEnableDrawingMode('Line');
                    }}
                    sx={{ width: '35%' }}
                  >
                    <Stack direction="column" justifyContent="center" alignItems="center">
                      <AlignVerticalCenterIcon />
                      <div>Branch</div>
                    </Stack>
                  </Button>
                </Tooltip>
                <Tooltip title="Transition Ducts">
                  <Button
                    variant="outlined"
                    onClick={() => {
                      handleEnableDrawingMode('Transition');
                    }}
                    sx={{ width: '35%' }}
                  >
                    <Stack direction="column" justifyContent="center" alignItems="center">
                      <VerticalAlignCenterIcon />
                      <div>Transition</div>
                    </Stack>
                  </Button>
                </Tooltip>
              </ButtonGroup>
            )}
          </Box>
          <Box display="flex" alignItems="center" sx={{ marginLeft: 'auto', marginRight: 2 }}>
            <Tooltip title={`Current Project: ${projectTitle || 'Loading...'}`}>
              <Paper
                elevation={3}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                  marginRight: 2,
                  padding: '8px 16px',
                  borderRadius: '8px',
                  backgroundColor: '#f0f2ff',
                  border: '1px solid #5864A6',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    backgroundColor: '#e0e4ff',
                    transform: 'translateY(-2px)',
                    boxShadow: '0 4px 8px rgba(88, 100, 166, 0.2)',
                  },
                }}
              >
                <Typography
                  variant="caption"
                  sx={{
                    color: '#5864A6',
                    fontWeight: 'medium',
                    textTransform: 'uppercase',
                    letterSpacing: '0.5px',
                  }}
                >
                  Current Project
                </Typography>
                {renderProjectTitle()}
              </Paper>
            </Tooltip>
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              sx={{
                backgroundColor: '#f0f2ff',
                '&:hover': {
                  backgroundColor: '#e0e4ff',
                },
              }}
            >
              <Avatar alt="User Avatar" src="" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{
                'aria-labelledby': 'long-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 3,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                },
              }}
              transformOrigin={{ horizontal: 'right', vertical: 'top' }}
              anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
              {options.map(option => (
                <MenuItem key={option} onClick={() => handleAction(option)}>
                  {option}
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>

      <Drawer
        variant="temporary"
        open={openDrawer}
        onClose={handleCloseDrawer}
        sx={{
          '& .MuiDrawer-paper': {
            boxSizing: 'border-box',
            width: 240,
          },
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '8px' }}>
          <IconButton onClick={handleCloseDrawer}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <Tooltip title="Go to main Dashboard, where you can select projects." placement="right">
            <ListItem button onClick={navigateToDashboard}>
              <ListItemIcon>
                <HomeOutlinedIcon sx={{ color: '#5864A6' }} />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Go to Reports, where you can see the analytics." placement="right">
            <ListItem button onClick={showActivityLogView}>
              <ListItemIcon>
                <WhatshotOutlinedIcon sx={{ color: '#5864A6' }} />
              </ListItemIcon>
              <ListItemText primary="Report" />
            </ListItem>
          </Tooltip>
          <Tooltip title="Go to main Project Settings." placement="right">
            <ListItem button onClick={navigateToSettings}>
              <ListItemIcon>
                <SettingsOutlinedIcon sx={{ color: '#5864A6' }} />
              </ListItemIcon>
              <ListItemText primary="Settings" />
            </ListItem>
          </Tooltip>
        </List>
      </Drawer>

      <Dialog
        open={confirmDialogOpen}
        onClose={handleConfirmDialogClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Unsaved Changes</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You have unsaved changes. Are you sure you want to leave this page? All unsaved changes will be
            lost.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleConfirmDialogClose}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleConfirmDialogProceed} autoFocus>
            Proceed
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={snackBar}
        autoHideDuration={2000}
        message={snackBarText}
      >
        <Alert onClose={handleCloseSnackBar} variant="filled" sx={{ width: '100%', bgcolor: '#4E5FBF' }}>
          {snackBarText}
        </Alert>
      </Snackbar>
    </>
  );
};

export default HeaderUi;
