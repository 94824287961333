import LoginIcon from '@mui/icons-material/Login';
import { AppBar, Box, Button, Container, Fade, Link, Typography, useTheme } from '@mui/material';
import { styled } from "@mui/system";
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import logo from "../components/Landingpage/Images/CalcmeLogo.png";
import LandingPage from '../components/Landingpage/LandingPage';



const navigationItems = [
  { label: 'Home', href: '#home', testId: 'link-home' },
  { label: 'About', href: '#about-us', testId: 'link-aboutus' },
  { label: 'Contact', href: '#contact', testId: 'link-contact' },
];

const Logo = styled("img")({
  width: "150px",
  mixBlendMode: "darken"
});

const useActiveLink = (href) => {
  const location = useLocation();
  return location.hash === href;
};

const NavLink = ({ href, label, testId }) => {
  const isActive = useActiveLink(href);
  const theme = useTheme();

  return (
    <Box
      sx={{
        position: 'relative',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        '&::after': {
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          height: '3px',
          backgroundColor: theme.palette.primary.main,
          transform: isActive ? 'scaleX(1)' : 'scaleX(0)',
          transition: 'transform 0.3s ease-in-out',
        },
        '&:hover::after': {
          transform: 'scaleX(1)',
        },
      }}
    >
      <Link
        data-testid={testId}
        href={href}
        underline="none"
        sx={{
          color: theme.palette.text.primary,
          fontWeight: 500,
          transition: 'color 0.3s ease-in-out',
          '&:hover': {
            color: theme.palette.primary.main,
          },
        }}
      >
        <Typography data-testid={`${testId}-label`} variant="subtitle1">
          {label}
        </Typography>
      </Link>
    </Box>
  );
};

const Home = () => {
  const history = useHistory();
  const theme = useTheme();

  return (
    <>
      <AppBar
        elevation={0}
        sx={{
          height: '64px',
          backgroundColor: theme.palette.background.paper,
          boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        }}
      >

        <Container maxWidth="lg" sx={{ height: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
              }}
            >
              <Logo src={logo} alt="calcme Logo" />
              {navigationItems.map((item) => (
                <NavLink key={item.href} {...item} />
              ))}
            </Box>
            <Box sx={{ display: 'flex', gap: 2, ml: 4 }}>
              <Fade in={true} style={{ transitionDelay: '100ms' }}>
                <Button
                  variant="outlined"
                  onClick={() => history.push('/waitlist')}
                  data-testid="join-waitlist-button"
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'none',
                    transition: 'all 0.3s ease-in-out',
                    color: theme.palette.text.primary,
                    borderColor: theme.palette.text.primary,
                    fontWeight: 500,
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      borderColor: theme.palette.primary.main,
                      color: theme.palette.primary.main,
                    },
                  }}
                >
                  Join waitlist
                </Button>
              </Fade>
              <Fade in={true} style={{ transitionDelay: '200ms' }}>
                <Button
                  variant="outlined"
                  startIcon={<LoginIcon />}
                  onClick={() => history.push('/login')}
                  data-testid="login-button"
                  sx={{
                    borderRadius: '20px',
                    textTransform: 'none',
                    transition: 'all 0.3s ease-in-out',
                    backgroundColor: theme.palette.primary.main,
                    color: theme.palette.common.white,
                    fontWeight: 500,
                    '&:hover': {
                      transform: 'translateY(-2px)',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      backgroundColor: theme.palette.primary.white,
                    },
                  }}
                >
                  Login
                </Button>
              </Fade>
            </Box>
          </Box>
        </Container>
      </AppBar>
      <Box sx={{ marginTop: '64px' }}>
        <LandingPage />
      </Box>
    </>
  );
};

export default Home;