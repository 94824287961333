export const scalePdfStyles = {
  container: {
    position: 'absolute',
    top: '50%',
    right: 0,
    transform: 'translateY(-50%)',
    border: '1px solid #c4c3c3',
    borderRadius: '3px',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    p:2,
    overflowY: 'auto',
    backgroundColor: 'white',
  },
  title: {
    textAlign: 'center',
    mt: 2,
  },
  scaleSection: {
    textAlign: 'center',
    mb: 2,
  },
  rotateSection: {
    textAlign: 'center',
    mt: 2,
  },
  buttonGroup: {
    display: 'flex',
    gap: 1,
    mt: 2,
    p: 2
  },
};
