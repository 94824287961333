import { Card, CardContent, Grid, MenuItem, Select, Typography } from '@mui/material';
import React from 'react';

const MethodForm = ({ formState, handleInputChange }) => (
    <Card sx={{ mb: 3, width: "100%" }}>
        <CardContent>
            <Typography
                variant="h6"
                gutterBottom
                sx={{
                    color: 'white',
                    bgcolor: '#8091f2',
                    display: 'inline-block',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    mb: 3
                }}
            >
                Mechanical
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Vent Air Changes Rate Standard
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            fullWidth
                            value={formState.ventAirStandard || ''}
                            onChange={handleInputChange}
                            size='small'
                        >
                            {["ASHRAE 62.1 2022"].map((option, idx) => (
                                <MenuItem value={option} key={idx}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
                <Grid item xs={12} container alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="body1" gutterBottom>
                            Duct Sizing Method
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Select
                            fullWidth
                            value={formState.ductSizingMethod}
                            onChange={handleInputChange}
                            size='small'
                        >
                            {["MAX Pressure Drop"].map((option, idx) => (
                                <MenuItem value={option} key={idx}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export default MethodForm;
